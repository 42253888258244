import { urls } from '@/constants';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import Overview from '@/page/Overview';
import Vendors from '@/page/Vendors';
import Shuttles from '@/page/Shuttles';
import Charters from '@/page/Charters';
import Membership from '@/page/Membership';
import MembershipDetail from '@/page/MembershipDetail';
import EditCharter from '@/page/Charters/Detail/EditCharter';
import EditShuttle from '@/page/Shuttles/Detail/EditShuttle';
import CreateShuttle from '@/page/Shuttles/Detail/CreateShuttle';
import VendorDetail from '@/page/Vendors/VendorDetail/VendorDetail';
import Settings from '@/page/Settings';

export interface Props {}

const Main: React.FC<Props> = () => {
  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
      <Switch>
        <Route path={urls.overview.base} component={Overview} />

        <Route path={urls.charters.detail.base} component={EditCharter} />

        <Route path={urls.charters.base} component={Charters} />

        <Route path={urls.membership.detail.base} component={MembershipDetail} />

        <Route path={urls.membership.base} component={Membership} />

        <Route path={urls.vendors.detail.base} component={VendorDetail} />

        <Route path={urls.vendors.base} component={Vendors} />

        <Route path={urls.shuttles.create} component={CreateShuttle} />

        <Route path={urls.shuttles.detail.base} component={EditShuttle} />

        <Route path={urls.shuttles.base} component={Shuttles} />

        <Route path={urls.settings.base} component={Settings} />

        <ConsumeRedirectRoute render={() => <Redirect to={urls.overview.base} />} />
      </Switch>
    </main>
  );
};

export default Main;
