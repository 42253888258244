import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

const AirportTableHeader = () => {
  const text = translations.pages.airports.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] justify-between font-bold">
      <Table.Data className="pr-1 w-[20%]" header>
        <FormattedMessage id={text.city} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.iata} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.icao} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.faa} />
      </Table.Data>

      <Table.Data className="pr-1 w-[30%]" header>
        <FormattedMessage id={text.airportName} />
      </Table.Data>

      <Table.Data className="w-4" header />
    </Typography>
  );
};

export default AirportTableHeader;
