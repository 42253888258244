import React from 'react';
import { useParams } from 'react-router';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { UserStatus } from '@flyblack/common/domains';
import { getUser } from '@/services/api/user';
import { approveMembership } from '@/services/api/membership';

import useLoad from '@flyblack/common/hooks/useLoad';
import Avatar from '@flyblack/common/components/Avatar';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import BackButton from '@flyblack/common/components/BackButton';
import Typography from '@flyblack/common/components/Typography';
import RowContainer from '@flyblack/common/components/RowContainer';
import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';
import Modal from '@flyblack/common/components/Modal';
import RejectMembershipApplicationModal from '../Membership/RejectMembershipApplicationModal';

interface Params {
  id: string;
}

const MembershipDetail: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<Params>();

  const { value: user, loading, reload } = useLoad({
    load: () => getUser(id)
  });

  return loading ? (
    <Loading visible={true} center className="w-full h-[400px]">
      <Loading.Indicator size={100} borderWidth={2} />
    </Loading>
  ) : (
    <div className="min-h-full py-20">
      <Container max>
        <BackButton />

        <div className="flex pt-4">
          <div className="pr-6">
            <Avatar image={user.profilePictureUrl} className="w-32 h-32" />
          </div>

          <div className="w-full">
            <RowContainer className="items-center flex-wrap">
              <RowContainer.Item className="flex flex-col mb-12" col={4}>
                <div className="ml-11">
                  <Typography is="span" type="flamingo">
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>

                  <Spacer xs={1} />

                  <Typography is="span" type="halcyon" color="lightGray">
                    {user.email}
                  </Typography>

                  <Spacer xs={1} />

                  <Typography is="span" type="halcyon" color="lightGray">
                    {user.phoneNumber}
                  </Typography>
                </div>
              </RowContainer.Item>

              <RowContainer.Item col={4} className="mb-12">
                <DoubleContentWithIcon
                  icon="location"
                  top={
                    <Typography is="div" type="halcyon">
                      <FormattedMessage id={translations.pages.membershipDetail.section.relevantLocations} />
                    </Typography>
                  }
                  bottom={
                    user.otherCities &&
                    user.otherCities.map((city, index) => (
                      <React.Fragment key={city.id}>
                        <Typography is="div" type="halcyon" color="lightGray">
                          {city.name}
                        </Typography>
                        {index < user.otherCities.length - 1 && <Spacer xs={1} />}
                      </React.Fragment>
                    ))
                  }
                />
              </RowContainer.Item>

              <RowContainer.Item col={4} className="mb-12">
                <DoubleContentWithIcon
                  icon="location"
                  top={
                    <Typography is="div" type="halcyon">
                      <FormattedMessage id={translations.pages.membershipDetail.section.homeCity} />
                    </Typography>
                  }
                  bottom={
                    <Typography is="div" type="halcyon" color="lightGray">
                      {user.stayingTheMostCity && user.stayingTheMostCity.name}
                    </Typography>
                  }
                />
              </RowContainer.Item>

              {user.identityDocumentUrl && (
                <RowContainer.Item col={4} className="mb-12">
                  <DoubleContentWithIcon
                    icon="id"
                    top={
                      <Typography is="div" type="halcyon">
                        <FormattedMessage id={translations.pages.membershipDetail.section.idCard} />
                      </Typography>
                    }
                    bottom={<img src={user.identityDocumentUrl} className="max-w-[220px]" />}
                  />
                </RowContainer.Item>
              )}

              <RowContainer.Item col={4} className="mb-12">
                <DoubleContentWithIcon
                  icon="calendar"
                  top={
                    <Typography is="div" type="halcyon">
                      <FormattedMessage id={translations.pages.membershipDetail.section.registrationDate} />
                    </Typography>
                  }
                  bottom={
                    <Typography is="div" type="halcyon" color="lightGray">
                      <FormattedDate value={user.createdAt} year="numeric" month="numeric" day="numeric" />
                    </Typography>
                  }
                />
              </RowContainer.Item>

              {user.birthDate && (
                <RowContainer.Item col={4} className="mb-12">
                  <DoubleContentWithIcon
                    icon="calendar"
                    top={
                      <Typography is="div" type="halcyon">
                        <FormattedMessage id={translations.pages.membershipDetail.section.birthDate} />
                      </Typography>
                    }
                    bottom={
                      <Typography is="div" type="halcyon" color="lightGray">
                        <FormattedDate
                          value={user.birthDate}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                          timeZone="UTC"
                        />
                      </Typography>
                    }
                  />
                </RowContainer.Item>
              )}

              {user.weight && (
                <RowContainer.Item col={4} className="mb-12">
                  <DoubleContentWithIcon
                    icon="weight"
                    top={
                      <Typography is="div" type="halcyon">
                        <FormattedMessage id={translations.pages.membershipDetail.section.weight} />
                      </Typography>
                    }
                    bottom={
                      <Typography is="div" type="halcyon" color="lightGray">
                        <FormattedMessage
                          id={translations.domains.weightPattern}
                          values={{
                            weightValue: user.weight.value,
                            weightUnit: intl.formatMessage({ id: translations.domains.weightUnit[user.weight.unit] })
                          }}
                        />
                      </Typography>
                    }
                  />
                </RowContainer.Item>
              )}
            </RowContainer>

            <Spacer xs={2} />

            {user.membership && (
              <React.Fragment>
                <div className="flex justify-between">
                  <Typography is="span" type="flamingo">
                    <FormattedMessage id={translations.pages.membershipDetail.membership} />
                  </Typography>

                  {(user.membership.status === UserStatus.PendingApproval ||
                    user.membership.status === UserStatus.OnWaitingList) && (
                    <div className="flex">
                      <Modal modal={RejectMembershipApplicationModal} id={user.membership.id} onSuccess={reload}>
                        {({ open }) => (
                          <Button appearance="ghost" className="w-[128px] mr-6" onClick={open}>
                            <Typography is="span" type="halcyon">
                              <FormattedMessage id={translations.inputs.buttons.decline} />
                            </Typography>
                          </Button>
                        )}
                      </Modal>

                      <Button
                        appearance="white"
                        className="w-[200px]"
                        onClick={() => approveMembership(user.membership.id).then(reload)}
                      >
                        <Typography is="span" type="halcyon">
                          <FormattedMessage id={translations.inputs.buttons.accept} />
                        </Typography>
                      </Button>
                    </div>
                  )}
                </div>

                <Spacer xs={6} />

                <RowContainer className="items-center flex-wrap">
                  <RowContainer.Item col={4} className="mb-12">
                    <DoubleContentWithIcon
                      icon="calendar"
                      top={
                        <Typography is="div" type="halcyon">
                          <FormattedMessage id={translations.pages.membershipDetail.section.applicationDate} />
                        </Typography>
                      }
                      bottom={
                        <Typography is="div" type="halcyon" color="lightGray">
                          <FormattedDate
                            value={user.membership.approvedAt}
                            year="numeric"
                            month="numeric"
                            day="numeric"
                          />
                        </Typography>
                      }
                    />
                  </RowContainer.Item>

                  <RowContainer.Item col={4} className="mb-12">
                    <DoubleContentWithIcon
                      icon="city"
                      top={
                        <Typography is="div" type="halcyon">
                          <FormattedMessage id={translations.pages.membershipDetail.section.workplace} />
                        </Typography>
                      }
                      bottom={
                        <Typography is="div" type="halcyon" color="lightGray">
                          {user.membership.workplace}
                        </Typography>
                      }
                    />
                  </RowContainer.Item>

                  <RowContainer.Item col={4} className="mb-12">
                    <DoubleContentWithIcon
                      icon="navigationAccount"
                      top={
                        <Typography is="div" type="halcyon">
                          <FormattedMessage id={translations.pages.membershipDetail.section.role} />
                        </Typography>
                      }
                      bottom={
                        <Typography is="div" type="halcyon" color="lightGray">
                          {user.membership.workRole}
                        </Typography>
                      }
                    />
                  </RowContainer.Item>

                  {user.gender && (
                    <RowContainer.Item col={4} className="mb-12">
                      <DoubleContentWithIcon
                        icon="gender"
                        top={
                          <Typography is="div" type="halcyon">
                            <FormattedMessage id={translations.pages.membershipDetail.section.gender} />
                          </Typography>
                        }
                        bottom={
                          <Typography is="div" type="halcyon" color="lightGray">
                            <FormattedMessage id={translations.domains.gender[user.gender]} />
                          </Typography>
                        }
                      />
                    </RowContainer.Item>
                  )}

                  {user.nationality && (
                    <RowContainer.Item col={4} className="mb-12">
                      <DoubleContentWithIcon
                        icon="country"
                        top={
                          <Typography is="div" type="halcyon">
                            <FormattedMessage id={translations.pages.membershipDetail.section.nationality} />
                          </Typography>
                        }
                        bottom={
                          <Typography is="div" type="halcyon" color="lightGray">
                            {user.nationality}
                          </Typography>
                        }
                      />
                    </RowContainer.Item>
                  )}

                  <RowContainer.Item col={4} className="mb-12">
                    <DoubleContentWithIcon
                      icon="dollar"
                      top={
                        <Typography is="div" type="halcyon">
                          <FormattedMessage id={translations.pages.membershipDetail.section.credits} />
                        </Typography>
                      }
                      bottom={
                        <Typography is="div" type="halcyon" color="lightGray">
                          {user.credit ? user.credit.value : 0}
                        </Typography>
                      }
                    />
                  </RowContainer.Item>
                </RowContainer>

                <DoubleContentWithIcon
                  icon="email"
                  top={
                    <Typography is="div" type="halcyon">
                      <FormattedMessage id={translations.pages.membershipDetail.section.motivation} />
                    </Typography>
                  }
                  bottom={
                    <Typography is="div" type="halcyon" color="lightGray">
                      {user.membership.applicationReason}
                    </Typography>
                  }
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MembershipDetail;
