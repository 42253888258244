export enum Sort {
  By = 'sortOptions[0].by',
  Order = 'sortOptions[0].order'
}

export enum By {
  CreatedAt = 'CREATED_AT',
  DepartureDate = 'DEPARTURE_DATE',
  DepartureTime = 'DEPARTURE_TIME',
  JetCharterDepartureDate = 'JET_CHARTER_DEPARTURE_DATE'
}

export enum Order {
  Ascendent = 'ASC',
  Descendent = 'DESC'
}
