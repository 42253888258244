export enum Status {
  Canceled = 'CANCELED',
  Booked = 'BOOKED',
  Delayed = 'DELAYED',
  Open = 'OPEN',
  PendingBooking = 'PENDING_BOOKING',
  Expired = 'EXPIRED'
}

export enum JetShuttleStatus {
  Booked = 'BOOKED',
  Canceled = 'CANCELED',
  Delayed = 'DELAYED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  PartiallyBooked = 'PARTIALLY_BOOKED',
  PendingBooking = 'PENDING_BOOKING'
}

export enum TripStatus {
  Booked = 'BOOKED',
  Canceled = 'CANCELED',
  Delayed = 'DELAYED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  PartiallyBooked = 'PARTIALLY_BOOKED',
  Pending = 'PENDING_BOOKING',
  WaitingForApproval = 'WAITING_FOR_APPROVAL',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}
