import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { translations } from '@/locale';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

const BackButton: React.FC = () => {
  const history = useHistory();

  return (
    <Button appearance="transparent" onClick={() => history.goBack()}>
      <Icon type="chevronLeft" className="text-4xl" />
      <Typography is="span" type="halcyon">
        <FormattedMessage id={translations.inputs.buttons.goBack} />
      </Typography>
    </Button>
  );
};

export default BackButton;
