import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { urls } from '@/constants';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import PromoCodes from './PromoCodes';
import PushNotifications from './PushNotifications';
import Cities from './Cities';
import Airports from './Airports';

const Settings = () => {
  return (
    <Switch>
      <Route path={urls.settings.pushNotifications.base} component={PushNotifications} />

      <Route path={urls.settings.promoCodes.base} component={PromoCodes} />

      <Route path={urls.settings.cities.base} component={Cities} />

      <Route path={urls.settings.airports.base} component={Airports} />

      <ConsumeRedirectRoute render={() => <Redirect to={urls.settings.pushNotifications.base} />} />
    </Switch>
  );
};

export default Settings;
