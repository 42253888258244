import React from 'react';
import * as yup from 'yup';
import { parse } from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { translations } from '@/locale';
import { STRING_LONG_MAX_LENGTH, urls } from '@/constants';
import { checkResetPasswordTokenValidity, resetPassword } from '@/services/api/session';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object({
  password: yup
    .string()
    .min(8)
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.password.label)
    .required(),
  confirmationPassword: yup
    .string()
    .label(translations.inputs.password.label)
    .required()
    .oneOf([yup.ref('password')], translations.validation.custom.passwordsDontMatch)
});

const ResetPassword: React.FC = () => {
  const location = useLocation();

  const token = parse(location.search).token as string;

  const intl = useIntl();

  const history = useHistory();

  React.useEffect(() => {
    checkResetPasswordTokenValidity(token)
      .then((tokenValidity) => {
        if (tokenValidity.expired) history.push(urls.resetPassword.expired);
      })
      .catch(() => history.push(urls.resetPassword.expired));
  }, []);

  return (
    <div className="flex flex-col sm:max-w-md w-[320px] px-5 sm:px-0">
      <div className="uppercase text-xs tracking-[2px] font-semibold">
        <FormattedMessage id={translations.pages.resetPassword.title} />
      </div>

      <Form
        schema={schema}
        subscription={{ submitError: true, submitting: true, values: true, valid: true, dirty: true }}
        onSubmit={({ password }) =>
          resetPassword({ password, resetPasswordToken: token }).then(() => history.push(urls.signIn))
        }
      >
        {({ submitError, submitting, values, valid, dirty }) => (
          <div className="flex flex-col">
            <Spacer xs={6} />

            <div className="text-sm font-normal text-flyblack-light-gray">
              <FormattedMessage id={translations.pages.resetPassword.subtitle} />
            </div>

            <Spacer xs={2} />

            <Form.Field
              is={TextInputWithValidation}
              id="password"
              name="password"
              type="password"
              leadingIcon="password"
              placeholder={intl.formatMessage({ id: translations.inputs.password.placeholder })}
            />

            <Spacer xs={4} />

            <Form.Field
              is={TextInputWithValidation}
              id="confirmation-password"
              name="confirmationPassword"
              type="password"
              leadingIcon="password"
              placeholder={intl.formatMessage({ id: translations.inputs.confirmedPassword.placeholder })}
            />

            <Spacer xs={5} />

            {!!submitError && (
              <React.Fragment>
                <SubmitError error={submitError} />

                <Spacer xs={2} />
              </React.Fragment>
            )}

            <Button
              fat
              type="submit"
              className="font-medium"
              appearance="white"
              disabled={!valid || !dirty || submitting}
            >
              <FormattedMessage id={translations.pages.resetPassword.setPassword} />
            </Button>

            <Spacer xs={2} />
          </div>
        )}
      </Form>
    </div>
  );
};

export default ResetPassword;
