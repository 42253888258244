import React from 'react';
import classnames from 'classnames';

import Card from '@flyblack/common/components/Card';

interface Props {
  transparent?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const DetailCard = ({ children, className, transparent, style }: React.PropsWithChildren<Props>) => {
  return (
    <Card
      bg={transparent ? 'transparent' : 'dark'}
      className={classnames('p-9 mb-8 rounded-[2px] flex-1', className)}
      style={style}
    >
      {children}
    </Card>
  );
};

export default DetailCard;
