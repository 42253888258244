import { UpdatePassword } from '@flyblack/common/domains';
import { authorizedApi } from '@/services/network/authorized-api';
import { AdminMe } from '@/domains/me';

export const getMe = (): Promise<AdminMe> =>
  authorizedApi.get<{ user: AdminMe }>(`/v1/users/me`).then(({ user, ...rest }) => ({
    ...user,
    ...rest
  }));

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/v1/users/me/password`, data);
