import { authorizedApi } from '@/services/network/authorized-api';
import { Page } from '@flyblack/common/domains';
import { CharterEnquireItem } from '@/domains/charter-enquire';

export const getCharterEnquires = (pageNumber: number, pageSize: number, options?: Record<string, any>) =>
  authorizedApi.get<Page<CharterEnquireItem>>(`/v1/jet-charter-inquires`, { pageNumber, pageSize, ...options });

export const deleteCharterEnquires = (id) => authorizedApi.delete(`/v1/jet-charter-inquires/${id}`);

export const updateCharterEnquiresStatus = (id, status) =>
  authorizedApi.put(`/v1/jet-charter-inquires/${id}/status`, { status });
