import React from 'react';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getNow } from '@flyblack/common/util';
import { getCharters } from '@/services/api/charter';
import { CharterFilter } from '@/domains/charter-filter';
import { By, Order, Page, Sort } from '@flyblack/common/domains';

import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Typography from '@flyblack/common/components/Typography';
import usePagination from '@flyblack/common/hooks/usePagination';
import PageSelector from '@flyblack/common/components/PageSelector';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import SearchByIdInput from '@flyblack/common/components/SearchByIdInput';

import CharterTableHeader from './CharterTableHeader';
import CharterTableItem from './CharterTableItem';
import { getCharterEnquires } from '@/services/api/charter-enquire';
import { CharterListItem } from '@/domains/charter';
import { CharterEnquireItem } from '@/domains/charter-enquire';
import CharterEnquireTableHeader from '@/page/Charters/Enquire/CharterEnquireTableHeader';
import CharterEnquireTableItem from '@/page/Charters/Enquire/CharterEnquireTableItem';

export interface Props {}

const orderingOptionsByFilter: Record<CharterFilter, any> = {
  [CharterFilter.All]: { [Sort.Order]: Order.Ascendent },
  [CharterFilter.Request]: { [Sort.Order]: Order.Ascendent },
  [CharterFilter.PendingPayment]: { [Sort.Order]: Order.Ascendent },
  [CharterFilter.Confirmed]: { [Sort.Order]: Order.Ascendent },
  [CharterFilter.Canceled]: { [Sort.Order]: Order.Ascendent },
  [CharterFilter.History]: { [Sort.Order]: Order.Descendent },
  [CharterFilter.Enquiries]: { [Sort.Order]: Order.Descendent }
};

const Charters: React.FC<RouteComponentProps> = ({ history, location }) => {
  const urlFilter = queryString.parse(location.search).type || CharterFilter.All;

  const [selectedFilter, setSelectedFilter] = React.useState((urlFilter as string) || CharterFilter.All);
  const [searchedId, setSearchedId] = React.useState<number>();

  const { loading, data: charters, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size): Promise<Page<CharterEnquireItem | CharterListItem>> => {
      return selectedFilter === CharterFilter.Enquiries
        ? getCharterEnquires(page, size, {
            start: getNow(dateFormat),
            [Sort.By]: By.CreatedAt,
            ...orderingOptionsByFilter[selectedFilter],
            ...(searchedId ? { ids: [searchedId] } : null)
          })
        : getCharters(page, size, {
            start: getNow(dateFormat),
            status: selectedFilter === CharterFilter.All ? '' : selectedFilter,
            [Sort.By]: By.JetCharterDepartureDate,
            ...orderingOptionsByFilter[selectedFilter],
            ...(searchedId ? { ids: [searchedId] } : null)
          });
    }
  });

  React.useEffect(() => {
    !loading && reload();
    selectedFilter !== urlFilter &&
      history.push(queryString.stringifyUrl({ url: urls.charters.base, query: { type: selectedFilter } }));
  }, [selectedFilter]);

  React.useEffect(() => {
    setSelectedFilter(urlFilter as string);
  }, [urlFilter]);

  React.useEffect(() => {
    reload();
  }, [searchedId]);

  const onSearchedIdChange = (value: string) => setSearchedId(parseInt(value));

  return (
    <div className="bg-black flex flex-col w-full min-h-full">
      <Spacer xs={3} />

      <div className="flex h-14 items-center px-10 justify-between">
        <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-sm">
          {Object.values(CharterFilter).map((item) => (
            <Button
              key={item}
              type="button"
              appearance={selectedFilter === item ? 'white' : 'transparent'}
              className="mx-1 first:mx-0 last:mx-0 w-[160px] font-normal"
              onClick={() => setSelectedFilter(item)}
              disabled={loading}
            >
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.domains.charterFilter[item]} />
              </Typography>
            </Button>
          ))}
        </div>

        <SearchByIdInput id="charterSearch" onChange={onSearchedIdChange} />
      </div>

      <Spacer xs={4} />

      {loading ? (
        <Loading visible={true} center className="w-full h-[612px]">
          <Loading.Indicator size={100} borderWidth={2} />
        </Loading>
      ) : (
        <Table className="min-h-[612px]">
          {selectedFilter === CharterFilter.Enquiries ? (
            <>
              <CharterEnquireTableHeader />
              <Table.Body>
                {!loading &&
                  charters.map((item) =>
                    'quote' in item ? <CharterEnquireTableItem key={item.id} data={item} onAction={reload} /> : null
                  )}
              </Table.Body>
            </>
          ) : (
            <>
              <CharterTableHeader />
              <Table.Body>
                {!loading &&
                  charters.map((item) =>
                    'legs' in item ? <CharterTableItem key={item.id} data={item} onAction={reload} /> : null
                  )}
              </Table.Body>
            </>
          )}
        </Table>
      )}

      <Spacer xs={3} />

      <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} className="px-10" />

      <Spacer xs={10} />
    </div>
  );
};

export default Charters;
