import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { STRING_LONG_MAX_LENGTH } from '@/constants';
import { createVendor, inviteVendorUser } from '@/services/api/vendors';

import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

interface Props extends InstanceProps {
  onSubmit: () => any;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.name.label)
    .required(),
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);

const NewVendorModal: React.FC<Props> = ({ close, onSubmit }) => {
  const intl = useIntl();

  return (
    <Modal.Card className="w-[420px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.newVendorModal.title} />
      </Modal.Header>

      <Card.Row padded>
        <Form
          schema={schema}
          subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
          onSubmit={({ name, email }) =>
            createVendor(name).then(({ id }) =>
              inviteVendorUser(id, email).then(() => {
                onSubmit();
                close();
              })
            )
          }
          className="flex flex-col w-full"
        >
          {({ submitError, submitting, valid, dirty }) => (
            <React.Fragment>
              <Form.Field
                is={TextInputWithValidation}
                id="name"
                name="name"
                type="text"
                leadingIcon="crew"
                inputClassName="w-full"
                label={intl.formatMessage({ id: translations.inputs.vendorName.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.vendorName.placeholder })}
                appearance="light"
              />

              <Spacer xs={4} />

              <Form.Field
                is={TextInputWithValidation}
                id="email"
                name="email"
                type="text"
                leadingIcon="email"
                inputClassName="w-full"
                label={intl.formatMessage({ id: translations.inputs.email.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                appearance="light"
              />

              <Spacer xs={2} />

              {!!submitError ? <SubmitError error={submitError} /> : <Spacer xs={5} />}

              <Button
                type="submit"
                className="font-medium ml-auto w-[150px]"
                appearance="black"
                disabled={!valid || !dirty || submitting}
              >
                <FormattedMessage id={translations.modals.newVendorModal.addVendor} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card.Row>
    </Modal.Card>
  );
};

export default NewVendorModal;
