import React from 'react';
import range from 'lodash/range';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

interface PageIndicatorProps {
  page: number;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  selected?: boolean;
}

const PageIndicator: React.FC<PageIndicatorProps> = ({ page, selected, onClick }) => (
  <Button
    type="button"
    appearance={selected ? 'white' : 'transparent'}
    className={classnames(' w-12 my-1 mx-[2px] first:ml-1 last:mr-1')}
    value={page}
    onClick={onClick}
  >
    <Typography is="span" type="halcyon">
      {page}
    </Typography>
  </Button>
);

export interface Props {
  pages: number;
  selected: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const PageSelector: React.FC<Props> = ({ pages, selected, onPageChange, className }) => {
  const pagesToShow = 5;

  const displayPageIndicators = (pages: number, selected: number) => {
    let pagesToRender: number[] = range(1, Math.min(pagesToShow, pages) + 1);

    if (pages < pagesToShow) pagesToRender = range(1, pages + 1);
    else {
      if (selected <= 3) pagesToRender = range(1, pagesToShow + 1);

      if (selected > 3 && selected < pages - 2) pagesToRender = range(selected - 2, selected + 3);

      if (selected >= pages - 2) pagesToRender = range(pages - pagesToShow + 1, pages + 1);
    }

    return pagesToRender.map((value) => (
      <PageIndicator
        key={value}
        page={value}
        selected={value === selected}
        onClick={value !== selected ? () => onPageChange(value) : null}
      />
    ));
  };

  return (
    <React.Fragment>
      {!!pages && pages > 1 ? (
        <div className={classnames('flex flex-row items-center justify-end', className)}>
          <Button
            appearance="transparent"
            className="mr-6"
            type="button"
            onClick={() => onPageChange(Math.max(selected - 1, 1))}
            disabled={selected === 1}
          >
            <Typography is="span" type="halcyon">
              <FormattedMessage id={translations.inputs.buttons.pagedNavigation.previous} />
            </Typography>
          </Button>

          <div className="flex flex-row bg-white bg-opacity-[10%] rounded-md">
            {displayPageIndicators(pages, selected)}
          </div>

          <Button
            appearance="transparent"
            className="ml-6"
            type="button"
            onClick={() => onPageChange(Math.min(selected + 1, pages))}
            disabled={selected === pages}
          >
            <Typography is="span" type="halcyon">
              <FormattedMessage id={translations.inputs.buttons.pagedNavigation.next} />
            </Typography>
          </Button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default PageSelector;
