import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getCities } from '@/services/api/city';

import usePagination from '@flyblack/common/hooks/usePagination';

import Button from '@flyblack/common/components/Button';
import Container from '@flyblack/common/components/Container';
import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import Loading from '@flyblack/common/components/Loading';
import Table from '@flyblack/common/components/Table';
import PageSelector from '@flyblack/common/components/PageSelector';
import Modal from '@flyblack/common/components/Modal';

import CityTableHeader from './CityTableHeader';
import CityTableItem from './CityTableItem';
import AddCityModal from './AddCityModal';

import SettingsNavigation from '../SettingsNavigation';

const Cities = () => {
  const { loading, data: cities, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size) => getCities(page, size, { status: 'ALL', type: 'INTERNAL_AND_PLACE_API' })
  });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex h-14 items-center justify-between">
          <SettingsNavigation />

          <Modal modal={AddCityModal} onSuccess={reload}>
            {({ open }) => (
              <Button appearance="white" onClick={open}>
                <Icon type="add" className="pr-2" />

                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.cities.addCity} />
                </Typography>
              </Button>
            )}
          </Modal>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible center className="w-full h-[612px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <CityTableHeader />

            <Table.Body>
              {!loading && cities.map((item) => <CityTableItem key={item.id} data={item} onAction={reload} />)}
            </Table.Body>
          </Table>
        )}

        <Spacer xs={3} />

        <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default Cities;
