import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CharterContactInfo } from '@/domains/charter';

import Spacer from '@flyblack/common/components/Spacer';
import DetailRow from '@flyblack/common/components/DetailRow';
import Typography from '@flyblack/common/components/Typography';

import RequestedBySection from './RequestedBySection';
import CharterPriceSection from './CharterPriceSection';

interface Props {
  contactInfo: CharterContactInfo;
  formId: string;
  submitting: boolean;
}

const GeneralDetailsSection = ({ contactInfo, formId, submitting }: Props) => (
  <React.Fragment>
    <Typography is="span" type="flamingo" className="mr-auto">
      <FormattedMessage id={translations.pages.charters.detail.generalDetails} />
    </Typography>

    <Spacer xs={4} />

    <DetailRow>
      <CharterPriceSection formId={formId} submitting={submitting} />

      <RequestedBySection contactInfo={contactInfo} />
    </DetailRow>
  </React.Fragment>
);

export default GeneralDetailsSection;
