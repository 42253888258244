import React from 'react';
import classnames from 'classnames';

// @ts-ignore
import style from './Typography.sass';

type Type =
  | 'ostrich'
  | 'flamingo'
  | 'albatross'
  | 'swan'
  | 'hawk'
  | 'corvus'
  | 'owl'
  | 'sparrow'
  | 'halcyon'
  | 'hummingbird';
export type Color = 'orange' | 'blue' | 'red' | 'gray' | 'lightGray' | 'white' | 'green' | 'darkerGray' | 'black';
type WeightType = 'lighter' | 'regular' | 'medium' | 'bold' | 'bolder';

const colorToClassNameMap: Record<Color, string> = {
  orange: 'text-yellow-600',
  blue: 'text-blue-500',
  red: 'text-red-600',
  gray: 'text-flyblack-gray',
  lightGray: 'text-flyblack-light-gray',
  darkerGray: 'text-flyblack-darker-gray',
  white: 'text-white',
  green: 'text-green-600',
  black: 'text-black'
};

const weightTypeToClassNameMap: Record<WeightType, string> = {
  lighter: 'font-light',
  regular: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold',
  bolder: 'font-extrabold'
};

export interface Props<T> {
  is: React.ElementType<T> | 'span' | 'div';
  type?: Type;
  color?: Color;
  weight?: WeightType;
  className?: string;
  [x: string]: any;
}

export default class Typography<T extends any> extends React.PureComponent<Props<T>> {
  render() {
    const { is: Component, type = 'halcyon', color, weight, className, ...rest } = this.props;

    return (
      // @ts-ignore
      <Component
        {...rest}
        className={classnames(
          style[type!],
          // `${type}`,
          !!color && colorToClassNameMap[color],
          !!weight && weightTypeToClassNameMap[weight],
          className
        )}
      />
    );
  }
}
