import React from 'react';
import classnames from 'classnames';
import { parse, format, addDays, subDays, isValid } from 'date-fns';

import { Props as TextInputProps } from '@flyblack/common/components/Form/Input/TextInput';
import DateInput, { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import DatePicker from '@flyblack/common/components/Calendar/DatePicker';

const dateDisplayFormat = 'MM/dd/yyyy';

interface Props extends Omit<TextInputProps, 'value'> {
  value: string;
  change: (name: string, value: any) => void;
}

interface State {
  isOpen: boolean;
}

class DatePickerInput extends React.PureComponent<Props, State> {
  state: State = {
    isOpen: false
  };

  timeoutID = null;

  onFocus = (event) => {
    if (!this.props.disabled) {
      clearTimeout(this.timeoutID);
      if (!this.state.isOpen) {
        this.setState({ isOpen: true });
      }
      this.props.onFocus && this.props.onFocus(event);
    }
  };

  onBlur = (event) => {
    if (!this.props.disabled) {
      this.timeoutID = setTimeout(() => {
        if (this.state.isOpen) {
          this.setState({ isOpen: false });
        }
      }, 0);
      this.props.onBlur && this.props.onBlur(event);
    }
  };

  goToNextDay = (event) => {
    const newDate = addDays(parse(this.props.value, dateFormat, new Date()), 1);
    this.props.change(this.props.name, format(newDate, dateFormat));
  };

  goToPreviousDay = (event) => {
    const newDate = subDays(parse(this.props.value, dateFormat, new Date()), 1);
    this.props.change(this.props.name, format(newDate, dateFormat));
  };

  render() {
    const { change, inputClassName, className, ...rest } = this.props;

    return (
      <div className={classnames('relative', className)} onFocus={this.onFocus} onBlur={this.onBlur} tabIndex={-1}>
        <DateInput
          {...rest}
          inputClassName={classnames('bg-flyblack-gray', inputClassName)}
          onBlur={({ target: { value: newValue } }) => {
            const newDate = parse(newValue, dateDisplayFormat, new Date());
            if (isValid(newDate)) {
              change(this.props.name, format(newDate, dateFormat));
            }
          }}
          onChange={() => null}
          leadingIcon="calendar"
        />

        {this.state.isOpen && (
          <DatePicker
            className="absolute top-[100%] left-0 z-[1]"
            date={this.props.value && parse(this.props.value, dateFormat, new Date())}
            selectDate={(date: Date) => change(this.props.name, format(date, dateFormat))}
          />
        )}
      </div>
    );
  }
}

export default DatePickerInput;
