import React from 'react';
import classnames from 'classnames';
import { startOfDay, isEqual } from 'date-fns';

import Typography from '@flyblack/common/components/Typography';

export interface RenderProps {
  dayNumber: string;
  disabled: boolean;
  isSelected: boolean;
}

export interface Props {
  dayNumber: string;
  date: Date;
  disabled?: boolean;
  isSelected: boolean;
  onSelectDate: (date: Date) => void;
  children?: (renderProps: RenderProps) => any;
  className?: string;
}

const Day = ({ dayNumber, date, disabled, isSelected, onSelectDate, children, className }: Props) => {
  const isToday = isEqual(startOfDay(new Date()), startOfDay(date));

  const onClick = () => !disabled && onSelectDate(date);

  return (
    <div className={classnames('my-[2px]', className)}>
      <div
        className={classnames(
          `h-[40px] w-[40px] mx-[2px]
          flex items-center justify-center
          rounded-full`,
          disabled
            ? isSelected
              ? 'hover:cursor-default bg-flyblack-black-24 text-white'
              : 'hover:cursor-default text-flyblack-black-24'
            : isSelected
            ? 'hover:cursor-pointer bg-black text-white hover:bg-black hover:text-white hover:bg-opacity-90'
            : 'hover:cursor-pointer text-black hover:bg-black hover:text-black hover:bg-opacity-10',
          isToday && 'border-flyblack-lighter-gray border-solid border-[1px]'
        )}
        onClick={onClick}
      >
        {children ? (
          children({ dayNumber, disabled: !!disabled, isSelected })
        ) : (
          <Typography is="span" type="hummingbird">
            {dayNumber}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Day;
