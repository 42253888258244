import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { Airport } from '@flyblack/common/domains';
import { getAirports } from '@/services/api/airport';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import DateTime from '@flyblack/common/components/DateTime';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import LoadableSelect from '@flyblack/common/components/LoadableSelect';
import { withSource } from '@flyblack/common/components/hoc/withSource';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

const AirportSelectWithValidation = withValidation(
  withSource<Airport[]>(LoadableSelect, () =>
    getAirports().then((reponse) =>
      reponse.map((item) => ({
        id: item.id,
        name: `${item.faaCode} ${item.city.name}`
      }))
    )
  )
);

interface Props {
  formId: string;
  submitting: boolean;
  form;
  isBooked?: boolean;
  type: 'departure' | 'arrival';
  editMode?: boolean;
}

const TravelDetailsSection: React.FC<Props> = ({ formId, submitting, form, isBooked, type, children, editMode }) => {
  const intl = useIntl();

  return (
    <DetailCard className="mb-0">
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage
          id={translations.pages.shuttles.detail.sections[type === 'departure' ? 'departure' : 'destination']}
        />
      </Typography>

      <Spacer xs={4} />

      <Form.Field
        is={AirportSelectWithValidation}
        id={`${formId}-${type === 'departure' ? 'source-airport-id' : 'destination-airport-id'}`}
        name={type === 'departure' ? 'sourceAirportId' : 'destinationAirportId'}
        type="text"
        label={intl.formatMessage({
          id: translations.inputs[type === 'departure' ? 'departureLocationSelect' : 'destinationLocationSelect'].label
        })}
        placeholder={intl.formatMessage({
          id:
            translations.inputs[type === 'departure' ? 'departureLocationSelect' : 'destinationLocationSelect']
              .placeholder
        })}
        readOnly={submitting}
        disabled={isBooked || editMode}
        leadingIcon={type === 'departure' ? 'departure' : 'destination'}
      />

      <Spacer xs={4} />

      <div className="flex justify-between items-end">
        <DateTime
          formId={formId}
          type={type}
          title={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureDateTime' : 'arrivalDateTime'].label
          })}
          timePlaceholder={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureDateTime' : 'arrivalDateTime'].placeholder
          })}
          change={form.change}
          submitting={submitting}
          disabledDate={isBooked}
        />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-${type === 'departure' ? 'source-airport-terminal' : 'destination-airport-terminal'}`}
          name={type === 'departure' ? 'sourceAirportTerminal' : 'destinationAirportTerminal'}
          type="text"
          label={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureTerminal' : 'destinationTerminal'].label
          })}
          placeholder={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureTerminal' : 'destinationTerminal'].placeholder
          })}
          readOnly={submitting}
          leadingIcon="terminal"
          className="w-[180px]"
        />
      </div>

      {children}
    </DetailCard>
  );
};

export default TravelDetailsSection;
