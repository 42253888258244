import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {}

const CharterTableHeader: React.FC<Props> = () => {
  const text = translations.pages.charters.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] px-10 justify-between">
      <Table.Data className="pr-1 w-24" header>
        <FormattedMessage id={text.type} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.departure} />
      </Table.Data>

      <Table.Data className="pr-1 w-[15%]" header>
        <FormattedMessage id={text.dateTime} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.arrival} />
      </Table.Data>

      <Table.Data className="pr-1 w-[15%]" header>
        <FormattedMessage id={text.dateTime} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.category} />
      </Table.Data>

      <Table.Data className="pr-1 w-24" header>
        <FormattedMessage id={text.price} />
      </Table.Data>

      <Table.Data className="pr-1 w-[160px] justify-end" header>
        <FormattedMessage id={text.status} />
      </Table.Data>

      <Table.Data className="w-4" header />
    </Typography>
  );
};

export default CharterTableHeader;
