export enum CityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export interface City {
  countryCode: string;
  id: number;
  name: string;
  photoUrl: string;
  state: string;
  zoneId: string;
  placeId: string;
  userCount: number;
  status: CityStatus;
}
