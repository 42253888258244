import React from 'react';
import { range } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { translations } from '@/locale';
import { CharterLegSummary, ScheduleType } from '@flyblack/common/domains';
import { getDateFormat, getTime } from '@flyblack/common/util';
import { getCharter, updateCharter } from '@/services/api/charter';
import { CharterDetail } from '@/domains/charter';

import Form from '@flyblack/common/components/Form';
import useLoad from '@flyblack/common/hooks/useLoad';
import Modal from '@flyblack/common/components/Modal';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import DetailRow from '@flyblack/common/components/DetailRow';
import Typography from '@flyblack/common/components/Typography';
import { SubmitError } from '@flyblack/common/components/Error';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import PassengersSection from '@flyblack/common/components/PassengersSection';

import PilotsSection from '@/components/PilotsSection/PilotsSection';
import AircraftInfoSection from '@/components/AircraftInfoSection/AircraftInfoSection';
import TravelDetailsSection from '@/components/TravelDetailsSection/TravelDetailsSection';

import TitleSection from './TitleSection';
import RequestedBySection from './RequestedBySection';
import CharterPriceSection from './CharterPriceSection';
import CharterDetailButtons from './CharterDetailButtons';
import GeneralDetailsSection from './GeneralDetailsSection';

export interface Props {}

interface Params {
  id: string;
}

const DEFAULT_CURRENCY = 'USD';

const loadCharter = (id?: number) => (id ? getCharter(id) : new Promise<CharterDetail>((resolve) => resolve(null)));

const EditCharter: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const [selectedLeg, setSelectedLeg] = React.useState(0);
  const intl = useIntl();

  const convertLeg = (data: CharterLegSummary) => ({
    aircraftCategoryId: data.aircraftType ? data.aircraftType.aircraftCategory.id : data.aircraftCategory.id,
    aircraftTypeId: data.aircraftType ? data.aircraftType.id : null,
    destination: data.destinationAirport.icaoCode,
    destinationAirportTerminal: data.destinationAirport.terminal,
    flightId: data.flightId,
    pilots: data.pilots,
    schedule: data.schedule,
    source: data.sourceAirport.icaoCode,
    sourceAirportTerminal: data.sourceAirport.terminal,
    tailNumber: data.tailNumber
  });

  const { value: data, loading, reload } = useLoad({
    load: loadCharter,
    id: parseInt(match.params.id)
  });

  return (
    !loading && (
      <div className="min-h-full flex justify-center py-20">
        <div className="w-full max-w-[1080px]">
          <Form
            id="jet-charter"
            subscription={{ submitting: true, submitError: true, dirty: true, values: true, hasValidationErrors: true }}
            initialValues={{
              sourceAirportId: data.legs[selectedLeg].sourceAirport.icaoCode,
              departureDate: getDateFormat(data.legs[selectedLeg].schedule.departsAt, dateFormat),
              departureTime: getTime(data.legs[selectedLeg].schedule.departsAt),
              sourceAirportTerminal: data.legs[selectedLeg].sourceAirport.terminal,
              destinationAirportId: data.legs[selectedLeg].destinationAirport.icaoCode,
              arrivalDate: getDateFormat(data.legs[selectedLeg].schedule.arrivesAt, dateFormat),
              arrivalTime: getTime(data.legs[selectedLeg].schedule.arrivesAt),
              destinationAirportTerminal: data.legs[selectedLeg].destinationAirport.terminal,
              flightId: data.legs[selectedLeg].flightId,
              aircraftCategoryId: data.legs[selectedLeg].aircraftType
                ? data.legs[selectedLeg].aircraftType.aircraftCategory.id
                : data.legs[selectedLeg].aircraftCategory.id,
              aircraftTypeId: data.legs[selectedLeg].aircraftType ? data.legs[selectedLeg].aircraftType.id : null,
              tailNumber: data.legs[selectedLeg].tailNumber,
              firstPilot: data.legs[selectedLeg].pilots[0] || '',
              secondPilot: data.legs[selectedLeg].pilots[1] || '',
              price: data.price.value,
              status: data.status
            }}
            onSubmit={(values) => {
              const { price, status, ...rest } = values as any;

              const legs = data.legs.map((leg, index) =>
                index === selectedLeg
                  ? {
                      aircraftCategoryId: rest.aircraftCategoryId,
                      aircraftTypeId: rest.aircraftTypeId || null,
                      destination: rest.destinationAirportId,
                      destinationAirportTerminal: rest.destinationAirportTerminal,
                      flightId: rest.flightId,
                      pilots: [rest.firstPilot, rest.secondPilot],
                      schedule: {
                        type: ScheduleType.Fixed,
                        departsAt: `${rest.departureDate}T${rest.departureTime}:59`,
                        arrivesAt: `${rest.arrivalDate}T${rest.arrivalTime}:59`
                      },
                      source: rest.sourceAirportId,
                      sourceAirportTerminal: rest.sourceAirportTerminal,
                      tailNumber: rest.tailNumber
                    }
                  : convertLeg(leg)
              );

              return updateCharter({ price: { value: price, currency: DEFAULT_CURRENCY }, status, legs }, data.id).then(
                reload
              );
            }}
            className="w-full flex flex-col items-center"
          >
            {({ submitError, submitting, values, form, dirty, hasValidationErrors }, formId) => (
              <React.Fragment>
                <TitleSection
                  //@ts-ignore
                  initialStatus={data.status}
                  //@ts-ignore
                  selectedStatus={values.status}
                  formId={formId}
                  submitting={submitting}
                  dirty={dirty}
                  hasValidationErrors={hasValidationErrors}
                  submitError={submitError}
                />

                {data.legs.length > 1 && (
                  <React.Fragment>
                    <Spacer xs={6} />

                    <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-sm mr-auto max-w-[1080px]">
                      {range(data.legs.length).map((flight) => (
                        <Modal
                          key={flight}
                          modal={ConfirmationModal}
                          title={intl.formatMessage({ id: translations.modals.confirmationModals.charterTabSwitch })}
                          confirmMessage={intl.formatMessage({ id: translations.modals.confirmationModals.yes })}
                          cancelMessage={intl.formatMessage({ id: translations.modals.confirmationModals.no })}
                          onConfirm={() => {
                            setSelectedLeg(flight);
                            return Promise.resolve(true);
                          }}
                          onCancel={() => Promise.resolve(true)}
                        >
                          {({ open: openModal }) => (
                            <Button
                              type="button"
                              appearance={selectedLeg === flight ? 'white' : 'transparent'}
                              className="mx-1 first:mx-0 last:mx-0 w-[150px] font-normal"
                              onClick={!dirty ? () => setSelectedLeg(flight) : openModal}
                              disabled={false}
                            >
                              <Typography is="span" type="halcyon">
                                <FormattedMessage
                                  id={translations.pages.charters.detail.tabTitle}
                                  values={{ number: flight + 1 }}
                                />
                              </Typography>
                            </Button>
                          )}
                        </Modal>
                      ))}
                    </div>
                  </React.Fragment>
                )}

                <Spacer xs={3} />

                <Typography is="div" type="owl" className="w-full">
                  <FormattedMessage
                    id={translations.pages.charters.detail.flightId}
                    values={{ flightId: data.legs[selectedLeg].flightId }}
                  />
                </Typography>

                <Spacer xs={3} />

                <DetailRow>
                  <TravelDetailsSection
                    formId={formId}
                    submitting={submitting}
                    form={form}
                    type="departure"
                    airportSelectDisabled={false}
                    dateSelectDisabled={false}
                    timeSelectDisabled={false}
                    terminalInputDisabled={false}
                  />

                  <TravelDetailsSection
                    formId={formId}
                    submitting={submitting}
                    form={form}
                    type="arrival"
                    airportSelectDisabled={false}
                    dateSelectDisabled={false}
                    timeSelectDisabled={false}
                    terminalInputDisabled={false}
                  />
                </DetailRow>

                <Spacer xs={4} />

                <DetailRow>
                  <AircraftInfoSection formId={formId} form={form} submitting={submitting} values={values} />

                  <PilotsSection formId={formId} submitting={submitting} />
                </DetailRow>

                <Spacer xs={4} />

                <DetailRow>
                  {data.legs.length > 1 ? (
                    <div />
                  ) : (
                    <CharterPriceSection isBooked={false} formId={formId} submitting={submitting} />
                  )}

                  <PassengersSection
                    passengers={data.legs[selectedLeg].passengers}
                    pets={data.legs[selectedLeg].pets}
                    translations={translations.pages.charters.detail.passengersSection}
                    className="mb-0 w-full max-w-[528px]"
                  />
                </DetailRow>

                <Spacer xs={4} />

                {data.legs.length < 2 ? (
                  <DetailRow>
                    <RequestedBySection contactInfo={data.contactInfo} />

                    <div />
                  </DetailRow>
                ) : (
                  <React.Fragment>
                    <Spacer xs={7} />

                    <GeneralDetailsSection contactInfo={data.contactInfo} formId={formId} submitting={submitting} />
                  </React.Fragment>
                )}

                <Spacer xs={7} />

                {submitError && (
                  <div className="flex flex-col items-end">
                    <SubmitError error={submitError} />
                    <Spacer xs={6} />
                  </div>
                )}

                <div className="w-full">
                  <CharterDetailButtons
                    formId={formId}
                    submitting={submitting}
                    disabledSubmit={!dirty || hasValidationErrors}
                  />
                </div>
              </React.Fragment>
            )}
          </Form>
        </div>
      </div>
    )
  );
};

export default EditCharter;
