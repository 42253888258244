import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import { REJECT_MEMBERSHIP_REASON_MAX_LENGTH } from '@/constants';
import { rejectMembership } from '@/services/api/membership';

import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

interface Props extends InstanceProps {
  id: number;
  onSuccess?: () => any;
}

const schema = yup.object().shape({
  reason: yup
    .string()
    .max(REJECT_MEMBERSHIP_REASON_MAX_LENGTH)
    .label(translations.inputs.membershipRejectionReason.label)
    .required()
});

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);

const RejectMembershipApplicationModal: React.FC<Props> = ({ id, onSuccess, close }) => {
  const intl = useIntl();

  return (
    <Modal.Card className="w-[420px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.rejectMembershipApplicationModal.title} />
      </Modal.Header>

      <Card.Row padded>
        <Form
          schema={schema}
          initialValues={{
            reason: intl.formatMessage({ id: translations.modals.rejectMembershipApplicationModal.reason })
          }}
          subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
          onSubmit={({ reason }) =>
            rejectMembership(id, reason).then(() => {
              close();
              onSuccess && onSuccess();
            })
          }
          className="flex flex-col w-full"
        >
          {({ submitError, submitting, valid, dirty }) => (
            <React.Fragment>
              <Form.Field
                is={TextAreaWithValidation}
                id="reason"
                name="reason"
                type="text"
                inputClassName="resize-none h-32"
                label={intl.formatMessage({ id: translations.inputs.membershipRejectionReason.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.membershipRejectionReason.placeholder })}
                appearance="light"
              />

              <Spacer xs={2} />

              {!!submitError ? <SubmitError error={submitError} /> : <Spacer xs={5} />}

              <Button
                type="submit"
                className="font-medium ml-auto w-[150px]"
                appearance="black"
                disabled={!valid || submitting}
              >
                <FormattedMessage id={translations.modals.rejectMembershipApplicationModal.reject} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card.Row>
    </Modal.Card>
  );
};

export default RejectMembershipApplicationModal;
