import React from 'react';

import Spacer from '@flyblack/common/components/Spacer';
import Container from '@flyblack/common/components/Container';
import RowContainer from '@flyblack/common/components/RowContainer';

import OpenFlightsCard from './OpenFlightsCard';
import BookedFlightsCard from './BookedFlightsCard';
import TotalHoursFlownCard from './TotalHoursFlownCard';
import RevenueChartCard from './RevenueChartCard';

const PromoCodes = () => {
  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <RowContainer>
          <RowContainer.Item col={9}>
            <RowContainer>
              <RowContainer.Item col={6}>
                <OpenFlightsCard />
              </RowContainer.Item>

              <RowContainer.Item col={6}>
                <BookedFlightsCard />
              </RowContainer.Item>
            </RowContainer>
          </RowContainer.Item>

          <RowContainer.Item col={3}>
            <TotalHoursFlownCard />
          </RowContainer.Item>
        </RowContainer>

        <RevenueChartCard />

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default PromoCodes;
