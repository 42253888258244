import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import { Pricing } from '@flyblack/common/domains';
import { setFriendReferralCreditsValue } from '@/services/api/friend-fererral';

import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import Typography from '@flyblack/common/components/Typography';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const DEFAULT_CURRENCY = 'USD';

interface Props extends InstanceProps {
  data?: Pricing;
  onSuccess?: () => any;
}

const schema = yup.object().shape({
  value: yup
    .number()
    .label(translations.inputs.friendReferralCreditValue.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);

const FriendReferralCreditModal = ({ data, onSuccess, close }: Props) => {
  const intl = useIntl();

  return (
    <Modal.Card className="w-[384px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.friendReferralModal.title} />
      </Modal.Header>

      <Form
        schema={schema}
        initialValues={data}
        subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
        onSubmit={({ value }) =>
          setFriendReferralCreditsValue(DEFAULT_CURRENCY, value).then(() => {
            onSuccess && onSuccess();
            close();
          })
        }
      >
        {({ submitError, submitting, valid, dirty, form }) => (
          <React.Fragment>
            <Card.Row padded className="flex flex-col">
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.modals.friendReferralModal.subtitle} />
              </Typography>

              <Spacer xs={4} />

              <Form.Field
                is={TextInputWithValidation}
                id="value"
                name="value"
                type="text"
                label={intl.formatMessage({ id: translations.inputs.friendReferralCreditValue.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.friendReferralCreditValue.placeholder })}
                leadingIcon="dollar"
                className="w-[180px]"
                appearance="light"
              />

              {!!submitError ? <SubmitError error={submitError} className="h-10" /> : <Spacer xs={5} />}
            </Card.Row>

            <Card.Row padded>
              <Button
                type="submit"
                className="font-medium ml-auto w-[170px]"
                appearance="black"
                disabled={!valid || !dirty || submitting}
              >
                <FormattedMessage id={translations.inputs.buttons.save} />
              </Button>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    </Modal.Card>
  );
};

export default FriendReferralCreditModal;
