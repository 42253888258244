import React from 'react';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { HttpError } from '@/services/network/http';
import { translations } from '@/locale';

import Typography, { Color } from '@flyblack/common/components/Typography';

// @ts-ignore
import style from './Error.sass';

export const isValidError = (error: HttpError<any> | undefined | null) => {
  if (!error) return false;

  return (
    (error.isAxiosError && error.response.status === 403) ||
    (error.isAxiosError && error.response && error.response.data && error.response.data.errors
      ? !!error.response.data.errors.find(({ code }) => !!translations.errors.api[code])
      : !!error.message)
  );
};

interface Props {
  error: HttpError<any> | undefined | null;
  color?: Color;
  className?: string;
  setCustomError?: (errorObject: any) => string;
}

const Error: React.FC<Props & WrappedComponentProps> = ({
  intl,
  error,
  color = 'red',
  children,
  className,
  setCustomError
}) => {
  if (!error) return null;

  let validErrors = null;

  if (error.isAxiosError && error.response.status === 403)
    validErrors = [intl.formatMessage({ id: translations.errors.api.forbidden })];
  else {
    const errors =
      error.isAxiosError && error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors.map((errorObject) =>
            translations.errors.api[errorObject.code]
              ? intl.formatMessage({ id: translations.errors.api[errorObject.code] })
              : setCustomError
              ? setCustomError(errorObject)
              : null
          )
        : [error.message];

    validErrors = errors.filter((error) => !!error);
  }

  return (
    <React.Fragment>
      <Typography className={classnames(style.root, className)} is="div" type="hummingbird" color={color}>
        {validErrors.length > 0
          ? validErrors.map((error, index) => <div key={index}>{error}</div>)
          : intl.formatMessage({ id: translations.errors.custom.unknown })}
      </Typography>

      {children}
    </React.Fragment>
  );
};

export const SubmitError = injectIntl(Error);
