import React from 'react';
import classnames from 'classnames';

import Content from './Content';

export interface Props {
  max?: boolean;
  removePaddingOnMobile?: boolean;
  className?: string;
}

export default class Container extends React.PureComponent<Props> {
  static Content = Content;

  static defaultProps: Partial<Props> = {
    max: false
  };
  render() {
    const { max, removePaddingOnMobile, className, ...rest } = this.props;
    return (
      <div
        {...rest}
        className={classnames(
          'relative w-full mx-auto px-[24px] sm:px-[40px]',
          max && 'max-w-[1440px]',
          removePaddingOnMobile && 'px-0 sm:px-[40px]',
          className
        )}
      />
    );
  }
}
