import React from 'react';

import { Airport } from '@flyblack/common/domains';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';
import Modal from '@flyblack/common/components/Modal';

import AirportTableItemMenu from './AirportTableItemMenu';
import AirportModal from './AirportModal';

export interface Props {
  data: Airport;
  onAction: () => any;
}

const AirportTableItem: React.FC<Props> = ({ data, onAction }) => {
  return (
    <Modal modal={AirportModal} data={data} onSuccess={onAction}>
      {({ open }) => (
        <Typography
          is={Table.Row}
          onClick={open}
          className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
          type="halcyon"
          hoverable
        >
          <Table.Data className="pr-1 w-[20%]">{data.city.name}</Table.Data>

          <Table.Data className="pr-1 w-[10%]">{data.iataCode}</Table.Data>

          <Table.Data className="pr-1 w-[10%]">{data.icaoCode}</Table.Data>

          <Table.Data className="pr-1 w-[10%]">{data.faaCode}</Table.Data>

          <Table.Data className="pr-1 w-[30%]">{data.name}</Table.Data>

          <Table.Data className="w-4 justify-end" stopPropagation>
            <AirportTableItemMenu airportId={data.id} onAction={onAction} />
          </Table.Data>
        </Typography>
      )}
    </Modal>
  );
};

export default AirportTableItem;
