import { TimeInterval } from './time-interval';

export enum ScheduleType {
  Flexible = 'flexible',
  Fixed = 'fixed'
}

export interface FixedSchedule {
  type: ScheduleType.Fixed;
  departsAt: string;
  arrivesAt: string;
}

export interface FlexibleSchedule {
  type: ScheduleType.Flexible;
  departureInterval: TimeInterval;
  arrivalInterval: TimeInterval;
}
