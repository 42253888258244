import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  isApprovable?: boolean;
}

const PendingTableHeader: React.FC<Props> = ({ isApprovable }) => {
  const text = translations.pages.membership.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] justify-between font-bold">
      <Table.Data className="w-[30%]" header>
        <FormattedMessage id={text.name} />
      </Table.Data>

      <Table.Data className="w-[40%]" header>
        <FormattedMessage id={text.email} />
      </Table.Data>

      <Table.Data className="w-[15%]" header>
        <FormattedMessage id={text.requestDate} />
      </Table.Data>

      <Table.Data className="w-[15%]" header>
        {isApprovable && <FormattedMessage id={text.approval} />}
      </Table.Data>
    </Typography>
  );
};

export default PendingTableHeader;
