import React from 'react';
import classnames from 'classnames';

import Loading from '@flyblack/common/components/Loading';
import Icon, { Type } from '@flyblack/common/components/Icon';

// TODO add all appearances when the styleguide is done
export type ButtonAppearance =
  | 'white'
  | 'grey'
  | 'gray'
  | 'transparent'
  | 'underlined'
  | 'ghost'
  | 'lightGhost'
  | 'black';

const appearanceToClassNameMap: Record<ButtonAppearance, string> = {
  white: 'px-3 bg-white disabled:bg-flyblack-light-gray text-black border-transparent',
  grey: 'px-3 bg-white disabled:bg-flyblack-gray text-white border-transparent',
  gray: 'px-3 bg-white disabled:bg-flyblack-gray text-white border-transparent',
  black: 'px-3 bg-black disabled:bg-flyblack-gray text-white border-transparent',
  ghost: 'px-3 text-white bg-black hover:bg-flyblack-gray hover:transition-colors border-solid border-white',
  lightGhost: 'px-3 text-black bg-white hover:bg-flyblack-light-gray hover:transition-colors border-solid border-black',
  transparent: 'px-3 text-white hover:bg-flyblack-dark-gray hover:transition-colors border-transparent',
  underlined: 'underline border-transparent'
};

export interface Props extends React.ButtonHTMLAttributes<any> {
  is?: any;
  appearance?: ButtonAppearance;
  loading?: boolean;
  [x: string]: any;
  fat?: boolean;
  trailingIcon?: Type;
}

const Button: React.FC<Props> = ({
  is: Component = 'button',
  appearance = null,
  loading = null,
  disabled,
  className,
  fat,
  trailingIcon,
  // TODO This is a known bug in Safari anchors with type="button" ignoring all styles.
  // This prop should be removed from the button component because this component is generic and might not contain the type prop.
  // Ticket: https://halcyonmobile.jira.com/jira/software/projects/FLYB/boards/470?selectedIssue=FLYB-1313
  type = 'button',
  children,
  onClick,
  ...rest
}) => {
  return (
    <Component
      type={type}
      className={classnames(
        `relative
         inline-flex items-center justify-center
         border
         text-sm
         leading-6
         rounded-sm
         disabled:cursor-default
         focus:outline-none focus:ring-0 ${fat ? 'h-[56px]' : 'h-12'}`,
        appearance ? appearanceToClassNameMap[appearance] : 'border-transparent',
        className
      )}
      disabled={loading || disabled}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {!!loading && (
        <Loading visible={!!loading} className="mr-2">
          <Loading.Indicator size={24} borderWidth={3} />
        </Loading>
      )}

      <span className="inline-flex items-center justify-center">{children}</span>

      {!!trailingIcon && <Icon type={trailingIcon} className={`absolute right-0 ${fat ? 'pr-6' : 'pr-4'}`} />}
    </Component>
  );
};

export default Button;
