export enum MediaFileType {
  Id = 'IDENTITY_DOCUMENT',
  ProfilePicture = 'PROFILE_PICTURE',
  Aircraft = 'AIRCRAFT'
}

export interface Media {
  imageUrl: string;
}

export interface Image {
  id?: string;
  url: string;
}

export interface GalleryImage extends Image {
  type: 'GALLERY' | 'MAIN_PHOTO';
}
