import classNames from 'classnames';
import React from 'react';

export type ColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const colSizeToClassName = (colSize: ColSize) => {
  switch (colSize) {
    case 1:
      return 'w-1/12';
    case 2:
      return 'w-1/6';
    case 3:
      return 'w-1/4';
    case 4:
      return 'w-1/3';
    case 5:
      return 'w-5/12';
    case 6:
      return 'w-1/2';
    case 7:
      return 'w-7/12';
    case 8:
      return 'w-2/3';
    case 9:
      return 'w-3/4';
    case 10:
      return 'w-5/6';
    case 11:
      return 'w-11/12';
    case 12:
      return 'w-full';
  }
};

interface RowContainerProps {
  className?: string;
}

interface ItemProps {
  className?: string;
  col?: ColSize;
}

const RowContainer = ({ children, className }: React.PropsWithChildren<{}> & RowContainerProps) => {
  return <div className={classNames('flex -mx-3', className)}>{children}</div>;
};

RowContainer.Item = ({ children, className, col = 12 }: React.PropsWithChildren<{}> & ItemProps) => {
  return <div className={classNames('px-3', colSizeToClassName(col), className)}>{children}</div>;
};

export default RowContainer;
