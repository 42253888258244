import { authorizedApi } from '../network/authorized-api';

import { JetShuttleDetail, JetShuttleStatus, Page, VendorJetShuttleDetail } from '@flyblack/common/domains';

export const getShuttles = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<JetShuttleDetail>>(`/v1/jet-shuttles`, { pageNumber, pageSize, ...options });

export const deleteShuttle = (id) => authorizedApi.delete<Page<any>>(`/v1/jet-shuttles/${id}`);

export const cancelShuttle = (id) =>
  authorizedApi.put(`/v1/jet-shuttles/${id}/status`, { status: JetShuttleStatus.Canceled });

export const createJetShuttle = (data: any) => authorizedApi.post<string>(`/v1/jet-shuttles`, data);

export const updateJetShuttle = (data: any, id: number) => authorizedApi.put<string>(`/v1/jet-shuttles/${id}`, data);

export const getJetShuttle = (id: number) => authorizedApi.get<VendorJetShuttleDetail>(`/v1/vendor/jet-shuttles/${id}`);
