import React from 'react';
import classnames from 'classnames';

import Spacer from '@flyblack/common/components/Spacer';
import Icon, { Type } from '@flyblack/common/components/Icon';

export interface Props {
  top: React.ReactNode;
  bottom: React.ReactNode;
  icon?: Type;
  className?: string;
  noSpacer?: boolean;
}

const DoubleContentWithIcon: React.FC<Props> = ({ icon, top, bottom, className, noSpacer }) => (
  <div className={classnames('flex flex-row items-center', className)}>
    {icon && <Icon type={icon} className="mr-5" />}

    <div className="flex flex-col">
      {top}
      {noSpacer || <Spacer xs={1} />}
      {bottom}
    </div>
  </div>
);

export default DoubleContentWithIcon;
