import React from 'react';
import { urls } from '@/constants';
import { useHistory } from 'react-router';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import { Vendor } from '@/domains/vendor';

export interface Props {
  data: Vendor;
}

const VendorTableItem: React.FC<Props> = ({ data }) => {
  const history = useHistory();

  return (
    <Typography
      is={Table.Row}
      onClick={() => history.push(urls.vendors.detail.get(data.id))}
      className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
    >
      <Table.Data className="pr-1 w-[30%]">{data.name}</Table.Data>

      <Table.Data className="pr-1 w-[30%]">
        {(data.users &&
          data.users[0] &&
          (data.users[0].firstName && data.users[0].lastName
            ? `${data.users[0].firstName} ${data.users[0].lastName}`
            : '-')) ||
          '-'}
      </Table.Data>

      <Table.Data className="pr-1 w-[30%]"> {data.users && data.users[0] && data.users[0].email}</Table.Data>
    </Typography>
  );
};

export default VendorTableItem;
