import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { FormatDateOptions, FormattedDate, FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { CharterListItem } from '@/domains/charter';
import { CharterStatus } from '@/domains/charter-status';
import { getCharterLegSummaryDisplayData } from '@flyblack/common/util';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import CharterTableItemMenu from './CharterTableItemMenu';

export interface Props {
  data: CharterListItem;
  onAction: () => any;
}

const charterStatusToBgColorMap: Record<CharterStatus, string> = {
  [CharterStatus.Archived]: 'bg-[#525252]',
  [CharterStatus.Canceled]: 'bg-[#751C1C]',
  [CharterStatus.Confirmed]: 'bg-[#0F5630]',
  [CharterStatus.WaitingApproval]: 'bg-[#6D521D]',
  [CharterStatus.WaitingPayment]: 'bg-[#6D521D]',
  [CharterStatus.Booked]: 'bg-[#0F5630]'
};

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true
};

const CharterTableItem: React.FC<Props> = ({ data, onAction }) => {
  const history = useHistory();

  const tripData = getCharterLegSummaryDisplayData(data.legs, data.type);

  return (
    <Typography
      is={Table.Row}
      onClick={() => history.push(urls.charters.detail.get(data.id))}
      className="justify-between cursor-pointer font-light h-20 px-10 odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
    >
      <Table.Data className="pr-1 w-24">
        {data.type && <FormattedMessage id={translations.domains.charterType[data.type]} />}
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${tripData.sourceAirport.displayCode} ${tripData.sourceAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[15%]">
        <FormattedDate value={tripData.schedule.departsAt} {...formatDateOptions} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${tripData.destinationAirport.displayCode} ${tripData.destinationAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[15%]">
        <FormattedDate value={tripData.schedule.arrivesAt} {...formatDateOptions} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]">
        {tripData.aircraftCategory ? tripData.aircraftCategory.name : 'old test data'}
      </Table.Data>

      <Table.Data className="pr-1 w-24">
        {`${data.price.symbol}${Number(data.price.value).toLocaleString()}`}
      </Table.Data>

      <Table.Data className="pr-1 w-[160px] justify-end">
        <div className={classnames(charterStatusToBgColorMap[data.status], 'px-4 py-1 rounded-sm')}>
          <Typography is="span" type="hummingbird">
            <FormattedMessage id={translations.domains.charterStatus[data.status]} />
          </Typography>
        </div>
      </Table.Data>

      <Table.Data className="w-4 justify-end" stopPropagation>
        <CharterTableItemMenu
          charterId={data.id}
          canDelete={[CharterStatus.WaitingApproval].includes(data.status)}
          onAction={onAction}
        />
      </Table.Data>
    </Typography>
  );
};

export default CharterTableItem;
