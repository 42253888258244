import React from 'react';
import classnames from 'classnames';

import HiddenInput, { Props as HiddenInputProps } from '@flyblack/common/components/Form/Input/HiddenInput';

export interface Props extends HiddenInputProps {
  align?: 'left' | 'right';
  block?: boolean;
  invalid?: boolean;
  renderToggle: (toggled: boolean, disabled: boolean) => React.ReactNode;
  onInputLabelClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  toggleContainerClass?: string;
}

const Toggle: React.FC<Props> = ({
  align,
  block,
  className,
  children,
  invalid,
  renderToggle,
  onInputLabelClick,
  toggleContainerClass = '',
  ...rest
}) => (
  <label
    htmlFor={rest.id}
    className={classnames(
      'relative cursor-pointer select-none disabled:text-gray-400',
      block ? 'block w-full' : 'inline-block',
      className
    )}
    onClick={onInputLabelClick}
  >
    <div className="flex flex-row items-center">
      <span className={classnames(children && (align === 'right' ? 'order-1 pl-4' : 'pr-4'), toggleContainerClass)}>
        <HiddenInput {...rest} />
        {renderToggle(rest.checked, rest.disabled)}
      </span>

      {children && <div className="relative w-full">{children}</div>}
    </div>
  </label>
);

export default Toggle;
