import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import DateTime from '@flyblack/common/components/DateTime';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import CharterAirportSelect from '@flyblack/common/components/CharterAirportSelect';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);
const CharterAirportSelectWithValidation = withValidation(CharterAirportSelect);

interface Props {
  formId: string;
  submitting: boolean;
  form;
  type: 'departure' | 'arrival';
  editMode?: boolean;
  airportSelectDisabled?: boolean;
  dateSelectDisabled?: boolean;
  timeSelectDisabled?: boolean;
  terminalInputDisabled?: boolean;
}

const TravelDetailsSection: React.FC<Props> = ({
  formId,
  submitting,
  form,
  type,
  children,
  airportSelectDisabled = false,
  dateSelectDisabled = false,
  timeSelectDisabled = false,
  terminalInputDisabled = false
}) => {
  const intl = useIntl();

  return (
    <DetailCard className="mb-0 w-full max-w-[528px]">
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage
          id={translations.pages.shuttles.detail.sections[type === 'departure' ? 'departure' : 'destination']}
        />
      </Typography>

      <Spacer xs={4} />

      <Form.Field
        is={CharterAirportSelectWithValidation}
        id={`${formId}-${type === 'departure' ? 'source-airport-id' : 'destination-airport-id'}`}
        name={type === 'departure' ? 'sourceAirportId' : 'destinationAirportId'}
        type="text"
        label={intl.formatMessage({
          id: translations.inputs[type === 'departure' ? 'departureLocationSelect' : 'destinationLocationSelect'].label
        })}
        placeholder={intl.formatMessage({
          id:
            translations.inputs[type === 'departure' ? 'departureLocationSelect' : 'destinationLocationSelect']
              .placeholder
        })}
        readOnly={submitting}
        disabled={airportSelectDisabled}
        leadingIcon={type === 'departure' ? 'departure' : 'destination'}
        editable
      />

      <Spacer xs={4} />

      <div className="flex justify-between items-end">
        <DateTime
          formId={formId}
          type={type}
          title={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureDateTime' : 'arrivalDateTime'].label
          })}
          timePlaceholder={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureDateTime' : 'arrivalDateTime'].placeholder
          })}
          change={form.change}
          submitting={submitting}
          disabledDate={dateSelectDisabled}
          disabledTime={timeSelectDisabled}
        />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-${type === 'departure' ? 'source-airport-terminal' : 'destination-airport-terminal'}`}
          name={type === 'departure' ? 'sourceAirportTerminal' : 'destinationAirportTerminal'}
          type="text"
          label={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureTerminal' : 'destinationTerminal'].label
          })}
          placeholder={intl.formatMessage({
            id: translations.inputs[type === 'departure' ? 'departureTerminal' : 'destinationTerminal'].placeholder
          })}
          readOnly={submitting}
          leadingIcon="terminal"
          className="w-[100px]"
          disabled={terminalInputDisabled}
        />
      </div>

      {children}
    </DetailCard>
  );
};

export default TravelDetailsSection;
