import React from 'react';
import { isEqual, startOfDay } from 'date-fns';

import { getWeekdayLabels, getDays } from '@flyblack/common/util';

import Typography from '@flyblack/common/components/Typography';

import Day, { RenderProps as DayRenderProps } from './Day';

const weekdayLabels = getWeekdayLabels({ weekDayFormat: 'EEEEE' });

export interface Props {
  year: number;
  month: number;
  selectedDate?: Date | Date[];
  onSelectDate: (date: Date) => void;
  enabledDates?: Date[];
  renderDay?: (dayRenderProps: DayRenderProps) => any;
}

const Month = ({ year, month, selectedDate, onSelectDate, enabledDates, renderDay }: Props) => {
  const days = React.useMemo(() => getDays({ year, month }), [year, month]);

  const selectedDates = Array.isArray(selectedDate) ? selectedDate : [selectedDate];

  return (
    <React.Fragment>
      <div className="flex items-stretch">
        {weekdayLabels.map((dayLabel, index) => (
          <Typography
            is="span"
            type="hummingbird"
            key={index}
            className="text-center w-[40px] h-[40px] text-flyblack-darker-gray m-[2px]"
          >
            {dayLabel}
          </Typography>
        ))}
      </div>

      <div className="flex flex-wrap">
        {days.map((day, index) => {
          if (typeof day === 'object') {
            const currentDaySelectedIndex = selectedDates.findIndex((date) =>
              // @ts-ignore
              isEqual(startOfDay(date), startOfDay(day.date))
            );

            return (
              <Day
                key={index}
                date={day.date}
                dayNumber={day.dayNumber}
                disabled={
                  enabledDates
                    ? enabledDates.findIndex((date) => isEqual(startOfDay(date), startOfDay(day.date))) === -1
                    : false
                }
                // @ts-ignore
                isSelected={
                  selectedDates.length &&
                  (currentDaySelectedIndex === 0 || currentDaySelectedIndex === selectedDates.length - 1)
                }
                onSelectDate={onSelectDate}
                // @ts-ignore
                className={
                  selectedDates.length > 1 &&
                  currentDaySelectedIndex !== -1 &&
                  (currentDaySelectedIndex === 0
                    ? 'bg-gradient-to-r from-white to-flyblack-lighter-gray'
                    : currentDaySelectedIndex === selectedDates.length - 1
                    ? 'bg-gradient-to-l from-white to-flyblack-lighter-gray'
                    : 'bg-flyblack-lighter-gray')
                }
              >
                {renderDay}
              </Day>
            );
          }

          return <div className="h-[40px] w-[40px] block m-[2px]" key={`day-${index}`} />;
        })}
      </div>
    </React.Fragment>
  );
};

export default Month;
