import { CharterAirport, Page } from '@flyblack/common/domains';
import { CharterInquiry, CharterLeg, CharterQuoteListItem } from '../../domains/charter';
import { api } from '../network/api';

export const searchCharters = (passengers: number, legs: CharterLeg[]) =>
  api.post<Page<CharterQuoteListItem>>(`/v1/jet-charters/search`, { paxNum: passengers, legs });

export const getCharterAirports = (text: any) =>
  api.get<Page<CharterAirport>>(`/v1/jet-charters/airports/search`, { text });

export const inquireCharter = (data: CharterInquiry) =>
  api.post<Page<CharterQuoteListItem>>(`/v1/jet-charters/inquire`, data);
