import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { Notification } from '@/domains/notification';
import { sendNotification } from '@/services/api/notifications';
import { NOTIFICATION_CONTENT_MAX_LENGTH, NOTIFICATION_TITLE_MAX_LENGTH } from '@/constants';

import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

interface Props extends InstanceProps {
  data?: Notification;
  onSuccess?: () => any;
}

const schema = yup.object().shape({
  title: yup
    .string()
    .max(NOTIFICATION_TITLE_MAX_LENGTH)
    .label(translations.inputs.notificationTitle.label)
    .required(),
  content: yup
    .string()
    .max(NOTIFICATION_CONTENT_MAX_LENGTH)
    .label(translations.inputs.notificationBody.label)
    .required()
});

const TextAreaWithValidation = withValidation((props) => <TextInput is="textarea" {...props} />);
const TextInputWithValidation = withValidation(TextInput);

const PushNotificationDetailModal = ({ data, onSuccess, close }: Props) => {
  const intl = useIntl();

  return (
    <Modal.Card className="w-[420px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.pushNotificationDetailModal.title[data ? 'update' : 'new']} />
      </Modal.Header>

      <Card.Row padded>
        <Form
          schema={schema}
          initialValues={data}
          subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
          onSubmit={({ title, content }) =>
            sendNotification(title, content).then(() => {
              onSuccess && onSuccess();
              close();
            })
          }
          className="flex flex-col w-full"
        >
          {({ submitError, submitting, valid, dirty, form }) => (
            <React.Fragment>
              <Form.Field
                is={TextInputWithValidation}
                id="title"
                name="title"
                type="text"
                label={intl.formatMessage({ id: translations.inputs.notificationTitle.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.notificationTitle.placeholder })}
                disabled={data}
                appearance="light"
              />

              <Spacer xs={4} />

              <Form.Field
                is={TextAreaWithValidation}
                id="content"
                name="content"
                type="text"
                inputClassName="resize-none h-32"
                label={intl.formatMessage({ id: translations.inputs.notificationBody.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.notificationBody.placeholder })}
                disabled={data}
                appearance="light"
              />

              <Spacer xs={2} />

              {!data && (
                <React.Fragment>
                  {!!submitError ? <SubmitError error={submitError} /> : <Spacer xs={5} />}

                  <Modal
                    modal={ConfirmationModal}
                    title={intl.formatMessage({ id: translations.modals.pushNotificationDetailModal.confirmSend })}
                    confirmMessage={intl.formatMessage({ id: translations.inputs.buttons.sendNotification })}
                    cancelMessage={intl.formatMessage({ id: translations.inputs.buttons.cancel })}
                    onConfirm={form.submit}
                  >
                    {({ open: openModal }) => (
                      <Button
                        onClick={openModal}
                        className="font-medium ml-auto w-[150px]"
                        appearance="black"
                        disabled={!valid || !dirty || submitting}
                      >
                        <FormattedMessage id={translations.inputs.buttons.sendNotification} />
                      </Button>
                    )}
                  </Modal>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Form>
      </Card.Row>
    </Modal.Card>
  );
};

export default PushNotificationDetailModal;
