import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import HeaderWrapper from '@flyblack/common/components/HeaderWrapper';

import { SessionContext } from '@/components/Context/Session';

export interface Props {}

const Header: React.FC<Props> = () => {
  const { signOut } = React.useContext(SessionContext);

  const history = useHistory();

  return (
    <HeaderWrapper>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center h-full space-x-8">
          <Typography is={HeaderWrapper.NavItem} to={urls.overview.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.overview} />
          </Typography>

          <Typography is={HeaderWrapper.NavItem} to={urls.membership.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.membership} />
          </Typography>

          <Typography is={HeaderWrapper.NavItem} to={urls.vendors.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.vendors} />
          </Typography>

          <Typography is={HeaderWrapper.NavItem} to={urls.shuttles.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.shuttles} />
          </Typography>

          <Typography is={HeaderWrapper.NavItem} to={urls.charters.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.charters} />
          </Typography>

          <Typography is={HeaderWrapper.NavItem} to={urls.settings.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.settings} />
          </Typography>
        </div>

        <Button
          type="button"
          appearance="white"
          className="ml-auto font-normal"
          onClick={() => signOut().then(() => history.push(urls.home))}
        >
          Log Out
        </Button>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
