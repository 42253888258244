import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import DropdownContent from './DropdownContent';

// @ts-ignore
import style from './Dropdown.sass';

export interface MenuRenderProps {
  close: () => any;
}

export interface RenderProps extends MenuRenderProps {
  isOpen: boolean;
  open: () => any;
}

export interface Props {
  isOpen?: boolean;
  className?: string;
  dropdownClassName?: string;
  overlay?: boolean;
  children: (props: RenderProps) => any;
  renderMenu: (props: MenuRenderProps) => any;
  onOpen?: () => any;
  onClose?: () => any;
}

export interface State {
  isOpen: boolean;
}

export default class Dropdown extends React.PureComponent<Props, State> {
  static Content = DropdownContent;

  static defaultProps = {
    onOpen: () => null,
    onClose: () => null
  };

  state: State = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.closeOnEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape);
  }

  closeOnEscape = (event: any) => event.keyCode === 27 && this.onClose();

  onOpen = () => this.setState({ isOpen: true }, () => this.props.onOpen!());

  onClose = () => this.setState({ isOpen: false }, () => this.props.onClose!());

  render() {
    const { isOpen = this.state.isOpen, overlay, className, dropdownClassName, renderMenu, children } = this.props;

    const transitionProps = {
      timeout: 350,
      in: isOpen,
      mountOnEnter: true,
      unmountOnExit: true,
      appear: true,
      classNames: {
        enterActive: style.open,
        enterDone: style.open
      },
      onExited: this.props.onClose
    };

    return (
      <React.Fragment>
        <CSSTransition {...transitionProps}>
          <div className={classnames(style.overlay, { [style.visibleOverlay]: overlay })} onClick={this.onClose} />
        </CSSTransition>

        <div className={classnames(style.root, { [style.open]: isOpen }, className)}>
          {children({ isOpen, open: this.onOpen, close: this.onClose })}

          <CSSTransition {...transitionProps}>
            <div className={classnames(style.dropdown, dropdownClassName)}>{renderMenu!({ close: this.onClose })}</div>
          </CSSTransition>
        </div>
      </React.Fragment>
    );
  }
}
