import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getHoursFlown } from '@/services/api/reports';

import useLoad from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import LargeDataDetail from '@flyblack/common/components/LargeDataDetail';

import OverviewCard from './OverviewCard';

const TotalHoursFlownCard = () => {
  const intl = useIntl();

  const { value: hoursFlownReport, loading, error } = useLoad({
    load: getHoursFlown
  });

  return (
    <OverviewCard title={intl.formatMessage({ id: translations.pages.overview.totalHoursFlown.title })}>
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : (
        <LargeDataDetail
          title={intl.formatMessage({ id: translations.pages.overview.totalHoursFlown.totalHoursFlown })}
          data={
            error
              ? intl.formatMessage({ id: translations.pages.overview.errorValue })
              : hoursFlownReport.totalHoursFlownAdmin
          }
        />
      )}
    </OverviewCard>
  );
};

export default TotalHoursFlownCard;
