import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CharterContactInfo } from '@/domains/charter';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';

interface Props {
  contactInfo: CharterContactInfo;
}

const RequestedBySection = ({ contactInfo: { firstName, lastName, phoneNumber, email } }: Props) => (
  <DetailCard className="mb-0 w-full max-w-[528px]">
    <Typography is="span" type="halcyon" weight="bold">
      <FormattedMessage id={translations.pages.charters.detail.requestedBy.title} />
    </Typography>

    <Spacer xs={3} />

    <div className="flex flex-col">
      <Typography is="div" type="halcyon" color="lightGray">
        <FormattedMessage
          id={translations.pages.charters.detail.requestedBy.name}
          values={{
            span: (chunks) => (
              <Typography is="span" type="halcyon" color="white">
                {chunks}
              </Typography>
            ),
            name: `${firstName} ${lastName}`
          }}
        />
      </Typography>

      <Typography is="div" type="halcyon" color="lightGray">
        <FormattedMessage
          id={translations.pages.charters.detail.requestedBy.phoneNumber}
          values={{
            span: (chunks) => (
              <Typography is="span" type="halcyon" color="white">
                {chunks}
              </Typography>
            ),
            phoneNumber
          }}
        />
      </Typography>

      <Typography is="div" type="halcyon" color="lightGray">
        <FormattedMessage
          id={translations.pages.charters.detail.requestedBy.email}
          values={{
            span: (chunks) => (
              <Typography is="span" type="halcyon" color="white">
                {chunks}
              </Typography>
            ),
            email
          }}
        />
      </Typography>
    </div>
  </DetailCard>
);

export default RequestedBySection;
