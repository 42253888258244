import React from 'react';
import classnames from 'classnames';

import Toggle, { Props as ToggleProps } from '@flyblack/common/components/Toggle';
import Typography, { Color } from '@flyblack/common/components/Typography';

export type ToggleSwitchAppearance = 'dark' | 'light';
type ToggleSwitchState = 'enabled' | 'disabled';
type ToggleSwitchValue = 'on' | 'off';

const appearanceToLabelColor: Record<ToggleSwitchAppearance, Color> = {
  dark: 'white',
  light: 'black'
};

const appearanceToBackgroundClassNameMap: Record<
  ToggleSwitchAppearance,
  Record<ToggleSwitchState, Record<ToggleSwitchValue, string>>
> = {
  dark: {
    enabled: {
      on: 'border-white bg-white',
      off: 'border-white bg-black'
    },
    disabled: {
      on: 'border-flyblack-white-32 bg-black',
      off: 'border-flyblack-white-32 bg-black'
    }
  },
  light: {
    enabled: {
      on: 'border-black bg-black',
      off: 'border-black bg-white'
    },
    disabled: {
      on: 'border-flyblack-black-32 bg-white',
      off: 'border-flyblack-black-32 bg-white'
    }
  }
};

const appearanceToCircleClassNameMap: Record<
  ToggleSwitchAppearance,
  Record<ToggleSwitchState, Record<ToggleSwitchValue, string>>
> = {
  dark: {
    enabled: {
      on: 'bg-black',
      off: 'bg-white'
    },
    disabled: {
      on: 'bg-flyblack-white-32',
      off: 'bg-flyblack-white-32'
    }
  },
  light: {
    enabled: {
      on: 'bg-white',
      off: 'bg-black'
    },
    disabled: {
      on: 'bg-flyblack-black-32',
      off: 'bg-flyblack-black-32'
    }
  }
};

export interface Props extends Omit<ToggleProps, 'renderToggle'> {
  label?: string;
  appearance?: ToggleSwitchAppearance;
}

const ToggleSwitch: React.FC<Props> = ({ label, appearance = 'dark', ...rest }) => (
  <Toggle
    {...rest}
    onChange={rest.onChange || (() => null)}
    renderToggle={(toggled, disabled) => (
      <div className="inline-flex w-full justify-between items-center">
        {label && (
          <Typography is="span" type="hummingbird" color={appearanceToLabelColor[appearance]}>
            {label}
          </Typography>
        )}

        <div
          className={classnames(
            'inline-block w-[48px] h-[24px] rounded-xl ml-2 transition-colors -p-[1px] border border-solid',
            appearanceToBackgroundClassNameMap[appearance][disabled ? 'disabled' : 'enabled'][toggled ? 'on' : 'off']
          )}
        >
          <div
            className={classnames(
              'inline-block w-[16px] h-[16px] rounded-full my-[3px] mx-[3px] transition',
              { 'translate-x-[24px]': toggled },
              appearanceToCircleClassNameMap[appearance][disabled ? 'disabled' : 'enabled'][toggled ? 'on' : 'off']
            )}
          />
        </div>
      </div>
    )}
  />
);

export default ToggleSwitch;
