import React from 'react';
import classnames from 'classnames';

import Row from '../Row';

export interface Props {
  is?: React.ElementType;
  className?: string;
}

const Header: React.FC<Props> = ({ is: Component = 'thead', className, children, ...rest }) => (
  <Component>
    <Row className={classnames('px-6 py-4 bg-black flex flex-row', className)} {...rest}>
      {children}
    </Row>
  </Component>
);

export default Header;
