import React from 'react';
import classnames from 'classnames';
import Typography from '../Typography';

export interface Props {
  label: React.ReactNode;
  text: React.ReactNode;
  className?: string;
}

const TextWithLabel: React.FC<Props> = ({ label, text, className }) => (
  <div className={classnames('flex flex-col', className)}>
    <Typography is="span" type="hummingbird" color="lightGray">
      {label}
    </Typography>

    <Typography is="span" type="halcyon">
      {text}
    </Typography>
  </div>
);

export default TextWithLabel;
