import React, { DOMAttributes } from 'react';
import classnames from 'classnames';

export interface Props extends DOMAttributes<any> {
  is?: any;
  padded?: boolean;
  className?: string;
}

const Row: React.FC<Props> = ({ is: Component = 'div', padded, className, ...rest }) => (
  <Component
    {...rest}
    className={classnames(
      'relative flex wrap border-b border-solid border-white border-opacity-10 last:border-0',
      { 'p-6': padded },
      className
    )}
  />
);

export default Row;
