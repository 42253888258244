import React from 'react';

import LogoLink from '@flyblack/common/components/LogoLink';
import Container from '@flyblack/common/components/Container';

import NavItem, { Props as NavItemProps } from './NavItem';

export interface Props {
  children?: any;
}

interface StaticComponents {
  NavItem: React.FC<NavItemProps>;
}

const HeaderWrapper: React.FC<Props> & StaticComponents = ({ children }) => {
  return (
    <header className="relative z-10 w-full flex-shrink-0 shadow bg-white text-black">
      <Container className="flex justify-between h-[72px] items-center">
        <div className="mr-20">
          <LogoLink className="h-9 py-[10px] w-auto" />
        </div>

        <div className="flex flex-auto w-auto h-full">{children}</div>
      </Container>
    </header>
  );
};

HeaderWrapper.NavItem = NavItem;

export default HeaderWrapper;
