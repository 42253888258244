import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getAircraftCategories } from '@/services/api/aircraft-type';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import RowContainer from '@flyblack/common/components/RowContainer';
import LoadableSelect from '@flyblack/common/components/LoadableSelect';
import { withSource } from '@flyblack/common/components/hoc/withSource';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import useLoad from '@flyblack/common/hooks/useLoad';

import AircraftTypeSelect from '@/components/AircraftTypeSelect';

const TextInputWithValidation = withValidation(TextInput);
const AircraftTypeSelectWithValidation = withValidation(AircraftTypeSelect);

const AircraftCategorySelectWithValidation = withValidation(
  withSource(LoadableSelect, () => getAircraftCategories().then((reponse) => reponse.categories))
);

interface Props {
  formId: string;
  submitting: boolean;
  values;
  form;
}

const AircraftInfoSection: React.FC<Props> = ({ formId, submitting, values, form }) => {
  const intl = useIntl();

  const { value: categories, loading: categoriesLoading } = useLoad({
    load: () => getAircraftCategories().then((response) => response.categories)
  });

  return (
    !categoriesLoading && (
      <DetailCard>
        <Typography is="span" type="halcyon" weight="bold">
          <FormattedMessage id={translations.pages.shuttles.detail.sections.aircraftInfo} />
        </Typography>

        <Spacer xs={4} />

        <RowContainer>
          <RowContainer.Item col={6}>
            <Form.Field
              is={AircraftCategorySelectWithValidation}
              id={`${formId}-aircraft-category-id`}
              name="aircraftCategoryId"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.aircraftCategorySelect.label })}
              placeholder={intl.formatMessage({
                id: translations.inputs.aircraftCategorySelect.placeholder
              })}
              readOnly={submitting || true}
              leadingIcon="plane"
              onChange={(value) => {
                form.change('aircraftTypeId', null);

                form.change('seats', categories.find((item) => item.id === (value as any)).jetShuttleSeats);
              }}
            />
          </RowContainer.Item>

          <RowContainer.Item col={6}>
            <Form.Field
              key={values.aircraftCategoryId}
              is={AircraftTypeSelectWithValidation}
              id={`${formId}-aircraft-type-id`}
              name="aircraftTypeId"
              type="text"
              categoryId={values.aircraftCategoryId}
              label={intl.formatMessage({ id: translations.inputs.aircraftTypeSelect.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.aircraftTypeSelect.placeholder })}
              readOnly={submitting}
              disabled={values.aircraftCategoryId === null}
            />
          </RowContainer.Item>
        </RowContainer>

        <Spacer xs={4} />

        <RowContainer>
          <RowContainer.Item col={6}>
            <Form.Field
              is={TextInputWithValidation}
              id={`${formId}-tail-number`}
              name="tailNumber"
              type="text"
              label={intl.formatMessage({ id: translations.inputs.tailNumber.label })}
              placeholder={intl.formatMessage({ id: translations.inputs.tailNumber.placeholder })}
              readOnly={submitting}
            />
          </RowContainer.Item>
        </RowContainer>
      </DetailCard>
    )
  );
};

export default AircraftInfoSection;
