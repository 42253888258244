import React from 'react';
import classnames from 'classnames';

export interface Props {
  size: number;
  borderWidth: number;
  fullCircle?: boolean;
  color?: string;
  center?: boolean;
}

const LoadingIndicator: React.FC<Props> = ({ size, borderWidth, color, center, fullCircle }) => {
  return (
    <span
      className={classnames(
        'inline-block border-solid border-t-transparent border-l-transparent border-b-transparent rounded-full align-middle animate-rotate',
        {
          'animate-rotateOnCenter absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': center,
          'border-t-gray-400 border-l-gray-400 border-b-gray-400': fullCircle
        }
      )}
      style={{
        width: size,
        height: size,
        borderWidth,
        borderRightColor: color
      }}
    />
  );
};

export default LoadingIndicator;
