import React from 'react';

import Card from '@flyblack/common/components/Card';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  title: string;
  value: string | number;
  className?: string;
}

const TravellerDetailColumn: React.FC<Props> = ({ title, value, className }) => {
  return (
    <Card.Column padded={false} className={className}>
      <Typography is="div" type="hummingbird" color="lightGray">
        {title}
      </Typography>
      <Typography is="div" type="halcyon" className="pt-2">
        {value}
      </Typography>
    </Card.Column>
  );
};

export default TravellerDetailColumn;
