import flat from 'flat';
import * as yup from 'yup';

import { Language } from '@/constants/language';

import englishMessage from './en.json';

export type LocaleTranslation = typeof englishMessage;

const messages: Record<Language, LocaleTranslation> = {
  [Language.English]: englishMessage
};

const traverse = (obj: any, path = ''): any => {
  if (obj === null) {
    return null;
  }

  const result: any = {};

  if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      result[key] = traverse(obj[key], path ? `${path}.${key}` : key);
    });
  } else {
    return path;
  }

  return result;
};

export const translations: LocaleTranslation = traverse(englishMessage);

yup.setLocale(translations.validation.basic);

export const getTranslationMessages = (language: Language): any => flat(messages[language]);
