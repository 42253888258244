import React from 'react';

import { City } from '@flyblack/common/domains';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import CityTableItemMenu from './CityTableItemMenu';
import CityTableItemStatus from './CityTableItemStatus';

export interface Props {
  data: City;
  onAction: () => any;
}

const CityTableItem: React.FC<Props> = ({ data, onAction }) => {
  return (
    <Typography
      is={Table.Row}
      className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
    >
      <Table.Data className="pr-1 w-[20%]">{data.name}</Table.Data>

      <Table.Data className="pr-1 w-[20%]">{data.state}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{data.countryCode}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{data.userCount}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">
        <CityTableItemStatus cityId={data.id} status={data.status} onStatusChange={onAction} />
      </Table.Data>

      <Table.Data className="w-4 justify-end" stopPropagation>
        <CityTableItemMenu cityId={data.id} onAction={onAction} />
      </Table.Data>
    </Typography>
  );
};

export default CityTableItem;
