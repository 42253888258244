import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { PassengerListItem, Pet } from '@flyblack/common/domains';

import Card from '@flyblack/common/components/Card';
import Typography from '@flyblack/common/components/Typography';
import { InstanceProps } from '@flyblack/common/components/Modal';
import Modal from '@flyblack/common/components/Modal';

import TravellerDetailColumn from './TravellerDetailColumn';

interface Props extends InstanceProps {
  passengers: PassengerListItem[];
  pets: Pet[];
}

const PassengersModal: React.FC<Props> = ({ passengers, pets, close }) => {
  const intl = useIntl();

  return (
    <Modal.Card className="w-[800px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.passengersModal.title.passengers} />
      </Modal.Header>

      <div className="h-[560px] overflow-y-auto">
        {passengers.map((passenger, index) => (
          <Card.Row key={passenger.id} padded>
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.passengerList.number })}
              value={index + 1}
              className="w-16"
            />
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.passengerList.legalName })}
              value={passenger.legalName}
              className="w-48"
            />
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.passengerList.dateOfBirth })}
              value={intl.formatDate(passenger.birthDate, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                timeZone: 'UTC'
              })}
              className="w-36"
            />
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.passengerList.weight })}
              value={
                passenger.weight
                  ? `${passenger.weight.value} ${intl.formatMessage({
                      id: translations.domains.weightUnit[passenger.weight.unit]
                    })}`
                  : '-'
              }
              className="w-28"
            />
          </Card.Row>
        ))}

        {pets.length > 0 && (
          <Card.Row padded>
            <Typography is="span" type="halcyon" weight="bold">
              <FormattedMessage id={translations.modals.passengersModal.title.pets} />
            </Typography>
          </Card.Row>
        )}

        {pets.map((pet, index) => (
          <Card.Row key={pet.id} padded>
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.petList.number })}
              value={index + 1}
              className="w-16"
            />
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.petList.type })}
              value={pet.type}
              className="w-24"
            />
            <TravellerDetailColumn
              title={intl.formatMessage({ id: translations.modals.passengersModal.petList.breed })}
              value={pet.breed}
              className="w-48"
            />
          </Card.Row>
        ))}
      </div>
    </Modal.Card>
  );
};

export default PassengersModal;
