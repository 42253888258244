import React from 'react';
import MaskedInput from 'react-text-mask';

import TextInput, { Props as TextInputProps } from '@flyblack/common/components/Form/Input/TextInput';

const timeMask = (value: string) => {
  const chars = value.split('');
  const hours = [/[0-2]/, chars[0] == '2' ? /[0-3]/ : /[0-9]/];

  const minutes = [/[0-5]/, /[0-9]/];

  return [...hours, ':', ...minutes];
};

export interface Props extends TextInputProps {}

const TimeInput: React.SFC<Props> = (props) => {
  return (
    <TextInput
      is={MaskedInput}
      // @ts-ignore
      mask={timeMask}
      guide={false}
      keepCharPositions
      {...props}
    />
  );
};

export default TimeInput;
