import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';

import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

interface Props {
  formId: string;
  submitting: boolean;
}

const PilotsSection: React.FC<Props> = ({ formId, submitting }) => {
  const intl = useIntl();

  return (
    <DetailCard>
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage id={translations.pages.shuttles.detail.sections.pilots} />
      </Typography>

      <Spacer xs={4} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-first-pilot`}
        name="firstPilot"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.firstPilot.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.firstPilot.placeholder })}
        readOnly={submitting}
        leadingIcon="navigationAccount"
      />

      <Spacer xs={4} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-second-pilot`}
        name="secondPilot"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.secondPilot.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.secondPilot.placeholder })}
        readOnly={submitting}
        leadingIcon="navigationAccount"
      />
    </DetailCard>
  );
};

export default PilotsSection;
