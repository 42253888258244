import { CharterLegListItem } from '@flyblack/client-web/src/domains/charter';

export interface CharterEnquireItem {
  id: number;
  inquire: CharterEnquireDetails;
  quote: CharterEnquireQuote;
  status: CharterEnquireStatus;
}

export interface CharterEnquireDetails {
  quoteId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  seats: number;
}

export interface CharterEnquireQuote {
  aircraftCategoryId: string;
  legs: CharterLegListItem[];
  price: number;
}

export enum CharterEnquireStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}
