import * as yup from 'yup';

import { translations } from '@/locale';

const optionalStringSchema = yup
  .string()
  .nullable()
  .notRequired();

const optionalNumberSchema = yup
  .number()
  .nullable()
  .notRequired();

const requiredStringSchema = yup.string().required();

const sourceAirportSchema = {
  sourceAirportId: yup
    .number()
    .label(translations.inputs.departureLocationSelect.label)
    .test('differentThanDestination', translations.validation.custom.identicalLocations, function(value) {
      const destinationAirportId: number = this.resolve(yup.ref('destinationAirportId'));

      return !destinationAirportId ? true : destinationAirportId !== value;
    })
    .required(),
  sourceAirportTerminal: optionalStringSchema
};

const destinationAirportSchema = {
  destinationAirportId: yup
    .number()
    .label(translations.inputs.destinationLocationSelect.label)
    .test('differentThanSource', translations.validation.custom.identicalLocations, function(value) {
      const sourceAirportId: number = this.resolve(yup.ref('sourceAirportId'));

      return !sourceAirportId ? true : sourceAirportId !== value;
    })
    .required(),
  destinationAirportTerminal: optionalStringSchema
};

const arrivalDateTimeSchema = {
  arrivalDate: yup
    .string()
    .test('minValueArrivalDate', translations.validation.custom.arrivalDate, function() {
      const departureDate: string = this.resolve(yup.ref('departureDate'));
      const arrivalDate: string = this.resolve(yup.ref('arrivalDate'));

      if (!departureDate || !arrivalDate) return true;

      return departureDate.localeCompare(arrivalDate) <= 0;
    })
    .required(),
  arrivalTime: yup
    .string()
    .test('minValueArrivalTime', translations.validation.custom.arrivalTime, function() {
      const departureDate: string = this.resolve(yup.ref('departureDate'));
      const departureTime: string = this.resolve(yup.ref('departureTime'));
      const arrivalDate: string = this.resolve(yup.ref('arrivalDate'));
      const arrivalTime: string = this.resolve(yup.ref('arrivalTime'));

      if (!departureDate || !arrivalDate || !departureTime) return true;

      return departureDate === arrivalDate ? departureTime.localeCompare(arrivalTime) !== 1 : true;
    })
    .required()
};

const editShuttleSchemaObject = {
  ...sourceAirportSchema,
  ...destinationAirportSchema,
  departureDate: requiredStringSchema,
  departureTime: requiredStringSchema,
  ...arrivalDateTimeSchema,
  price: yup
    .number()
    .positive()
    .label(translations.inputs.price.errorLabel)
    .required(),
  tailNumber: optionalStringSchema,
  firstPilot: optionalStringSchema,
  secondPilot: optionalStringSchema,
  eventName: optionalStringSchema.label(translations.inputs.eventName.label),
  aircraftCategoryId: yup
    .number()
    .label(translations.inputs.aircraftCategorySelect.label)
    .required(),
  aircraftTypeId: optionalNumberSchema,
  seats: optionalNumberSchema,
  passengerCount: yup.number().notRequired()
};

const createShuttleSchemaObject = {
  ...editShuttleSchemaObject,
  isRecurring: yup.boolean().required(),
  eventName: yup
    .string()
    .label(translations.inputs.eventName.label)
    .when('isRecurring', {
      is: true,
      then: optionalStringSchema,
      otherwise: requiredStringSchema
    }),
  recurringDays: yup.mixed().when('isRecurring', {
    is: true,
    then: yup.array().min(1),
    otherwise: yup
      .mixed()
      .nullable()
      .notRequired()
  }),
  recurringEndDate: yup.mixed().when('isRecurring', {
    is: true,
    then: yup
      .string()
      .test('minValueRecurringEndDateDate', translations.validation.custom.recurringEndDate, function() {
        const recurringEndDate: string = this.resolve(yup.ref('recurringEndDate'));
        const arrivalDate: string = this.resolve(yup.ref('arrivalDate'));

        if (!recurringEndDate || !arrivalDate) return true;

        return arrivalDate.localeCompare(recurringEndDate) <= 0;
      })
      .required(),
    otherwise: yup
      .mixed()
      .nullable()
      .notRequired()
  })
};

export const editShuttleSchema = yup.object({ ...editShuttleSchemaObject });

export const createShuttleSchema = yup.object({ ...createShuttleSchemaObject });
