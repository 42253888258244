import React from 'react';
import classnames from 'classnames';

export interface Props {
  is?: React.ElementType;
  className?: string;
  hoverable?: boolean;
}

const Row: React.FC<Props> = ({ is: Component = 'tr', className, hoverable, children, ...rest }) => (
  <Component
    className={classnames(
      'rounded-sm flex flex-row text-white  bg-black transition-colors',
      hoverable && 'hover:bg-flyblack-dark-gray hover:transition-colors',
      className
    )}
    {...rest}
  >
    {children}
  </Component>
);

export default Row;
