import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import { getJetShuttle, updateJetShuttle } from '@/services/api/shuttle';

import { getDateFormat, getTime } from '@flyblack/common/util';
import { ScheduleType, JetShuttleStatus, VendorJetShuttleDetail } from '@flyblack/common/domains';

import Form from '@flyblack/common/components/Form';
import Card from '@flyblack/common/components/Card';
import Spacer from '@flyblack/common/components/Spacer';
import DetailRow from '@flyblack/common/components/DetailRow';
import Container from '@flyblack/common/components/Container';
import DetailCard from '@flyblack/common/components/DetailCard';
import Typography from '@flyblack/common/components/Typography';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import { SubmitError } from '@flyblack/common/components/Error';

import useLoad from '@flyblack/common/hooks/useLoad';

import { editShuttleSchema } from './ShuttleSchema';

import PilotsSection from './PilotsSection';
import SeatingSection from './SeatingSection';
import PassengersSection from './PassengersSection';
import AircraftInfoSection from './AircraftInfoSection';
import TravelDetailsSection from './TravelDetailsSection';
import ShuttleDetailButtons from './ShuttleDetailButtons';

const DEFAULT_CURRENCY = 'USD';

export interface Props {}

const TextInputWithValidation = withValidation(TextInput);

interface Params {
  id: string;
}

const loadShuttle = (id?: number) =>
  id ? getJetShuttle(id) : new Promise<VendorJetShuttleDetail>((resolve) => resolve(null));

const EditShuttle: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const history = useHistory();

  const intl = useIntl();

  const { value: data, loading } = useLoad({
    load: loadShuttle,
    id: parseInt(match.params.id)
  });

  const isBooked =
    data &&
    [JetShuttleStatus.PartiallyBooked, JetShuttleStatus.Booked, JetShuttleStatus.PendingBooking].includes(data.status);

  return (
    !loading && (
      <div className="min-h-full py-20">
        <Container max>
          <Form
            id="jet-shuttle"
            schema={editShuttleSchema}
            subscription={{
              errors: true,
              submitting: true,
              submitError: true,
              dirty: true,
              values: true,
              hasValidationErrors: true
            }}
            initialValues={{
              sourceAirportId: data.sourceAirport.id,
              eventName: data.eventName,
              sourceAirportTerminal: data.sourceAirport.terminal,
              departureDate: getDateFormat(data.schedule.departsAt, dateFormat),
              departureTime: getTime(data.schedule.departsAt),
              destinationAirportId: data.destinationAirport.id,
              destinationAirportTerminal: data.destinationAirport.terminal,
              arrivalDate: getDateFormat(data.schedule.arrivesAt, dateFormat),
              arrivalTime: getTime(data.schedule.arrivesAt),
              aircraftCategoryId: data.aircraftType ? data.aircraftType.aircraftCategory.id : data.aircraftCategory.id,
              aircraftTypeId: data.aircraftType ? data.aircraftType.id : null,
              tailNumber: data.tailNumber,
              firstPilot: data.pilots.length ? data.pilots[0] : '',
              secondPilot: data.pilots.length > 1 ? data.pilots[1] : '',
              seats: data.aircraftCategory.jetShuttleSeats,
              price: data.price.value,
              passengerCount: data.trips ? data.trips.reduce((total, trip) => total + trip.purchasedSeats, 0) : 0
            }}
            onSubmit={(values) =>
              updateJetShuttle(
                {
                  aircraftCategoryId: values.aircraftCategoryId,
                  aircraftTypeId: values.aircraftTypeId,
                  destinationAirportId: values.destinationAirportId,
                  destinationAirportTerminal: values.destinationAirportTerminal,
                  eventName: values.eventName,
                  pilots: [values.firstPilot || '', values.secondPilot || ''],
                  price: { currency: DEFAULT_CURRENCY, value: values.price },
                  schedule: {
                    type: ScheduleType.Fixed,
                    departsAt: `${values.departureDate}T${values.departureTime}:00`,
                    arrivesAt: `${values.arrivalDate}T${values.arrivalTime}:00`
                  },
                  sourceAirportId: values.sourceAirportId,
                  sourceAirportTerminal: values.sourceAirportTerminal,
                  tailNumber: values.tailNumber
                },
                data.id
              ).then(() => history.push(urls.shuttles.base))
            }
          >
            {({ submitError, submitting, values, form, errors, dirty, hasValidationErrors }, formId) => (
              <React.Fragment>
                <div className="flex flex-row justify-between items-center">
                  <Typography is="span" type="flamingo">
                    <FormattedMessage id={translations.pages.shuttles.detail.title.update} values={{ id: data.id }} />
                  </Typography>

                  <ShuttleDetailButtons
                    formId={formId}
                    shuttleId={data ? data.id : null}
                    submitting={submitting}
                    disabledSubmit={!dirty || hasValidationErrors}
                    showDelete
                    allowDelete={!isBooked}
                  />
                </div>

                {submitError && (
                  <div className="flex flex-col items-end">
                    <Spacer xs={6} />
                    <SubmitError error={submitError} />
                  </div>
                )}

                <Spacer xs={6} />

                <DetailRow>
                  <Card bg="dark" className="flex justify-between w-full mb-8">
                    <TravelDetailsSection
                      formId={formId}
                      submitting={submitting}
                      form={form}
                      isBooked={isBooked}
                      type="departure"
                      editMode
                    >
                      {data.eventName && (
                        <React.Fragment>
                          <Spacer xs={6} />

                          <Form.Field
                            is={TextInputWithValidation}
                            id={`${formId}-event-name`}
                            name="eventName"
                            type="text"
                            label={intl.formatMessage({ id: translations.inputs.eventName.label })}
                            placeholder={intl.formatMessage({ id: translations.inputs.eventName.placeholder })}
                            readOnly={submitting}
                          />
                        </React.Fragment>
                      )}
                    </TravelDetailsSection>

                    <TravelDetailsSection
                      formId={formId}
                      submitting={submitting}
                      form={form}
                      isBooked={isBooked}
                      type="arrival"
                      editMode
                    />
                  </Card>
                </DetailRow>

                <DetailRow>
                  <AircraftInfoSection formId={formId} form={form} submitting={submitting} values={values} />

                  <PilotsSection formId={formId} submitting={submitting} />
                </DetailRow>

                <DetailRow>
                  <SeatingSection isBooked={isBooked} formId={formId} submitting={submitting} />

                  {values.passengerCount ? (
                    <PassengersSection formId={formId} trips={data.trips} />
                  ) : (
                    <DetailCard transparent />
                  )}
                </DetailRow>

                {submitError && (
                  <div className="flex flex-col items-end">
                    <SubmitError error={submitError} />
                    <Spacer xs={6} />
                  </div>
                )}

                <ShuttleDetailButtons
                  formId={formId}
                  shuttleId={data ? data.id : null}
                  submitting={submitting}
                  disabledSubmit={!dirty || hasValidationErrors}
                />
              </React.Fragment>
            )}
          </Form>
        </Container>
      </div>
    )
  );
};

export default EditShuttle;
