export enum CodeType {
  PromoCode = 'PROMO_CODE',
  RefferalCode = 'REFERRAL_CODE',
  Credit = 'CREDIT'
}

export enum FlightCodeType {
  PromoCode = CodeType.PromoCode,
  Credit = CodeType.Credit
}

export enum MembershipCodeType {
  PromoCode = CodeType.PromoCode,
  RefferalCode = CodeType.RefferalCode
}
