import React from 'react';

import Link from '@flyblack/common/components/Link';

import logoImg from '@flyblack/common/assets/logo.svg';

export interface Props {
  className?: string;
}

const LogoLink: React.FC<Props> = ({ className }) => {
  return (
    <Link to={'/'}>
      <img className={className} src={logoImg} alt="FlyBlack" />
    </Link>
  );
};

export default LogoLink;
