import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {}

const RegisteredTableHeader: React.FC<Props> = () => {
  const text = translations.pages.membership.table.header;

  return (
    <Typography
      is={Table.Header}
      type="hummingbird"
      className="uppercase text-flyblack-light-gray tracking-[1px] justify-between font-bold"
    >
      <Table.Data className="w-[30%]" header>
        <FormattedMessage id={text.name} />
      </Table.Data>

      <Table.Data className="w-[60%]" header>
        <FormattedMessage id={text.email} />
      </Table.Data>

      <Table.Data className="w-[10%]" header>
        <FormattedMessage id={text.registerDate} />
      </Table.Data>
    </Typography>
  );
};

export default RegisteredTableHeader;
