import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

interface Props {
  formId: string;
  submitting: boolean;
  isBooked?: boolean;
}

const CharterPriceSection: React.FC<Props> = ({ formId, submitting, isBooked }) => {
  const intl = useIntl();

  return (
    <DetailCard className="mb-0 w-full max-w-[528px]">
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage id={translations.pages.charters.detail.price} />
      </Typography>

      <Spacer xs={4} />

      <Form.Field
        is={TextInputWithValidation}
        id={`${formId}-price`}
        name="price"
        type="text"
        label={intl.formatMessage({ id: translations.inputs.charterPrice.label })}
        placeholder={intl.formatMessage({ id: translations.inputs.charterPrice.placeholder })}
        readOnly={submitting}
        leadingIcon="dollar"
        disabled={isBooked}
      />
    </DetailCard>
  );
};

export default CharterPriceSection;
