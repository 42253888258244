import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getUsers } from '@/services/api/user';
import { getVendors } from '@/services/api/vendors';

import Icon from '@flyblack/common/components/Icon';
import useLoad from '@flyblack/common/hooks/useLoad';
import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import usePagination from '@flyblack/common/hooks/usePagination';
import PageSelector from '@flyblack/common/components/PageSelector';

import NewVendorModal from './NewVendorModal';
import VendorTableItem from './VendorTableItem';
import VendorTableHeader from './VendorTableHeader';

export interface Props {}

const Vendors: React.FC<Props> = () => {
  const intl = useIntl();

  const { value: vendors, loading, reload: reloadVendors } = useLoad({ load: () => getVendors() });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex h-14 items-center justify-end">
          <Modal modal={NewVendorModal} onSubmit={reloadVendors}>
            {({ open }) => (
              <Button appearance="white" onClick={open}>
                <Icon type="add" className="pr-2" />

                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.vendors.addVendor} />
                </Typography>
              </Button>
            )}
          </Modal>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible={true} center className="w-full h-[400px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <VendorTableHeader />

            <Table.Body>{!loading && vendors.map((item) => <VendorTableItem key={item.id} data={item} />)}</Table.Body>
          </Table>
        )}

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default Vendors;
