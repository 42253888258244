import React from 'react';

import { getAircraftTypes } from '@/services/api/aircraft-type';

import LoadableSelect, { Props as LoadableSelectProps } from '@flyblack/common/components/LoadableSelect';

interface Props extends Omit<LoadableSelectProps, 'source'> {
  categoryId: number;
}

const AircraftTypeSelect = ({ categoryId, ...props }: Props) => {
  return (
    <LoadableSelect
      source={() =>
        getAircraftTypes(categoryId).then((response) =>
          response.content.map((item) => ({
            id: item.id,
            name: item.name
          }))
        )
      }
      {...props}
    />
  );
};

export default AircraftTypeSelect;
