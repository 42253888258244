import React from 'react';
import classnames from 'classnames';

import Form from '@flyblack/common/components/Form';
import TimeInput from '@flyblack/common/components/Form/Input/TimeInput';
import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';

interface Props {
  change: any;
  submitting: any;
  formId: string;
  type: string;
  title: string;
  timePlaceholder?: string;
  className?: string;
  disabledDate?: boolean;
  disabledTime?: boolean;
}

const DateTime: React.FC<Props> = ({
  title,
  submitting,
  change,
  type,
  formId,
  timePlaceholder,
  className,
  disabledDate,
  disabledTime
}) => {
  return (
    <div className={classnames('flex items-end', className)}>
      <Form.Field
        is={DatePickerInput}
        change={change}
        id={`${formId}-${type}-date`}
        name={`${type}Date`}
        type="text"
        readOnly={submitting}
        label={title}
        className="mr-4"
        disabled={disabledDate}
      />

      <Form.Field
        is={TimeInput}
        id={`${formId}-${type}-time`}
        name={`${type}Time`}
        type="text"
        placeholder={timePlaceholder}
        readOnly={submitting}
        className="max-w-[120px]"
        leadingIcon="clock"
        disabled={disabledTime}
      />
    </div>
  );
};
export default DateTime;
