import React from 'react';

import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { createCity } from '@/services/api/city';

import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import { SubmitError } from '@flyblack/common/components/Error';
import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';

import LocationSelect from '@/components/LocationSelect';

const LocationSelectWithValidation = withValidation(LocationSelect);

const schema = yup.object({
  location: yup
    .object({
      placeId: yup.string().required(),
      country: yup.string().required()
    })
    .label(translations.inputs.city.label)
    .default(null)
    .required()
});

interface Props extends InstanceProps {
  onSuccess?: () => any;
}

const AddCityModal = ({ close, onSuccess }: Props) => {
  const intl = useIntl();

  const handleSubmit = ({ location }) =>
    createCity(location.placeId).then(() => {
      onSuccess && onSuccess();
      close();
    });

  return (
    <Modal.Card className="w-[384px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.addCityModal.title} />
      </Modal.Header>

      <Form
        schema={schema}
        subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
        onSubmit={handleSubmit}
      >
        {({ submitError, submitting, valid, dirty }) => (
          <React.Fragment>
            <Card.Row padded className="flex flex-col">
              <Form.Field
                is={LocationSelectWithValidation}
                id="location"
                name="location"
                type="text"
                leadingIcon="location"
                appearance="light"
                label={intl.formatMessage({ id: translations.inputs.city.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.city.placeholder })}
                readOnly={submitting}
                editable
              />

              {!!submitError ? <SubmitError error={submitError} className="h-10" /> : <Spacer xs={5} />}
            </Card.Row>

            <Card.Row padded>
              <Button
                type="submit"
                className="font-medium ml-auto w-[170px]"
                appearance="black"
                disabled={!valid || !dirty || submitting}
              >
                <FormattedMessage id={translations.inputs.buttons.save} />
              </Button>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    </Modal.Card>
  );
};

export default AddCityModal;
