import { CharterDisplayData, CharterLegSummary, CharterType, ScheduleType } from '@flyblack/common/domains';

export const getCharterLegSummaryDisplayData = (
  jetCharterLegs: CharterLegSummary[],
  jetCharterType: CharterType
): CharterDisplayData => {
  const firstCharterTrip = jetCharterLegs[0];
  const lastCharterTrip = jetCharterLegs[jetCharterLegs.length - 1];

  return {
    aircraftCategory: firstCharterTrip.aircraftCategory,
    aircraftType: firstCharterTrip.aircraftType,
    sourceAirport: firstCharterTrip.sourceAirport,
    destinationAirport:
      jetCharterType === CharterType.RoundTrip
        ? firstCharterTrip.destinationAirport
        : lastCharterTrip.destinationAirport,
    schedule: {
      type: ScheduleType.Fixed,
      departsAt: firstCharterTrip.schedule.departsAt,
      arrivesAt: lastCharterTrip.schedule.arrivesAt
    }
  };
};
