import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { HttpError } from '@/services/network/http';
import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';

// @ts-ignore
import style from './Error.sass';

interface Props {
  error: HttpError<any> | undefined | null;
}

const Error: React.FC<Props & WrappedComponentProps> = ({ intl, error }) => {
  if (!error) return null;

  const hasError = error.isAxiosError && error.response && error.response.data && error.response.data.error;

  let errorMessage: string;

  if (hasError) {
    const errorCode = error.response.data.error;
    errorMessage = translations.errors.api[errorCode]
      ? intl.formatMessage({ id: translations.errors.api[errorCode] })
      : null;
  } else {
    errorMessage = error.message;
  }

  return (
    <Typography className={style.root} is="div" type="hummingbird" color="red">
      {errorMessage}
    </Typography>
  );
};

export const AuthError = injectIntl(Error);
