import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import Link from '@flyblack/common/components/Link';

export interface Props {
  to: string;
  className?: string;
  light?: boolean;
}

const NavItem: React.FC<Props> = ({ to, children, light, className }) => {
  const location = useLocation();

  return (
    <Link
      to={to}
      className={classnames(
        className,
        'relative text-sm py-2 transition-colors group',
        light ? 'hover:text-white' : 'text-black',
        location.pathname.indexOf(to) === 0 ? (light ? 'text-white' : 'text-black') : 'text-flyblack-darker-gray'
      )}
    >
      <span
        className={classnames(
          light ? 'bg-white' : 'bg-black',
          'absolute bottom-0 left-0 w-6 h-[1px]  transform transition-all duration-300 group-hover:scale-x-100 group-hover:bg-opacity-80',
          location.pathname.indexOf(to) === 0 ? 'scale-x-100' : 'scale-x-0'
        )}
      />
      {children}
    </Link>
  );
};

export default NavItem;
