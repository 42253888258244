import React, { PropsWithChildren } from 'react';

import DetailCard from '@flyblack/common/components/DetailCard';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  title?: string;
  className?: string;
  style?: React.CSSProperties;
}

const OverviewCard = ({ title, className, style, children }: PropsWithChildren<Props>) => {
  return (
    <DetailCard className={className} style={style}>
      <Typography is="div" type="halcyon" className="mb-6 font-semibold">
        {title}
      </Typography>

      {children}
    </DetailCard>
  );
};

export default OverviewCard;
