import React from 'react';

import { ShuttleBooking } from '@flyblack/common/domains';

import Card from '@flyblack/common/components/Card';

import BookingRowItemTableItem from './BookingRowItemTableItem';
import BookingRowItemTableHeader from './BookingRowItemTableHeader';

interface Props {
  data: ShuttleBooking;
}

const BookingRowItem: React.FC<Props> = ({ data }) => {
  return (
    <Card.Row padded className="flex flex-col w-full">
      <BookingRowItemTableHeader />

      {data.passengers.map((passenger, index) => (
        <BookingRowItemTableItem
          key={passenger.id}
          bookingData={index === 0 && { id: data.id, seats: data.purchasedSeats }}
          passenger={passenger}
        />
      ))}
    </Card.Row>
  );
};

export default BookingRowItem;
