import React, { PureComponent } from 'react';

import { crash } from '@flyblack/common/services/crash';

import Button from '@flyblack/common/components/Button';

export interface Props {}

export interface State {
  error: boolean;
}

export default class ErrorBoundary extends PureComponent<Props, State> {
  state = {
    error: false
  };

  componentDidCatch(error: Error) {
    this.setState({ error: true });
    return crash.report(error);
  }

  reset = () => this.setState({ error: false });

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    return (
      <div className="h-full max-w-[300px] whitespace-nowrap text-center my-0 mx-auto before:inline-block before:align-middle before:w-0 before:h-full">
        <div className="inline-block align-middle relative whitespace-normal">
          <div className="pb-5">
            We&apos;re sorry but something has gone wrong. Our team has been notified about it.
          </div>

          <Button onClick={this.reset} appearance="transparent">
            Try again
          </Button>
        </div>
      </div>
    );
  }
}
