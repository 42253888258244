import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classnames from 'classnames';

export interface Props extends NavLinkProps {
  className?: string;
  underlined?: boolean;
  external?: boolean;
  to: string;
}

const Link: React.FC<Props> = ({ className, underlined, external, children, to, ...rest }) => (
  <React.Fragment>
    {!!external ? (
      <a
        className={classnames('cursor-pointer', underlined ? 'underline' : 'no-underline', className)}
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...rest}
      >
        {children}
      </a>
    ) : (
      <NavLink
        className={classnames('cursor-pointer', underlined ? 'underline' : 'no-underline', className)}
        to={to}
        {...rest}
      >
        {children}
      </NavLink>
    )}
  </React.Fragment>
);

export default Link;
