import React from 'react';
import {
  useCombobox,
  UseComboboxProps,
  UseComboboxState,
  UseComboboxStateChangeOptions,
  GetItemPropsOptions
} from 'downshift';
import classnames from 'classnames';
import { compareTwoStrings } from 'string-similarity';
import isEqual from 'lodash/isEqual';

import SelectItem from './SelectItem';

import TextInput from '@flyblack/common/components/Form/Input/TextInput';

export interface Item<T> {
  value: T;
  name: string;
  disabled?: boolean;
}

export interface RenderItemsProps<I> {
  items: I[];
  inputValue?: string;
  children: React.ReactNode;
}

export interface RenderItemProps<I> {
  props: any;
  item: I;
}

export interface Props<T, I = Item<T>> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: T | T[] | null;
  items: I[];
  searchable?: boolean;
  editable?: boolean;
  multiple?: boolean;
  chevron?: boolean;
  invalid?: boolean;
  getKey?(item: I): string;
  getValue?(item: I): T;
  getInputValue?(isOpen: boolean, inputValue: string, displayName: string): string;
  getSelectedDisplayName?(props: { inputValue: string; selectedItems: I[] }): string;
  renderItem?(props: RenderItemProps<I>): React.ReactNode;
  renderItems?(props: RenderItemsProps<I>): React.ReactNode;
  inputClassName?: string;
  contentClassName?: string;
  dropdownClassName?: string;
  itemsClassName?: string;
  onInputValueChange?(inputValue: string): any;
  onChange(value: T[] | T | null): any;
  invert?: boolean;
}

function Select<T, I extends Item<any>>(props: React.PropsWithChildren<Props<T, I>>) {
  const {
    chevron = true,
    getKey = (item) => (item ? item.value : null),
    getValue = (item) => (item ? item.value : null),
    getInputValue = (isOpen, inputValue, displayName) => (isOpen ? inputValue || '' : displayName),
    getSelectedDisplayName = ({ selectedItems }) => selectedItems.map((item) => item.name).join(', ') || '',
    renderItem = ({ props, item }) => <SelectItem {...props}>{item.name}</SelectItem>,
    renderItems = ({ children }) => children,
    ...rest
  } = props;

  const selectedValues = React.useMemo(() => {
    if (props.value == null) return [];
    if (Array.isArray(props.value)) return props.value;

    return [props.value];
  }, [props.value]);

  const selectedItems = React.useMemo(
    () =>
      selectedValues.map((value) => props.items.find((item) => isEqual(getValue(item), value))!).filter((item) => item),
    [selectedValues, props.items, getValue]
  );

  const stateReducer = React.useCallback(
    (state: UseComboboxState<I>, { type, changes }: UseComboboxStateChangeOptions<I>): Partial<UseComboboxState<I>> => {
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionOpenMenu: {
          if (props.multiple) return changes;

          return {
            ...changes,
            highlightedIndex:
              selectedValues.length > 0
                ? props.items.findIndex((item) => isEqual(getValue(item), selectedValues[0]))!
                : -1
          };
        }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: !!props.multiple,
            highlightedIndex: state.highlightedIndex,
            inputValue: '',
            selectedItem: changes.selectedItem
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: ''
          };
        default:
          return changes;
      }
    },
    [selectedValues, props.multiple, props.items]
  );

  const filter = React.useCallback(
    (items: I[], input: string) => {
      const normalizeName = (value: string) => value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const normalizedInput = input && input.length > 0 ? normalizeName(input) : null;

      if (!normalizedInput || !props.searchable) return items.map((item, index) => ({ item, index }));

      const normalizeIndex = (index: number) => (index < 0 ? Infinity : index);

      return items
        .map((item, originalIndex) => {
          const normalizedName = normalizeName(item.name);
          const similarity = compareTwoStrings(normalizedInput, normalizedName);

          return { item, normalizedName, similarity, index: normalizedName.indexOf(normalizedInput), originalIndex };
        })
        .filter((item) => item.index >= 0 || item.similarity > 0.8)
        .sort((one, two) => {
          const indexDifference = normalizeIndex(one.index) - normalizeIndex(two.index);

          // prioritize exact searches first
          if (indexDifference !== 0) return indexDifference;

          return two.similarity - one.similarity;
        })
        .map(({ item, originalIndex }) => ({ item, index: originalIndex }));
    },
    [props.searchable]
  );

  const removeValue = React.useCallback(
    (selectedValue: T) => props.onChange(selectedValues.filter((value) => value !== selectedValue)),
    [selectedValues, props.onChange]
  );

  const addValue = React.useCallback((selectedValue: T) => props.onChange([...selectedValues, selectedValue]), [
    selectedValues,
    props.onChange
  ]);

  const updateValues = React.useCallback((selectedValues: T | T[] | null) => props.onChange(selectedValues), [
    selectedValues,
    props.onChange
  ]);

  const renderAndFilterItems = React.useCallback(
    (
      inputValue: string,
      items: I[],
      selectedItems: I[],
      highlightedIndex: number | null,
      getItemProps: (options: GetItemPropsOptions<I>) => any
    ) => {
      const filteredItems = filter(items, inputValue || '');

      const children = filteredItems.map(({ item, index }) =>
        renderItem({
          props: {
            key: getKey(item),
            active: highlightedIndex === index,
            isSelected: !!item && selectedItems.some((selectedItem) => isEqual(getValue(selectedItem), getValue(item))),
            disabled: item.disabled,
            ...getItemProps({ item, index })
          },
          item
        })
      );

      return renderItems({ items: filteredItems.map(({ item }) => item), inputValue, children });
    },
    [filter, renderItem, getKey, getValue, renderItems]
  );

  const onSelectedItemChange: UseComboboxProps<I>['onSelectedItemChange'] = React.useCallback(
    ({ selectedItem }) => {
      if (!selectedItem) return;

      const selectedValue = getValue(selectedItem);

      if (!props.multiple) return updateValues(selectedValue);

      if (selectedValues.some((value) => isEqual(value, selectedValue))) {
        return removeValue(selectedValue);
      }

      return addValue(selectedValue);
    },
    [getValue, updateValues, selectedValues, removeValue, addValue]
  );

  const onInputValueChange: UseComboboxProps<I>['onInputValueChange'] = React.useCallback(
    ({ inputValue }) => props.onInputValueChange && props.onInputValueChange(inputValue),
    [props.onInputValueChange]
  );

  const input = React.useRef<HTMLInputElement>();

  const onIsOpenChange: UseComboboxProps<I>['onIsOpenChange'] = React.useCallback(
    ({ isOpen }) => !isOpen && input.current && input.current.blur(),
    []
  );

  const {
    isOpen,
    inputValue,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    openMenu,
    closeMenu
  } = useCombobox({
    id: props.id,
    inputId: props.id,
    itemToString: (item) => (item ? item.name : ''),
    items: props.items,
    stateReducer,
    circularNavigation: false,
    onIsOpenChange,
    onInputValueChange,
    onSelectedItemChange,
    selectedItem: null
  });

  const value = React.useMemo(
    () =>
      getInputValue(
        isOpen,
        inputValue,
        getSelectedDisplayName({
          selectedItems,
          inputValue: inputValue || ''
        })
      ),
    [getInputValue, isOpen, inputValue, getSelectedDisplayName, selectedItems]
  );

  const onFocus = React.useCallback(() => !isOpen && openMenu(), [isOpen, openMenu]);

  return (
    <div {...getComboboxProps({ className: classnames('relative', props.className) })}>
      <TextInput
        {...rest}
        invalid={props.invalid}
        {...getInputProps({
          placeholder: props.placeholder,
          onFocus,
          value,
          readOnly: props.readOnly || !(props.editable || props.searchable),
          disabled: props.disabled || props.readOnly,
          className: classnames(props.inputClassName),
          ref: input as any
        })}
        inputClassName="mr-3 truncate"
        //@ts-ignore
        icon={chevron ? '' : isOpen ? 'chevronUp' : 'chevronDown'}
        invert={props.invert}
      />

      <div
        {...getMenuProps(
          {
            className: classnames(
              'max-h-60 absolute z-10 left-0 rounded-sm top-[37px] bg-white min-w-[300px] md:min-w-[448px] overflow-y-auto',
              isOpen && 'shadow',
              props.dropdownClassName
            )
          },
          { suppressRefError: true }
        )}
      >
        {isOpen && (
          <React.Fragment>
            <ul className={classnames('max-h-60 w-full', props.itemsClassName)}>
              {renderAndFilterItems(inputValue || '', props.items, selectedItems, highlightedIndex, getItemProps)}
            </ul>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Select;
