import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

const buttons = [
  {
    route: urls.settings.pushNotifications.base,
    translationId: translations.pages.settings.navigation.pushNotifications
  },
  {
    route: urls.settings.promoCodes.base,
    translationId: translations.pages.settings.navigation.promoCodes
  },
  {
    route: urls.settings.cities.base,
    translationId: translations.pages.settings.navigation.cities
  },
  {
    route: urls.settings.airports.base,
    translationId: translations.pages.settings.navigation.airports
  }
];

const SettingsNavigation = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-md space-x-1">
      {buttons.map(({ route, translationId }) => (
        <Button
          key={route}
          onClick={() => history.push(route)}
          type="button"
          appearance={location.pathname === route ? 'white' : 'transparent'}
          className="w-[160px]"
        >
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translationId} />
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default SettingsNavigation;
