import React from 'react';
import classnames from 'classnames';

import Card from '@flyblack/common/components/Card';

export interface Props {
  className?: string;
}

const ModalCard = ({ className, children }: React.PropsWithChildren<Props>) => {
  return (
    <Card bg="white" className={classnames('relative overflow-visible block text-black', className)}>
      {children}
    </Card>
  );
};

export default ModalCard;
