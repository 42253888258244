import { CharterDetail, CharterListItem } from '@/domains/charter';
import { CharterAirport, Page } from '@flyblack/common/domains';
import { authorizedApi } from '../network/authorized-api';

export const getCharters = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<CharterListItem>>(`/v1/jet-charters`, { pageNumber, pageSize, ...options });

export const getCharter = (id: number) => authorizedApi.get<CharterDetail>(`/v1/jet-charters/${id}`);

export const getCharterAirports = (text) =>
  authorizedApi.get<Page<CharterAirport>>(`/v1/jet-charters/airports/search`, { text });

const getAirportByICAO = (ICAO: string) =>
  getCharterAirports(ICAO).then(
    (response) => response.content.filter((item) => item.icao === ICAO)[0]
    //ICAO codes are unique so there will always be one result
  );

export const updateCharter = (data: any, id: number) => {
  const { legs, ...rest } = data;
  const airportsDataPromises: Promise<{
    city: string;
    country: string;
    iata: string;
    icao: string;
    name: string;
    region: string;
  }>[] = [];

  //Create a set of airports to reduce the number of API calls in case of linked flights
  const airportList = new Set();

  legs.map((leg) => {
    airportList.add(leg.source);
    airportList.add(leg.destination);
  });

  Array.from(airportList).map((item) => airportsDataPromises.push(getAirportByICAO(item as string)));

  return Promise.all(airportsDataPromises).then((airportsData) => {
    return authorizedApi.put<string>(`/v1/jet-charters/${id}`, {
      legs: legs.map((leg) => ({
        ...leg,
        source: airportsData.find((item) => item.icao === leg.source),
        destination: airportsData.find((item) => item.icao === leg.destination)
      })),
      ...rest
    });
  });
};

export const deleteCharter = (id) => authorizedApi.delete<Page<any>>(`/v1/jet-charters/${id}`);
