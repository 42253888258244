import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

const CityTableHeader = () => {
  const text = translations.pages.cities.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] justify-between font-bold">
      <Table.Data className="pr-1 w-[20%]" header>
        <FormattedMessage id={text.name} />
      </Table.Data>

      <Table.Data className="pr-1 w-[20%]" header>
        <FormattedMessage id={text.state} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.countryCode} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.userCount} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.status} />
      </Table.Data>

      <Table.Data className="w-4" header />
    </Typography>
  );
};

export default CityTableHeader;
