import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { ShuttleBooking } from '@flyblack/common/domains';

import Modal, { InstanceProps } from '@flyblack/common/components/Modal';

import BookingRowItem from './BookingRowItem';

interface Props extends InstanceProps {
  bookings: ShuttleBooking[];
}

const ShuttlePassengersModal: React.FC<Props> = ({ bookings, close }) => {
  return (
    <Modal.Card className="w-[800px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.passengersModal.title.passengers} />
      </Modal.Header>

      <div className="h-[560px] overflow-y-auto">
        {bookings.map((booking) => (
          <BookingRowItem key={booking.id} data={booking} />
        ))}
      </div>
    </Modal.Card>
  );
};

export default ShuttlePassengersModal;
