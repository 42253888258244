import React from 'react';
import classnames from 'classnames';

// @ts-ignore
import icon from './style.css';

export type Type = keyof typeof icon;

export type Appearance = 'orange' | 'blue' | 'red' | 'gray' | 'lightGray' | 'white' | 'green' | 'black';

const appearanceToClassNameMap: Record<Appearance, string> = {
  orange: 'text-yellow-600',
  blue: 'text-blue-500',
  red: 'text-red-600',
  gray: 'text-flyblack-gray',
  lightGray: 'text-flyblack-light-gray',
  white: 'text-white',
  green: 'text-green-600',
  black: 'text-black'
};

export interface Props {
  className?: string;
  sizeClassName?: string;
  type: Type;
  appearance?: Appearance;
}

export default class Icon extends React.PureComponent<Props> {
  render() {
    const { appearance, className, sizeClassName, type, ...rest } = this.props;

    return (
      <i
        className={classnames(
          'align-middle',
          // @ts-ignore
          `${type}`,
          icon[type],
          // @ts-ignore
          appearanceToClassNameMap[appearance],
          className,
          sizeClassName || 'text-[16px] leading-[16px] md:text-[24px] md:leading-[24px]'
        )}
        {...rest}
      />
    );
  }
}
