import React from 'react';
import classnames from 'classnames';

export interface Props {
  className?: string;
}

const DropdownContent: React.FC<Props> = ({ className, ...rest }) => (
  <div {...rest} className={classnames('bg-flyblack-gray', className)} />
);

export default DropdownContent;
