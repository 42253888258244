import React from 'react';

import Typography from '@flyblack/common/components/Typography';

const TruncatedTableDataContent = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Typography
      is="span"
      type="halcyon"
      className="truncate hover:overflow-visible hover:bg-flyblack-dark-gray hover:z-10 hover:pr-4"
    >
      {children}
    </Typography>
  );
};

export default TruncatedTableDataContent;
