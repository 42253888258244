import React from 'react';
import { useIntl } from 'react-intl';

import { Weekdays } from '@flyblack/common/domains';

import { translations } from '@/locale';

import Select, { Props as SelectProps } from '@flyblack/common/components/Select';

export interface Props extends Omit<SelectProps<Weekdays>, 'items'> {}

const WeekdaySelect: React.FC<Props> = ({ ...props }) => {
  const intl = useIntl();

  const items = Object.values(Weekdays).map((weekday) => ({
    value: weekday,
    name: intl.formatMessage({ id: translations.domains.weekdays[weekday] })
  }));

  return <Select {...props} items={items} />;
};

export default WeekdaySelect;
