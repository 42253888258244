import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import { CharterStatus } from '@/domains/charter-status';

import Select, { Props as SelectProps } from '@flyblack/common/components/Select';

export interface Props extends Omit<SelectProps<CharterStatus>, 'items'> {
  includeBookedStatus?: boolean;
}

const CharterStatusSelect: React.FC<Props> = ({ includeBookedStatus = false, ...props }) => {
  const intl = useIntl();

  let statuses = [CharterStatus.WaitingApproval, CharterStatus.WaitingPayment, CharterStatus.Canceled];
  if (includeBookedStatus) {
    statuses.push(CharterStatus.Booked);
  }

  const items = statuses.map((status) => ({
    value: status,
    name: intl.formatMessage({ id: translations.domains.charterStatus[status] })
  }));

  return <Select {...props} items={items} />;
};

export default CharterStatusSelect;
