import React from 'react';

export interface Props {}

export const withSource = <P extends Props>(
  Component: React.ComponentType<any>,
  source: () => Promise<{ id: number; name: string }[]>
) =>
  class WithSource extends React.PureComponent<P> {
    render() {
      return <Component source={source} {...this.props} />;
    }
  };
