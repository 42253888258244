import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';

const ResetPasswordLinkExpired: React.FC = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col sm:max-w-md w-[320px] px-5 sm:px-0">
      <div className="uppercase text-xs tracking-[2px] font-semibold">
        <FormattedMessage id={translations.pages.resetLinkExpired.title} />
      </div>

      <Spacer xs={6} />

      <div className="text-sm font-normal text-flyblack-light-gray">
        <FormattedMessage id={translations.pages.resetLinkExpired.subtitle} />
      </div>

      <Spacer xs={6} />

      <Button onClick={() => history.push(urls.signIn)} appearance="white" type="button">
        <FormattedMessage id={translations.pages.resetLinkExpired.backToLogin} />
      </Button>
    </div>
  );
};

export default ResetPasswordLinkExpired;
