import React from 'react';

import Header from '@/components/Header';

import Main from './Main';

export interface Props {}

const Home: React.FC<Props> = () => {
  return (
    <div className="h-screen flex overflow-hidden">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <Header />

        <Main />
      </div>
    </div>
  );
};

export default Home;
