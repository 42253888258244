import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getOpenFlights } from '@/services/api/reports';

import useLoad from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import LargeDataDetail from '@flyblack/common/components/LargeDataDetail';
import RowContainer from '@flyblack/common/components/RowContainer';

import OverviewCard from './OverviewCard';

const OpenFlightsCard = () => {
  const intl = useIntl();

  const { value: openFlightsReport, loading, error } = useLoad({
    load: getOpenFlights
  });

  return (
    <OverviewCard title={intl.formatMessage({ id: translations.pages.overview.activeFlights.title })}>
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : (
        <RowContainer>
          <RowContainer.Item col={6}>
            <LargeDataDetail
              title={intl.formatMessage({ id: translations.pages.overview.activeFlights.activeDeals })}
              data={
                error
                  ? intl.formatMessage({ id: translations.pages.overview.errorValue })
                  : openFlightsReport.adminEmptyLegs
              }
            />
          </RowContainer.Item>

          <RowContainer.Item col={6}>
            <LargeDataDetail
              title={intl.formatMessage({ id: translations.pages.overview.activeFlights.activeShuttles })}
              data={
                error
                  ? intl.formatMessage({ id: translations.pages.overview.errorValue })
                  : openFlightsReport.adminJetShuttles
              }
            />
          </RowContainer.Item>
        </RowContainer>
      )}
    </OverviewCard>
  );
};

export default OpenFlightsCard;
