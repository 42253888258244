import React from 'react';
import { FormatDateOptions, FormattedDate, useIntl } from 'react-intl';

import { Notification } from '@/domains/notification';

import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import PushNotificationDetailModal from './PushNotificationDetailModal';

export interface Props {
  data: Notification;
}

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true
};

const PushNotificationTableItem: React.FC<Props> = ({ data }) => {
  const intl = useIntl();

  return (
    <Modal modal={PushNotificationDetailModal} data={data}>
      {({ open }) => (
        <Typography
          is={Table.Row}
          onClick={open}
          className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
          type="halcyon"
          hoverable
        >
          <Table.Data className="pr-1 w-[80%]">
            <div className="flex flex-col">
              <Typography is="span" type="halcyon">{`${data.title}`}</Typography>

              <Typography is="span" type="hummingbird" color="lightGray">
                {data.content}
              </Typography>
            </div>
          </Table.Data>

          <Table.Data className="w-[15%] justify-end">
            <FormattedDate value={data.createdAt} {...formatDateOptions} />
          </Table.Data>
        </Typography>
      )}
    </Modal>
  );
};

export default PushNotificationTableItem;
