import { authorizedApi } from '../network/authorized-api';
import { Page } from '@flyblack/common/domains';
import { PromoCodeListItem } from '@/domains/promo-code';

export const getPromoCodes = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<PromoCodeListItem>>(`/v1/promo-codes`, { pageNumber, pageSize, ...options });

export const createPromoCode: (amount, code, endsAt) => Promise<number> = (amount, code, endsAt) =>
  authorizedApi.post(`/v1/promo-codes`, { code, endsAt, amount });

export const updatePromoCode: (id, amount, code, endsAt) => Promise<any> = (id, amount, code, endsAt) =>
  authorizedApi.put(`/v1/promo-codes/${id}`, { code, endsAt, amount });

export const deletePromoCode: (id) => Promise<number> = (id) => authorizedApi.delete(`/v1/promo-codes/${id}`);
