import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import useLoad from '@flyblack/common/hooks/useLoad';
import { By, Order, Sort } from '@flyblack/common/domains';
import { getPromoCodes } from '@/services/api/promo-code';
import { getFriendReferralCreditValue } from '@/services/api/friend-fererral';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import usePagination from '@flyblack/common/hooks/usePagination';
import PageSelector from '@flyblack/common/components/PageSelector';

import PromoCodesTableItem from './PromoCodesTableItem';
import ShuttleTableHeader from './PromoCodesTableHeader';
import PromoCodeDetailModal from './PromoCodeDetailModal';
import FriendReferralCreditModal from './FriendReferralCreditModal';

import SettingsNavigation from '../SettingsNavigation';

export interface Props {}

const PromoCodes: React.FC<Props> = () => {
  const { loading, data: promoCodes, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size) => {
      return getPromoCodes(page, size, {
        [Sort.By]: By.CreatedAt,
        [Sort.Order]: Order.Descendent
      });
    }
  });

  const { value: friendReferralCredit, loading: friendReferralLoading, reload: friendReferralReload } = useLoad({
    load: () => getFriendReferralCreditValue()
  });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex items-center justify-between">
          <SettingsNavigation />

          <div className="flex h-14 items-center justify-end gap-6">
            {!friendReferralLoading && (
              <Modal modal={FriendReferralCreditModal} data={friendReferralCredit} onSuccess={friendReferralReload}>
                {({ open }) => (
                  <Button appearance="ghost" onClick={open}>
                    <Typography is="span" type="halcyon" color="lightGray" weight="lighter">
                      <FormattedMessage id={translations.inputs.buttons.friendReferralCredit} />
                    </Typography>

                    <Typography is="span" type="halcyon" className="ml-8">
                      {friendReferralCredit.value}
                    </Typography>
                  </Button>
                )}
              </Modal>
            )}

            <Modal modal={PromoCodeDetailModal} onSuccess={reload}>
              {({ open }) => (
                <Button appearance="white" onClick={open}>
                  <Icon type="add" className="pr-2" />

                  <Typography is="span" type="halcyon">
                    <FormattedMessage id={translations.inputs.buttons.addPromoCode} />
                  </Typography>
                </Button>
              )}
            </Modal>
          </div>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible={true} center className="w-full h-[612px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <ShuttleTableHeader />

            <Table.Body>
              {promoCodes.map((item) => (
                <PromoCodesTableItem key={item.id} data={item} reload={reload} />
              ))}
            </Table.Body>
          </Table>
        )}

        <Spacer xs={3} />

        <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default PromoCodes;
