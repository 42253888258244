import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { ShuttleBooking } from '@flyblack/common/domains';

import Form from '@flyblack/common/components/Form';
import Modal from '@flyblack/common/components/Modal';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';

import ShuttlePassengersModal from '@/components/ShuttlePassengersModal';

interface Props {
  formId: string;
  trips: ShuttleBooking[];
}

const PassengersSection: React.FC<Props> = ({ formId, trips }) => {
  const intl = useIntl();

  return (
    <DetailCard>
      <div>
        <Typography is="span" type="halcyon" weight="bold">
          <FormattedMessage id={translations.pages.shuttles.detail.sections.passengers} />
        </Typography>

        <Spacer xs={4} />

        <div className="flex justify-between items-center">
          <Form.Field
            is={TextInput}
            id={`${formId}-passengerCount`}
            name="passengerCount"
            type="text"
            label={intl.formatMessage({ id: translations.pages.shuttles.detail.passengerDetail.label })}
            leadingIcon="crew"
            disabled
          />

          <Modal modal={ShuttlePassengersModal} bookings={trips}>
            {({ open }) => (
              <Typography is="div" type="halcyon" className="cursor-pointer" weight="bold" onClick={open}>
                <FormattedMessage id={translations.pages.shuttles.detail.passengerDetail.viewPassengers} />
              </Typography>
            )}
          </Modal>
        </div>
      </div>
    </DetailCard>
  );
};

export default PassengersSection;
