import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import LoadingIndicator, { Props as LoadingIndicatorProps } from './LoadingIndicator';

export interface Props {
  absolute?: boolean;
  fixed?: boolean;
  visible: boolean;
  center?: boolean;
  overlay?: boolean;
  className?: string;
}

interface StaticComponents {
  Indicator: React.FC<LoadingIndicatorProps>;
}

const Loading: React.FC<Props> & StaticComponents = ({
  absolute,
  fixed,
  visible,
  center,
  overlay,
  className,
  children
}) => {
  return (
    <CSSTransition
      timeout={300}
      in={visible}
      mountOnEnter
      unmountOnExit
      appear
      classNames={{
        enterActive: 'block',
        enterDone: 'block'
      }}
    >
      <div
        className={classnames(
          'z-[1]',
          fixed ? 'fixed' : absolute || overlay ? 'absolute' : 'relative',
          {
            hidden: !visible,
            'bg-white rounded-[20px] top-[1px] left-[1px] right-[1px] bottom-[1px]': overlay,
            'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': absolute && center
          },
          className
        )}
      >
        <div
          className={classnames(
            'font-semibold',
            center && 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          )}
        >
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

Loading.Indicator = LoadingIndicator;

export default Loading;
