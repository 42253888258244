import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import { createJetShuttle } from '@/services/api/shuttle';

import { getNow } from '@flyblack/common/util';
import { ScheduleType, RecurrenceRule } from '@flyblack/common/domains';

import Form from '@flyblack/common/components/Form';
import Card from '@flyblack/common/components/Card';
import WeekdaySelect from '@/components/WeekdaySelect';
import Spacer from '@flyblack/common/components/Spacer';
import DetailRow from '@flyblack/common/components/DetailRow';
import Container from '@flyblack/common/components/Container';
import DetailCard from '@flyblack/common/components/DetailCard';
import Typography from '@flyblack/common/components/Typography';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';
import { SubmitError } from '@flyblack/common/components/Error';

import { createShuttleSchema } from './ShuttleSchema';

import PilotsSection from './PilotsSection';
import SeatingSection from './SeatingSection';
import AircraftInfoSection from './AircraftInfoSection';
import TravelDetailsSection from './TravelDetailsSection';
import ShuttleDetailButtons from './ShuttleDetailButtons';

const DEFAULT_CURRENCY = 'USD';

export interface Props {}

const TextInputWithValidation = withValidation(TextInput);

const WeekdaySelectWithValidation = withValidation(WeekdaySelect);

const CreateShuttle: React.FC<Props> = () => {
  const history = useHistory();

  const intl = useIntl();

  const now = getNow(dateFormat);

  return (
    <div className="min-h-full py-20">
      <Container max>
        <Form
          id="jet-shuttle"
          schema={createShuttleSchema}
          subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
          initialValues={{
            isRecurring: false,
            recurringEndDate: now,
            departureDate: now,
            arrivalDate: now
          }}
          onSubmit={(values) =>
            createJetShuttle({
              aircraftCategoryId: values.aircraftCategoryId,
              aircraftTypeId: values.aircraftTypeId,
              destinationAirportId: values.destinationAirportId,
              destinationAirportTerminal: values.destinationAirportTerminal,
              eventName: values.eventName,
              pilots: [values.firstPilot || '', values.secondPilot || ''],
              price: {
                currency: DEFAULT_CURRENCY,
                value: values.price
              },
              recurrenceRule: values.isRecurring ? RecurrenceRule.Recurring : RecurrenceRule.Event,
              recurringDays: values.isRecurring ? values.recurringDays : [],
              recurringEndDate: values.isRecurring ? `${values.recurringEndDate}T00:00:00` : '',
              schedule: {
                type: ScheduleType.Fixed,
                departsAt: `${values.departureDate}T${values.departureTime}:00`,
                arrivesAt: `${values.arrivalDate}T${values.arrivalTime}:00`
              },
              sourceAirportId: values.sourceAirportId,
              sourceAirportTerminal: values.sourceAirportTerminal,
              tailNumber: values.tailNumber
            }).then(() => history.push(urls.shuttles.base))
          }
        >
          {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
            <React.Fragment>
              <div className="flex flex-row justify-between items-center">
                <Typography is="span" type="flamingo">
                  <FormattedMessage id={translations.pages.shuttles.detail.title.new} />
                </Typography>

                <ShuttleDetailButtons formId={formId} submitting={submitting} disabledSubmit={!dirty} />
              </div>

              {submitError && (
                <div className="flex flex-col items-end">
                  <Spacer xs={6} />
                  <SubmitError error={submitError} />
                </div>
              )}

              <Spacer xs={6} />

              <DetailRow>
                <Card bg="dark" className="flex justify-between w-full mb-8">
                  <TravelDetailsSection
                    formId={formId}
                    submitting={submitting}
                    form={form}
                    editMode={false}
                    type="departure"
                  >
                    <Spacer xs={6} />

                    <Form.Field
                      is={ToggleSwitch}
                      id={`${formId}-is-fixed-schedule`}
                      name="isRecurring"
                      type="checkbox"
                      label={intl.formatMessage({ id: translations.inputs.recurringFlight.label })}
                      readOnly={submitting}
                    />

                    <Spacer xs={4} />

                    {values.isRecurring ? (
                      <div className="flex justify-between items-end">
                        <Form.Field
                          is={WeekdaySelectWithValidation}
                          id={`${formId}-recurringDays`}
                          name="recurringDays"
                          multiple
                          searchable
                          editable
                          readOnly={submitting}
                          label={intl.formatMessage({ id: translations.inputs.weekdaySelect.label })}
                        />

                        <Form.Field
                          is={DatePickerInput}
                          id={`${formId}-recurring-end-date`}
                          name="recurringEndDate"
                          type="text"
                          readOnly={submitting}
                          label="Repeat ends on"
                          change={form.change}
                        />
                      </div>
                    ) : (
                      <Form.Field
                        is={TextInputWithValidation}
                        id={`${formId}-event-name`}
                        name="eventName"
                        type="text"
                        label={intl.formatMessage({ id: translations.inputs.eventName.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.eventName.placeholder })}
                        readOnly={submitting}
                      />
                    )}
                  </TravelDetailsSection>

                  <TravelDetailsSection formId={formId} submitting={submitting} form={form} type="arrival" />
                </Card>
              </DetailRow>

              <DetailRow>
                <AircraftInfoSection formId={formId} form={form} submitting={submitting} values={values} />

                <PilotsSection formId={formId} submitting={submitting} />
              </DetailRow>

              <DetailRow>
                <SeatingSection formId={formId} submitting={submitting} />

                <DetailCard transparent />
              </DetailRow>

              {submitError && (
                <div className="flex flex-col items-end">
                  <SubmitError error={submitError} />
                  <Spacer xs={6} />
                </div>
              )}

              <ShuttleDetailButtons formId={formId} submitting={submitting} disabledSubmit={!dirty} />
            </React.Fragment>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default CreateShuttle;
