import React from 'react';
import classnames from 'classnames';

import Input, { Props as InputProps } from '@flyblack/common/components/Form/Input';
import Icon, { Type } from '@flyblack/common/components/Icon';

export type InputAppearance = 'regular' | 'light';

const appearanceToInputClassNameMap: Record<InputAppearance, string> = {
  regular: 'border-flyblack-gray focus:border-white text-white placeholder-flyblack-light-gray',
  light: 'border-flyblack-light-gray focus:border-black text-black placeholder-flyblack-light-gray'
};

const appearanceToIconClassNameMap: Record<InputAppearance, string> = {
  regular: 'text-white',
  light: 'text-black'
};

export interface Props extends InputProps {
  invalid?: boolean;
  label?: string;
  leadingIcon?: Type;
  icon?: Type;
  appearance?: InputAppearance;
  inputClassName?: string;
  className?: string;
  invert?: boolean;
}

const TextInput: React.FC<Props> = ({
  id,
  invalid,
  className,
  inputClassName,
  appearance = 'regular',
  label,
  icon,
  leadingIcon,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className={classnames('relative flex flex-col')}>
        {leadingIcon && (
          <Icon
            type={leadingIcon}
            className={classnames(
              'absolute bottom-[10px] left-0 pointer-events-none',
              { 'text-flyblack-red transition-colors': invalid },
              appearanceToIconClassNameMap[appearance]
            )}
          />
        )}

        {label && (
          <label
            htmlFor={id}
            className={classnames('block text-xs text-flyblack-light-gray -mb-1 text-left', {
              'text-flyblack-red transition-colors': invalid,
              'pl-12': leadingIcon,
              'pr-12': icon
            })}
          >
            {label}
          </label>
        )}

        <Input
          id={id}
          className={classnames(
            'appearance-none transition-colors text-sm box-border border-0 border-b focus:ring-0 block w-full px-0',
            {
              'text-flyblack-red border-flyblack-red border-opacity-100 transition-colors placeholder-flyblack-red placeholder-opacity-100': invalid,
              'pl-12': leadingIcon,
              'pr-12': icon
            },
            appearanceToInputClassNameMap[appearance],
            inputClassName
          )}
          {...rest}
        />

        {icon && (
          <Icon
            type={icon}
            className={classnames(
              'absolute bottom-[10px] right-0 pointer-events-none',
              { 'text-flyblack-red transition-colors': invalid },
              appearanceToIconClassNameMap[appearance]
            )}
          />
        )}
      </div>
    </div>
  );
};

export default TextInput;
