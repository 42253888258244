import React from 'react';
import debounce from 'lodash/debounce';

import { CharterAirport } from '@flyblack/common/domains';

import HookSelect, { Item, Props as SelectProps } from './HookSelect';
import { getCharterAirports } from '../../../landing/services/api/charter';

export interface Props extends Omit<SelectProps<string>, 'items' | 'onChange'> {
  onChange: (value: any) => any;
}

const CharterAirportSelect: React.FC<Props> = ({ value, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<Item<string>[]>([]);

  const mapAirportsToSelectData = (airports: CharterAirport[]) =>
    airports.map((airport) => ({
      value: airport.icao,
      name: `(${airport.icao}) ${airport.city} - ${airport.name}`
    }));

  const load = (callback: () => Promise<Item<string>[]>) => {
    setLoading(true);

    return callback()
      .then((items) => {
        setLoading(false);
        setItems(items);
      })
      .catch(() => {
        setLoading(false);
        setItems([]);
      });
  };

  const loadFromSearch = async (inputValue: string) => {
    if (!inputValue || inputValue.length === 0) return [];

    return load(() => getCharterAirports(inputValue).then((response) => mapAirportsToSelectData(response.content)));
  };

  const loadFromValue = async () => {
    if (!value || value.length === 0) return [];

    return load(() =>
      getCharterAirports(value).then((response) =>
        mapAirportsToSelectData(response.content).filter((item) => item.value === value)
      )
    );
  };

  const debouncedloadFromSearch = debounce(loadFromSearch, 300);

  React.useEffect(() => {
    loadFromValue();
  }, [value]);

  return (
    <HookSelect
      value={value}
      {...rest}
      items={items}
      onInputValueChange={debouncedloadFromSearch}
      onChange={async (value) => {
        const { onChange } = rest;

        return onChange(value);
      }}
      invert
    />
  );
};

export default CharterAirportSelect;
