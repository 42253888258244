import React from 'react';
import classnames from 'classnames';

import Card from '@flyblack/common/components/Card';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import { SubmitError } from '@flyblack/common/components/Error';

interface Props extends InstanceProps {
  title: string;
  message?: string;
  confirmMessage: string;
  cancelMessage: string;
  onConfirm?: (event: React.MouseEvent) => Promise<any>;
  onCancel?: (event: React.MouseEvent) => Promise<any>;
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  message,
  confirmMessage,
  cancelMessage,
  onConfirm,
  onCancel,
  close,
  className
}) => {
  // TODO Use useState once instead of multiple times
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const loading = cancelLoading || confirmLoading;

  const handleConfirm = (event: React.MouseEvent) => {
    if (onConfirm) {
      setConfirmLoading(true);
      onConfirm(event)
        .then(() => {
          setConfirmLoading(false);
          close();
        })
        .catch((error) => {
          setConfirmLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (onCancel) {
      setCancelLoading(true);
      onCancel(event)
        .then(() => {
          setCancelLoading(false);
          close();
        })
        .catch((error) => {
          setCancelLoading(false);
          setError(error);
        });
    } else {
      close();
    }
  };

  return (
    <Modal.Card className={classnames('min-w-[420px]', className)}>
      <Modal.Header close={close}>{title}</Modal.Header>

      {(message || error) && (
        <Card.Row padded>
          {message && (
            <Typography is="div" type="hummingbird">
              {message}
            </Typography>
          )}

          {error && <SubmitError error={error} />}
        </Card.Row>
      )}

      <Card.Row padded className="justify-between">
        <Button
          appearance="lightGhost"
          className="min-w-[180px]"
          onClick={handleCancel}
          loading={cancelLoading}
          disabled={loading}
        >
          {cancelMessage}
        </Button>
        <Button
          appearance="black"
          className="min-w-[180px]"
          onClick={handleConfirm}
          loading={confirmLoading}
          disabled={loading}
        >
          {confirmMessage}
        </Button>
      </Card.Row>
    </Modal.Card>
  );
};

export default ConfirmationModal;
