import React from 'react';
import { FormattedDate } from 'react-intl';

import { UserDetail } from '@/domains/user-detail';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {
  data: UserDetail;
  onClick: (event: MouseEvent) => any;
}

const RegisteredTableItem: React.FC<Props> = ({ data, onClick }) => (
  <Typography
    is={Table.Row}
    className="justify-between font-light h-20 px-6 cursor-pointer odd:bg-flyblack-gray"
    type="halcyon"
    hoverable
    onClick={onClick}
  >
    <Table.Data className="pr-1 w-[30%]">{`${data.firstName} ${data.lastName}`}</Table.Data>

    <Table.Data className="pr-1 w-[60%]">{data.email}</Table.Data>

    <Table.Data className="pr-1 w-[10%]">
      <FormattedDate value={data.createdAt} month="short" day="2-digit" year="numeric" />
    </Table.Data>
  </Typography>
);

export default RegisteredTableItem;
