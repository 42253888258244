import React from 'react';

import { InstanceProps } from '@flyblack/common/components/Modal';
import Typography from '@flyblack/common/components/Typography';
import Button from '@flyblack/common/components/Button';
import Card from '@flyblack/common/components/Card';
import Icon from '@flyblack/common/components/Icon';

export interface Props extends Pick<InstanceProps, 'close'> {}

const ModalHeader = ({ close, children }: React.PropsWithChildren<Props>) => (
  <Card.Row padded className="flex flex-row justify-between items-center">
    <Typography is="span" type="halcyon">
      {children}
    </Typography>

    <Button type="button" appearance="white" className="z-[1] h-[24px] w-[24px] cursor-pointer" onClick={close}>
      <Icon type="close" className="text-flyblack-dark-gray" />
    </Button>
  </Card.Row>
);

export default ModalHeader;
