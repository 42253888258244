import React from 'react';

import Typography from '@flyblack/common/components/Typography';

export interface Props {
  title: string;
  data: string | number;
}

const LargeDataDetail = ({ title, data }: Props) => {
  return (
    <div>
      <Typography is="div" type="halcyon" color="lightGray" className="mb-4">
        {title}
      </Typography>

      <Typography is="div" type="flamingo">
        {data}
      </Typography>
    </div>
  );
};

export default LargeDataDetail;
