import React from 'react';
import debounce from 'lodash/debounce';

import Select, { Item, Props as SelectProps } from '@flyblack/common/components/Select';

interface Value {
  placeId: string;
  country: string;
}

export interface Props extends Omit<SelectProps<Value, string>, 'items' | 'onChange'> {
  onChange: (value: Value | Value[] | null) => any;
  google: any;
}

const LocationSelect: React.FC<Props> = ({ value, google, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<Item<string>[]>([]);

  const load = (callback: () => Promise<Item<string>[]>) => {
    setLoading(true);

    return callback()
      .then((items) => {
        setLoading(false);
        setItems(items);
      })
      .catch(() => {
        setLoading(false);
        setItems([]);
      });
  };

  const loadFromPlaceId = () => {
    if (!value) return;
    if (Array.isArray(value)) return;

    const newValue = value;

    return load(() =>
      google.getLocationsForPlaceId(newValue.placeId).then((items) =>
        items.map((item) => ({
          value: item.placeId,
          name: `${item.city.longName}, ${item.country.longName}`
        }))
      )
    );
  };

  const loadFromAddress = async (inputValue: string) => {
    if (!inputValue || inputValue.length === 0) return [];

    return load(() =>
      google.getAddressAutocomplete(inputValue).then((items) =>
        items.map((item) => ({
          value: item.placeId,
          name: item.name
        }))
      )
    );
  };

  const getValue = (placeId: string): Promise<Value> => {
    return google.getLocationsForPlaceId(placeId).then((locations) => {
      const location = locations[0];

      if (!location) return null;

      return { placeId: location.placeId, country: location.country.shortName };
    });
  };

  const debouncedloadFromAddress = debounce(loadFromAddress, 300);

  React.useEffect(() => {
    loadFromPlaceId();
  }, [value]);

  return (
    <Select
      value={value}
      {...rest}
      items={items}
      getItemValue={(value: Value) => value.placeId}
      onInputValueChange={debouncedloadFromAddress}
      onChange={async (value) => {
        const { onChange } = rest;

        if (value instanceof Array) {
          return onChange(await Promise.all(value.map(getValue)));
        }

        return onChange(await getValue(value));
      }}
    />
  );
};

export default LocationSelect;
