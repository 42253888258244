import React from 'react';

import { getCities } from '@/services/api/city';

import LoadableSelect, { Props as LoadableSelectProps } from '@flyblack/common/components/LoadableSelect';

interface Props extends Omit<LoadableSelectProps, 'source'> {}

const CitySelect: React.FC<Props> = (props) => {
  return <LoadableSelect searchable source={() => getCities(1, 100).then((response) => response.content)} {...props} />;
};

export default CitySelect;
