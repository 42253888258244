import React from 'react';
import { format } from 'date-fns';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  currentDate: Date;
  onGoToPreviousMonth: () => any;
  onGoToNextMonth: () => any;
}

const MonthNavigation = ({ currentDate, onGoToPreviousMonth, onGoToNextMonth }: Props) => {
  return (
    <React.Fragment>
      <Button type="button" appearance="white" className="h-[40px] w-[40px] m-[2px]" onClick={onGoToPreviousMonth}>
        <Icon type="chevronLeft" />
      </Button>

      <Typography is="span" type="halcyon" className="font-semibold text-black">
        {format(currentDate, 'MMMM yyyy')}
      </Typography>

      <Button type="button" appearance="white" className="h-[40px] w-[40px] m-[2px]" onClick={onGoToNextMonth}>
        <Icon type="chevronRight" />
      </Button>
    </React.Fragment>
  );
};

export default MonthNavigation;
