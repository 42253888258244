import React from 'react';

import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { Airport } from '@flyblack/common/domains';
import { createAirport, updateAirport } from '@/services/api/airport';

import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import { SubmitError } from '@flyblack/common/components/Error';
import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import RowContainer from '@flyblack/common/components/RowContainer';

import CitySelect from '@/components/CitySelect';

const CitySelectWithValidation = withValidation(CitySelect);
const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object({
  cityId: yup
    .number()
    .label(translations.inputs.airportCity.label)
    .required(),
  iataCode: yup
    .string()
    .label(translations.inputs.iataCode.label)
    .required(),
  icaoCode: yup
    .string()
    .label(translations.inputs.icaoCode.label)
    .required(),
  faaCode: yup
    .string()
    .label(translations.inputs.faaCode.label)
    .required(),
  name: yup
    .string()
    .label(translations.inputs.airportName.label)
    .required()
});

interface Props extends InstanceProps {
  data?: Airport;
  onSuccess?: () => any;
}

const AirportModal = ({ data, close, onSuccess }: Props) => {
  const intl = useIntl();

  const handleSubmit = ({ cityId, iataCode, icaoCode, faaCode, name }) =>
    (data
      ? updateAirport(data.id, { cityId, iataCode, icaoCode, faaCode, name })
      : createAirport({ cityId, iataCode, icaoCode, faaCode, name })
    ).then(() => {
      onSuccess && onSuccess();
      close();
    });

  return (
    <Modal.Card className="w-[572px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.airportModal.title[data ? 'update' : 'new']} />
      </Modal.Header>

      <Form
        schema={schema}
        initialValues={
          data && {
            cityId: data.city.id,
            iataCode: data.iataCode,
            icaoCode: data.icaoCode,
            faaCode: data.faaCode,
            name: data.name
          }
        }
        subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
        onSubmit={handleSubmit}
      >
        {({ submitError, submitting, valid, dirty }) => (
          <React.Fragment>
            <Card.Row padded className="flex flex-col">
              <Form.Field
                is={CitySelectWithValidation}
                id="cityId"
                name="cityId"
                type="text"
                appearance="light"
                label={intl.formatMessage({ id: translations.inputs.airportCity.label })}
                placeholder={intl.formatMessage({
                  id: translations.inputs.airportCity.placeholder
                })}
                readOnly={submitting}
                disabled={!!data}
              />

              <Spacer xs={3} />

              <RowContainer>
                <RowContainer.Item col={4}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id="iataCode"
                    name="iataCode"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.iataCode.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.iataCode.placeholder })}
                    appearance="light"
                  />
                </RowContainer.Item>

                <RowContainer.Item col={4}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id="icaoCode"
                    name="icaoCode"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.icaoCode.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.icaoCode.placeholder })}
                    appearance="light"
                  />
                </RowContainer.Item>

                <RowContainer.Item col={4}>
                  <Form.Field
                    is={TextInputWithValidation}
                    id="faaCode"
                    name="faaCode"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.faaCode.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.faaCode.placeholder })}
                    appearance="light"
                  />
                </RowContainer.Item>
              </RowContainer>

              <Spacer xs={3} />

              <Form.Field
                is={TextInputWithValidation}
                id="name"
                name="name"
                type="text"
                label={intl.formatMessage({ id: translations.inputs.airportName.label })}
                placeholder={intl.formatMessage({ id: translations.inputs.airportName.placeholder })}
                appearance="light"
              />

              {!!submitError ? <SubmitError error={submitError} className="h-10" /> : <Spacer xs={5} />}
            </Card.Row>

            <Card.Row padded>
              <Button
                type="submit"
                className="font-medium ml-auto w-[170px]"
                appearance="black"
                disabled={!valid || !dirty || submitting}
              >
                <FormattedMessage id={translations.inputs.buttons.save} />
              </Button>
            </Card.Row>
          </React.Fragment>
        )}
      </Form>
    </Modal.Card>
  );
};

export default AirportModal;
