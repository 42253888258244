import { authorizedApi } from '../network/authorized-api';
import { Page } from '@flyblack/common/domains';
import { Notification } from '@/domains/notification';

export const getNotifications = (
  pageNumber: number,
  pageSize: number,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<Notification>>(`/v1/admin/notifications`, { pageNumber, pageSize, ...options });

export const sendNotification: (title, content) => Promise<any> = (title, content) =>
  authorizedApi.post(`/v1/admin/notifications/send/all`, { title, content });
