import { FlightsHoursFlownReport, FlightsReport, RevenueReport } from '@flyblack/common/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getOpenFlights = () => authorizedApi.get<FlightsReport>(`/v1/reports/open-flights`);

export const getBookedFlights = () => authorizedApi.get<FlightsReport>(`/v1/reports/booked-flights`);

export const getHoursFlown = () => authorizedApi.get<FlightsHoursFlownReport>(`/v1/reports/hours-flown`);

export const getRevenue = () => authorizedApi.get<RevenueReport>(`/v1/reports/revenue`);
