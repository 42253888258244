import React from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';

import { PromoCodeListItem } from '@/domains/promo-code';

import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import PromoCodeDetailModal from './PromoCodeDetailModal';

export interface Props {
  data: PromoCodeListItem;
  reload: () => any;
}

const formatDateOptions: FormatDateOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric'
};

const PromoCodesTableItem: React.FC<Props> = ({ data, reload }) => {
  const intl = useIntl();

  return (
    <Modal modal={PromoCodeDetailModal} data={data} onSuccess={reload}>
      {({ open }) => (
        <Typography
          is={Table.Row}
          onClick={open}
          className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
          type="halcyon"
          hoverable
        >
          <Table.Data className="pr-1 w-[15%]">{data.code}</Table.Data>

          <Table.Data className="pr-1 w-[10%]">{`${data.amount.symbol}${data.amount.value}`}</Table.Data>

          <Table.Data className="pr-1 w-[15%]">{intl.formatDate(data.startsAt, formatDateOptions)}</Table.Data>

          <Table.Data className="pr-1 w-[15%]">{intl.formatDate(data.endsAt, formatDateOptions)}</Table.Data>

          <Table.Data className="pr-1 w-[10%]">{data.redeems}</Table.Data>

          <Table.Data className="w-4" />
        </Typography>
      )}
    </Modal>
  );
};

export default PromoCodesTableItem;
