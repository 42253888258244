import React from 'react';
import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';
import { FormatDateOptions, FormattedDate, FormattedMessage } from 'react-intl';
import { translations } from '@/locale';
import { CharterEnquireItem, CharterEnquireStatus } from '@/domains/charter-enquire';
import classnames from 'classnames';
import CharterEnquireTableItemMenu from '@/page/Charters/Enquire/CharterEnquireTableItemMenu';

export interface Props {
  data: CharterEnquireItem;
  onAction: () => void;
}

const charterEnquireStatusToBgColorMap: Record<CharterEnquireStatus, string> = {
  [CharterEnquireStatus.Closed]: 'bg-[#751C1C]',
  [CharterEnquireStatus.Open]: 'bg-[#6D521D]'
};

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

const CharterEnquireTableItem: React.FC<Props> = ({ data, onAction }) => {
  return (
    <Typography
      is={Table.Row}
      className="justify-between cursor-pointer font-light h-20 px-10 odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
    >
      <Table.Data className="pr-1 w-[15%] break-all">{data.inquire.firstName}</Table.Data>

      <Table.Data className="pr-1 w-[15%] break-all">{data.inquire.lastName}</Table.Data>

      <Table.Data className="pr-1 w-[20%] break-all">{data.inquire.email}</Table.Data>

      <Table.Data className="pr-1 w-[15%] break-all">{data.inquire.phoneNumber}</Table.Data>

      <Table.Data className="pr-1 w-[10%] break-all">
        <FormattedDate value={data.quote.legs[0].date} {...formatDateOptions} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%] break-all">{`${data.quote.legs[0].source.iata} ${data.quote.legs[0].source.city}`}</Table.Data>

      <Table.Data className="pr-1 w-[10%] break-all">{`${data.quote.legs[0].destination.iata} ${data.quote.legs[0].destination.city}`}</Table.Data>

      <Table.Data className="pr-1 w-24 break-all">{`$${Number(data.quote.price).toLocaleString()}`}</Table.Data>

      <Table.Data className="pr-1 w-24 break-all">{data.quote.legs.length > 1 ? 'Yes' : 'No'}</Table.Data>

      <Table.Data className="pr-1 w-[160px] justify-end">
        <div className={classnames(charterEnquireStatusToBgColorMap[data.status], 'px-4 py-1 rounded-sm')}>
          <Typography is="span" type="hummingbird">
            <FormattedMessage id={translations.domains.charterEnquireStatus[data.status]} />
          </Typography>
        </div>
      </Table.Data>

      <Table.Data className="w-4 justify-end" stopPropagation>
        <CharterEnquireTableItemMenu charterEnquireId={data.id} onAction={onAction} status={data.status} />
      </Table.Data>
    </Typography>
  );
};

export default CharterEnquireTableItem;
