import React from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { UserDetail } from '@/domains/user-detail';
import { UserStats } from '@/domains/users-response';
import { UserFilter, UserStatus, By, Order, Sort, WaitingListStatus } from '@flyblack/common/domains';
import { getUsers } from '@/services/api/user';
import { getWaitingListStatus, setWaitingListStatus } from '@/services/api/waiting-list';

import useLoad from '@flyblack/common/hooks/useLoad';
import usePagination from '@flyblack/common/hooks/usePagination';

import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import LiveSubmit from '@flyblack/common/components/LiveSubmit';
import Typography from '@flyblack/common/components/Typography';
import PageSelector from '@flyblack/common/components/PageSelector';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';

import MemberTableItem from './MemberTableItem';
import MemberTableHeader from './MemberTableHeader';
import PendingTableItem from './PendingTableItem';
import PendingTableHeader from './PendingTableHeader';
import RegisteredTableItem from './RegisteredTableItem';
import RegisteredTableHeader from './RegisteredTableHeader';

const statusByFilter = {
  [UserFilter.OnWaitingList]: UserStatus.OnWaitingList,
  [UserFilter.PendingApproval]: UserStatus.PendingApproval,
  [UserFilter.Member]: UserStatus.Member,
  [UserFilter.Registered]: null
};

const headerByFilter = {
  [UserFilter.PendingApproval]: () => <PendingTableHeader isApprovable />,
  [UserFilter.OnWaitingList]: () => <PendingTableHeader />,
  [UserFilter.Member]: () => <MemberTableHeader />,
  [UserFilter.Registered]: () => <RegisteredTableHeader />
};

const itemByFilter: Record<
  UserFilter,
  React.FC<{ item: UserDetail; onClick: (id: number) => any; onAction?: () => any }>
> = {
  [UserFilter.PendingApproval]: ({ item, onClick, onAction }) => (
    <PendingTableItem isApprovable data={item} onClick={() => onClick(item.id)} onAction={onAction} />
  ),
  [UserFilter.OnWaitingList]: ({ item, onClick, onAction }) => (
    <PendingTableItem isApprovable data={item} onClick={() => onClick(item.id)} onAction={onAction} />
  ),
  [UserFilter.Member]: ({ item, onClick }) => <MemberTableItem data={item} onClick={() => onClick(item.id)} />,
  [UserFilter.Registered]: ({ item, onClick }) => <RegisteredTableItem data={item} onClick={() => onClick(item.id)} />
};

//TODO this page really needs a refactor once all the APIS are finished
const Membership = ({ history }: RouteComponentProps) => {
  const intl = useIntl();

  const [selectedFilter, setSelectedFilter] = React.useState(UserFilter.Registered);
  const [userCategoryCounters, setUserCategoryCounters] = React.useState<UserStats>({});

  const userCountByFilter = {
    [UserFilter.PendingApproval]: userCategoryCounters.pendingApprovalUsers,
    [UserFilter.OnWaitingList]: userCategoryCounters.waitingListUsers,
    [UserFilter.Member]: userCategoryCounters.activeUsers,
    [UserFilter.Registered]: userCategoryCounters.registeredUsers
  };

  const [allowSignupForMembership, setAllowSignupForMembership] = React.useState(true);

  const { value: signupForMembership, loading: loadingSignupForMembership } = useLoad({
    load: () => getWaitingListStatus().then(({ status }) => status === WaitingListStatus.Disabled)
  });

  const { loading, data, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (pageNumber, pageSize) => {
      return getUsers(pageNumber, pageSize, {
        status: statusByFilter[selectedFilter],
        [Sort.By]: By.CreatedAt,
        [Sort.Order]: Order.Descendent
      }).then((response) => {
        const { users, ...rest } = response;

        setUserCategoryCounters({
          ...rest
        });

        return response.users;
      });
    }
  });

  React.useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [selectedFilter]);

  React.useEffect(() => {
    setAllowSignupForMembership(signupForMembership);
  }, [signupForMembership]);

  const onAllowSignupForMembershipChange = React.useCallback(
    ({ fieldState }) => {
      return setWaitingListStatus(fieldState ? WaitingListStatus.Disabled : WaitingListStatus.Enabled)
        .then(() => {
          setAllowSignupForMembership(fieldState);
          if (fieldState && selectedFilter === UserFilter.OnWaitingList) {
            setSelectedFilter(UserFilter.PendingApproval);
          }
        })
        .catch((error) => {
          // this way we can prevent turning off the waiting list (on UI) if there are users on it
          reload();
          throw error;
        });
    },
    [selectedFilter, setAllowSignupForMembership]
  );

  const Header = headerByFilter[selectedFilter];
  const Item = itemByFilter[selectedFilter];

  const onItemClick = (id) => history.push(urls.membership.detail.get(id));

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex flex-row justify-between items-center">
          {loadingSignupForMembership ? (
            <Loading visible={true} className="ml-2">
              <Loading.Indicator size={20} borderWidth={2} />
            </Loading>
          ) : (
            <React.Fragment>
              <LiveSubmit value={{ fieldState: signupForMembership }} onChange={onAllowSignupForMembershipChange}>
                {({ loading, value: { fieldState }, set }) => (
                  <div className="flex">
                    <ToggleSwitch
                      label={intl.formatMessage({ id: translations.pages.membership.allowMembershipSignup })}
                      disabled={loading || userCategoryCounters.waitingListUsers > 0}
                      checked={fieldState}
                      onClick={() => set('fieldState', !fieldState)}
                    />
                    {loading && (
                      <Loading visible={true} className="ml-2">
                        <Loading.Indicator size={20} borderWidth={2} />
                      </Loading>
                    )}
                  </div>
                )}
              </LiveSubmit>

              <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-sm">
                {Object.values(UserFilter)
                  .filter((item) => !allowSignupForMembership || item !== UserFilter.OnWaitingList)
                  .map((item) => (
                    <Button
                      key={item}
                      type="button"
                      appearance={selectedFilter === item ? 'white' : 'transparent'}
                      className="mx-1 first:mx-0 last:mx-0 w-[200px]"
                      onClick={() => setSelectedFilter(item)}
                      disabled={loading}
                    >
                      <Typography is="span" type="halcyon">
                        {`${intl.formatMessage({
                          id: translations.domains.userFilter[item]
                        })} (${userCountByFilter[item]})`}
                      </Typography>
                    </Button>
                  ))}
              </div>
            </React.Fragment>
          )}
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible={true} center className="w-full h-[400px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <React.Fragment>
            <Table className="min-h-[612px]">
              <Header />
              <Table.Body>
                {data.map((item) => (
                  <Item key={item.id} item={item} onClick={onItemClick} onAction={reload} />
                ))}
              </Table.Body>
            </Table>

            <Spacer xs={3} />

            <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default Membership;
