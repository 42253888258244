import {
  format,
  parse,
  eachDayOfInterval,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getDay,
  parseISO,
  differenceInYears,
  differenceInDays
} from 'date-fns';
import range from 'lodash/range';

export const getWeekdayLabels = ({ firstDayOfWeek = 1, weekDayFormat = 'EEE' }): string[] => {
  const now = new Date();
  const arr = eachDayOfInterval({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek)
  });
  return arr.reduce((array: string[], date) => {
    // @ts-ignore
    array.push(format(date, weekDayFormat));
    return array;
  }, []);
};

export const getDays = ({
  year,
  month,
  firstDayOfWeek = 1,
  dayNumberFormat = 'd'
}): (number | { date: Date; dayNumber: string })[] => {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);

  const prevMonthDays = range(
    monthStartDay >= firstDayOfWeek ? monthStartDay - firstDayOfWeek : 6 - firstDayOfWeek + monthStartDay + 1
  ).fill(0);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd }).map((date) => ({
    date,
    dayNumber: format(date, dayNumberFormat)
  }));

  return [...prevMonthDays, ...days];
};

export const getTime = (isoString: string) => format(new Date(isoString), 'HH:mm');

export const getDateFormat = (isoString: string, dateFormat = 'MMM d, yyyy, HH:mm') =>
  format(new Date(isoString), dateFormat);

export const getISOString = (date: string, dateFormat: string) => parse(date, dateFormat, new Date()).toISOString();

export const getNow = (dateFormat: string) => format(new Date(), dateFormat);

export const getAgeFromBirthdate: (birthDate: string) => number = (birthDate) => {
  return differenceInYears(new Date(), parseISO(birthDate));
};

export const altDateFormat = 'dd-MM-yyyy';

export const formatDuration = (minutes: number) => {
  const durationHours = Math.floor(minutes / 60);
  const durationMinutes = minutes - durationHours * 60;
  let durationResult = durationHours ? `${durationHours}h` : '';
  durationResult += durationHours && durationMinutes ? ' ' : '';
  durationResult += durationMinutes ? `${durationMinutes}m` : '';
  return durationResult;
};

export const getDatesBetween = (firstDate: Date, secondDate: Date) => {
  if (!firstDate || !secondDate) {
    return [firstDate, secondDate].filter((item) => item);
  }

  const [startDate, endDate] = firstDate < secondDate ? [firstDate, secondDate] : [secondDate, firstDate];

  const days = differenceInDays(endDate, startDate);
  return range(days + 1).map((i) => addDays(startDate, i));
};
