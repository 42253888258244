import React from 'react';
import ReactModal from 'react-modal';

import ModalHeader from './ModalHeader';
import ModalCard from './ModalCard';

export type Props<P extends InstanceProps, T> = Omit<P, keyof InstanceProps> & {
  modal: React.ComponentType<P>;
  children(props: RenderProps<T>): React.ReactNode;
  onClose?: () => any;
  className?: string;
  preventInitialScroll?: boolean;
};

export interface InstanceProps {
  close: () => any;
  className: string;
}

export interface RenderProps<T> {
  open(): any;
}

interface State<T> {
  isOpen: boolean;
}

export default class Modal<P extends InstanceProps, T> extends React.PureComponent<Props<P, T>, State<T>> {
  static Header = ModalHeader;
  static Card = ModalCard;

  state: State<T> = { isOpen: false };

  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false }, this.props.onClose);

  render() {
    const { modal: Component, children, preventInitialScroll, ...rest } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <ReactModal
          isOpen={isOpen}
          className="relative my-auto mx-0"
          htmlOpenClassName={!preventInitialScroll ? 'overflow-hidden' : ''}
          bodyOpenClassName={!preventInitialScroll ? 'overflow-hidden' : ''}
          overlayClassName="fixed inset-0 inline-flex justify-center bg-black bg-opacity-50 transition-colors ease-in-out duration-300 overflow-auto z-10"
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={this.close}
        >
          <div className="my-auto mx-0">
            {/* @ts-ignore */}
            <Component {...rest} close={this.close} />
          </div>
        </ReactModal>

        {children({ open: this.open })}
      </React.Fragment>
    );
  }
}
