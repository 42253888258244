import { Airport, CreateAirport, UpdateAirport } from '@flyblack/common/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getAirports = () => authorizedApi.get<Airport[]>(`/v1/airports`);

export const createAirport = (airport: CreateAirport) => authorizedApi.post(`/v1/airports`, airport);

export const updateAirport = (airportId: number, airport: UpdateAirport) =>
  authorizedApi.put(`/v1/airports/${airportId}`, airport);

export const deleteAirport = (airportId: number) => authorizedApi.delete(`/v1/airports/${airportId}`);
