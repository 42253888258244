import { api } from '@/services/network/api';

import { CLIENT_ID } from '@/config';

import { Session, ResetPassword, TokenValidity } from '@flyblack/common/domains/session';

const mapSession: (session: any) => Session = (session) => {
  return {
    accessToken: session['access_token'],
    refreshToken: session['refresh_token'],
    idToken: session['id_token']
  };
};

export const signInWithEmail: (email: string, password: string) => Promise<Session> = (email, password) => {
  const authData = new URLSearchParams();

  authData.append('client_id', CLIENT_ID);
  authData.append('grant_type', 'password');
  authData.append('scope', 'trust');
  authData.append('username', email);
  authData.append('password', password);

  return api
    .post<any>(`/v1/oauth/token`, authData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      sentry: { status: [401] }
    })
    .then(mapSession);
};

export const renewToken: (refreshToken: string) => Promise<Session> = (refreshToken) => {
  const authData = new URLSearchParams();

  authData.append('client_id', CLIENT_ID);
  authData.append('grant_type', 'refresh_token');
  authData.append('scope', 'trust');
  authData.append('refresh_token', refreshToken);

  return api
    .post<any>(`/v1/oauth/token`, authData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      sentry: { status: [401] }
    })
    .then(mapSession);
};

export const sendResetPasswordLink: (email: string) => Promise<any> = (email) =>
  api.post<any>(
    `/v1/users/password-token`,
    { email },
    {
      headers: { 'Content-Type': 'application/json' },
      params: { clientId: CLIENT_ID }
    }
  );

export const checkResetPasswordTokenValidity: (token: string) => Promise<TokenValidity> = (token) =>
  api.post<TokenValidity>(
    `/v1/users/password-token-validation`,
    { token },
    {
      headers: { 'Content-Type': 'application/json' },
      params: { clientId: CLIENT_ID }
    }
  );

export const resetPassword: (password: ResetPassword) => Promise<any> = (password) =>
  api.put<any>(`/v1/users/password`, password, {
    headers: { 'Content-Type': 'application/json' },
    params: { clientId: CLIENT_ID }
  });
