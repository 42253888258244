import { generatePath } from 'react-router';

export const home = `/`;

export const signIn = `/login`;

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`
};

export const membership = {
  base: '/membership',
  detail: {
    base: '/membership/:id',
    get: (id: number) => generatePath('/membership/:id', { id })
  }
};

export const vendors = {
  base: '/vendors',
  detail: {
    base: `/vendors/:id`,
    get: (id: number) => generatePath(`/vendors/:id`, { id })
  },
  seller: {
    base: '/vendors/:vendorId/sellers/:sellerId',
    get: (vendorId: number, sellerId: number) =>
      generatePath(`/vendors/:vendorId/sellers/:sellerId`, { vendorId, sellerId })
  }
};

export const shuttles = {
  base: '/shuttles',
  create: '/shuttles/new',
  detail: {
    base: `/shuttles/:id`,
    get: (id: number) => generatePath(`/shuttles/:id`, { id })
  }
};

export const charters = {
  base: '/charters',
  detail: {
    base: `/charters/:id`,
    get: (id: number) => generatePath(`/charters/:id`, { id })
  }
};

export const overview = {
  base: '/overview'
};

export const settings = {
  base: '/settings',
  pushNotifications: {
    base: '/settings/notifications'
  },
  promoCodes: {
    base: '/settings/promos'
  },
  cities: {
    base: '/settings/cities'
  },
  airports: {
    base: '/settings/airports'
  }
};
