import loadGoogleMapsApi from 'load-google-maps-api';

import { GOOGLE_API_KEY } from '@/config';
import { ip } from '@/services/ip';

type ExtractReturnType<T> = T extends (...args: any[]) => infer R ? R : never;
type ExtractPromise<T> = T extends Promise<infer R> ? R : never;

type GoogleMaps = ExtractPromise<ExtractReturnType<typeof loadGoogleMapsApi>>;

interface LocationName {
  shortName: string;
  longName: string;
}

export interface Location {
  city: LocationName;
  country: LocationName;
  placeId: string;
}

export interface Prediction {
  placeId: string;
  name: string;
}

class GoogleService {
  private promise: Promise<GoogleMaps> | null = null;

  private initialize = () => {
    if (this.promise) return this.promise;

    this.promise = loadGoogleMapsApi({ key: GOOGLE_API_KEY, libraries: ['places'] });

    return this.promise;
  };

  private getAddressComponent = (components: any[], type: string) =>
    components.find((component) => component.types.indexOf(type) >= 0);

  private convertResultToStateCountry = (result: any): Location | null => {
    const cityComponent = this.getAddressComponent(result.address_components, 'locality');
    const countryComponent = this.getAddressComponent(result.address_components, 'country');

    if (!cityComponent || !countryComponent) return null;

    return {
      city: {
        shortName: cityComponent.short_name,
        longName: cityComponent.long_name
      },
      country: {
        shortName: countryComponent.short_name,
        longName: countryComponent.long_name
      },
      placeId: result.place_id
    };
  };

  getLocationsFromAddress = (address: string): Promise<Location[]> => {
    return this.initialize().then((service) => {
      return new Promise<Location[]>((resolve, reject) => {
        const geocoder = new service.Geocoder();

        geocoder.geocode({ address }, (results: any[], status: string) => {
          if (status !== 'OK') return reject(new Error(status));

          const locations = results.map(this.convertResultToStateCountry).filter((item) => item) as Location[];

          resolve(locations);
        });
      });
    });
  };

  getLocationsForPlaceId = (placeId: string): Promise<Location[]> => {
    return this.initialize().then((service) => {
      return new Promise<Location[]>((resolve, reject) => {
        const geocoder = new service.Geocoder();

        geocoder.geocode({ placeId }, (results: any[], status: string) => {
          if (status !== 'OK') return reject(new Error(status));

          const locations = results.map(this.convertResultToStateCountry).filter((item) => item) as Location[];

          resolve(locations);
        });
      });
    });
  };

  private getPlacePredictions = (request: google.maps.places.AutocompletionRequest) => {
    return this.initialize().then((service) => {
      return new Promise<Prediction[]>((resolve, reject) => {
        try {
          const autocompleteService = new service.places.AutocompleteService();

          autocompleteService.getPlacePredictions(request, (results, status) => {
            if (status === service.places.PlacesServiceStatus.ZERO_RESULTS) return resolve([]);

            if (status !== service.places.PlacesServiceStatus.OK)
              return reject(new Error(`Unable to autocomplete the location (status=${status}).`));

            const mapAutocompletePrediction = (result: google.maps.places.AutocompletePrediction): Prediction => ({
              name: result.description,
              placeId: result.place_id
            });

            resolve(results.map(mapAutocompletePrediction));
          });
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  getAddressAutocomplete = async (input: string) => {
    const [service, location] = await Promise.all([this.initialize(), ip.get().catch(() => null)]);

    return this.getPlacePredictions({
      input,
      types: ['(cities)'],
      ...(location ? { location: new service.LatLng(location.latitude, location.longitude), radius: 1000 * 1000 } : {})
    });
  };
}

export const google = new GoogleService();
