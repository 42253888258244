import React from 'react';
import { subMonths, addMonths, startOfDay } from 'date-fns';

export const useMonthNavigation = (date?: Date) => {
  const [currentDate, setCurrentDate] = React.useState(date || startOfDay(new Date()));

  const goToPreviousMonth = React.useCallback(() => {
    setCurrentDate((currentDate) => subMonths(currentDate, 1));
  }, []);

  const goToNextMonth = React.useCallback(() => {
    setCurrentDate((currentDate) => addMonths(currentDate, 1));
  }, []);

  return { currentDate, goToPreviousMonth, goToNextMonth };
};
