import { GalleryImage } from '@flyblack/common/domains';

export const roundToTwoDecimals = (number: number): number => Math.round((number + Number.EPSILON) * 100) / 100;

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};

const comparePhotos = (a: GalleryImage, b: GalleryImage) => {
  if (a.type < b.type) {
    return 1;
  }
  if (a.type > b.type) {
    return -1;
  }
  return 0;
};

export const sortPhotos = (photos: GalleryImage[]) => photos.sort(comparePhotos);
