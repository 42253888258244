import React from 'react';

import { google } from '@/services/google';

import CommonLocationSelect, { Props as CommonLocationSelectProps } from '@flyblack/common/components/LocationSelect';

interface Props extends Omit<CommonLocationSelectProps, 'google'> {}

const LocationSelect = ({ ...props }: Props) => {
  return <CommonLocationSelect {...props} google={google} />;
};

export default LocationSelect;
