import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

interface Props {
  formId: string;
  submitting: boolean;
  isBooked?: boolean;
}

const SeatingSection: React.FC<Props> = ({ formId, submitting, isBooked }) => {
  const intl = useIntl();

  return (
    <DetailCard>
      <Typography is="span" type="halcyon" weight="bold">
        <FormattedMessage id={translations.pages.shuttles.detail.sections.seating} />
      </Typography>

      <Spacer xs={4} />

      <div className="flex w-4/5">
        <Form.Field
          is={TextInput}
          id={`${formId}-seats`}
          name="seats"
          type="text"
          label={intl.formatMessage({ id: translations.inputs.numberOfSeats.label })}
          readOnly={submitting}
          leadingIcon="seat"
          disabled
        />

        <div className="w-6" />

        <Form.Field
          is={TextInputWithValidation}
          id={`${formId}-price`}
          name="price"
          type="text"
          label={intl.formatMessage({ id: translations.inputs.price.label })}
          placeholder={intl.formatMessage({ id: translations.inputs.price.placeholder })}
          readOnly={submitting}
          leadingIcon="dollar"
          disabled={isBooked}
        />
      </div>
    </DetailCard>
  );
};

export default SeatingSection;
