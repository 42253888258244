import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { cancelShuttle, deleteShuttle } from '@/services/api/shuttle';

import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  formId: string;
  shuttleId?: number;
  submitting: boolean;
  disabledSubmit: boolean;
  showDelete?: boolean;
  allowDelete?: boolean;
}

const ShuttleDetailButtons: React.FC<Props> = ({
  formId,
  shuttleId,
  submitting,
  disabledSubmit,
  allowDelete,
  showDelete
}) => {
  const history = useHistory();

  const [loadingOnDelete, setLoadingOnDelete] = React.useState(false);

  const deleteJetShuttle = () => {
    setLoadingOnDelete(true);
    return deleteShuttle(shuttleId)
      .then(() => history.push(urls.shuttles.base))
      .catch(() => setLoadingOnDelete(false));
  };

  const cancelJetShuttle = () => {
    setLoadingOnDelete(true);
    return cancelShuttle(shuttleId)
      .then(() => history.push(urls.shuttles.base))
      .catch(() => setLoadingOnDelete(false));
  };

  return (
    <div className="flex justify-end">
      {shuttleId && showDelete && (
        <Button
          appearance="transparent"
          className="w-[200px] mr-6"
          form={formId}
          loading={loadingOnDelete}
          type="button"
          onClick={allowDelete ? deleteJetShuttle : cancelJetShuttle}
        >
          <Icon type="bin" className="pr-2" />

          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.inputs.buttons[allowDelete ? 'deleteShuttle' : 'cancelShuttle']} />
          </Typography>
        </Button>
      )}

      <Button
        onClick={() => history.push(urls.shuttles.base)}
        appearance="ghost"
        className="w-[128px] mr-6"
        form={formId}
        type="button"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons.discardShuttle} />
        </Typography>
      </Button>

      <Button
        appearance="white"
        className="w-[200px]"
        form={formId}
        loading={submitting}
        disabled={disabledSubmit}
        type="submit"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons[shuttleId ? 'updateShuttle' : 'addShuttle']} />
        </Typography>
      </Button>
    </div>
  );
};

export default ShuttleDetailButtons;
