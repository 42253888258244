import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Typography from '@flyblack/common/components/Typography';

const BookingRowItemTableHeader: React.FC = () => {
  return (
    <Typography is="span" type="hummingbird" className="justify-between flex" color="lightGray">
      <div className="pr-1 w-[5%]">
        <FormattedMessage id={translations.modals.passengersModal.passengerList.number} />
      </div>

      <div className="pr-1 w-[15%]">
        <FormattedMessage id={translations.modals.passengersModal.passengerList.numberOfBookedSeats} />
      </div>

      <div className="pr-1 w-[40%]">
        <FormattedMessage id={translations.modals.passengersModal.passengerList.legalName} />
      </div>

      <div className="pr-1 w-[15%]">
        <FormattedMessage id={translations.modals.passengersModal.passengerList.dateOfBirth} />
      </div>

      <div className="pr-1 w-[10%]">
        <FormattedMessage id={translations.modals.passengersModal.passengerList.weight} />
      </div>
    </Typography>
  );
};

export default BookingRowItemTableHeader;
