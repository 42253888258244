import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { PassengerListItem } from '@flyblack/common/domains';

import Typography from '@flyblack/common/components/Typography';

interface Props {
  bookingData?: {
    id: number;
    seats: number;
  };
  passenger: PassengerListItem;
}

const BookingRowItemTableItem: React.FC<Props> = ({ passenger, bookingData }) => {
  const intl = useIntl();

  return (
    <Typography is="span" className="justify-between flex font-light pt-2" type="halcyon">
      <div className="pr-1 w-[5%]">{bookingData && bookingData.id}</div>

      <div className="pr-1 w-[15%]">{bookingData && bookingData.seats}</div>

      <div className="pr-1 w-[40%]">{`${passenger.legalName}${passenger.accountHolder === true ? '*' : ''}`}</div>

      <div className="pr-1 w-[15%]">
        <FormattedDate value={passenger.birthDate} month="2-digit" day="2-digit" year="numeric" timeZone="UTC" />
      </div>

      <div className="pr-1 w-[10%]">
        {passenger.weight
          ? `${passenger.weight.value} ${intl.formatMessage({
              id: translations.domains.weightUnit[passenger.weight.unit]
            })}`
          : '-'}
      </div>
    </Typography>
  );
};

export default BookingRowItemTableItem;
