import { UsersResponse } from '@/domains/users-response';
import { authorizedApi } from '../network/authorized-api';
import { UserDetail } from '@/domains/user-detail';

export const getUsers: (
  page,
  size,
  options?: {
    [x: string]: any;
  }
) => Promise<UsersResponse> = (pageNumber, pageSize, options) =>
  authorizedApi.get(`/v1/admin/users`, { pageNumber, pageSize, ...options });

export const getUser: (id) => Promise<UserDetail> = (id) => authorizedApi.get<UserDetail>(`/v1/admin/users/${id}`);
