import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { getAirports } from '@/services/api/airport';

import useLoad from '@flyblack/common/hooks/useLoad';
import Button from '@flyblack/common/components/Button';
import Container from '@flyblack/common/components/Container';
import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import Loading from '@flyblack/common/components/Loading';
import Table from '@flyblack/common/components/Table';
import Modal from '@flyblack/common/components/Modal';

import AirportTableHeader from './AirportTableHeader';
import AirportTableItem from './AirportTableItem';
import AirportModal from './AirportModal';

import SettingsNavigation from '../SettingsNavigation';

const Airports = () => {
  const { value: airports, loading, reload } = useLoad({
    load: () => getAirports()
  });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex h-14 items-center justify-between">
          <SettingsNavigation />

          <Modal modal={AirportModal} onSuccess={reload}>
            {({ open }) => (
              <Button appearance="white" onClick={open}>
                <Icon type="add" className="pr-2" />

                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.airports.addAirport} />
                </Typography>
              </Button>
            )}
          </Modal>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible center className="w-full h-[612px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <AirportTableHeader />

            <Table.Body>
              {!loading && airports.map((item) => <AirportTableItem key={item.id} data={item} onAction={reload} />)}
            </Table.Body>
          </Table>
        )}

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default Airports;
