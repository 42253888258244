import React, { useState } from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Button from '@flyblack/common/components/Button';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';
import { CharterEnquireStatus } from '@/domains/charter-enquire';
import { deleteCharterEnquires, updateCharterEnquiresStatus } from '@/services/api/charter-enquire';

interface Props {
  charterEnquireId: number;
  status: CharterEnquireStatus;
  onAction?: () => void;
}

export enum ModalType {
  DELETE,
  STATUS
}

const CharterEnquireTableItemMenu = ({ charterEnquireId, onAction, status }: Props) => {
  const intl = useIntl();

  const [modalType, setModalType] = useState<ModalType>();

  const handleModalConfirmation = () => {
    if (ModalType.DELETE === modalType) {
      return deleteCharterEnquires(charterEnquireId).then(() => onAction && onAction());
    }

    const newStatus = CharterEnquireStatus.Open === status ? CharterEnquireStatus.Closed : CharterEnquireStatus.Open;
    return updateCharterEnquiresStatus(charterEnquireId, newStatus).then(() => onAction && onAction());
  };

  const handleActionSelect = (openModal: () => void, modalType: ModalType) => {
    setModalType(modalType);
    openModal();
  };

  return (
    <Modal
      modal={ConfirmationModal}
      title={
        modalType === ModalType.DELETE
          ? intl.formatMessage({ id: translations.modals.confirmationModals.deleteThis })
          : intl.formatMessage({ id: translations.modals.confirmationModals.status })
      }
      confirmMessage={intl.formatMessage({ id: translations.modals.confirmationModals.yes })}
      cancelMessage={intl.formatMessage({ id: translations.modals.confirmationModals.no })}
      onConfirm={handleModalConfirmation}
    >
      {({ open: openModal }) => (
        <Menu as="div" className="ml-3 relative">
          {({ open }) => (
            <>
              <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
                <Icon type="more" appearance="white" className="cursor-pointer" />
              </Menu.Button>
              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 rounded-sm shadow-lg bg-flyblack-gray focus:outline-none z-10"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        onClick={() => handleActionSelect(openModal, ModalType.DELETE)}
                        appearance="transparent"
                        className={classNames(
                          active && 'bg-white bg-opacity-[32%] rounded-sm',
                          'block px-4 py-2 text-sm text-white w-full'
                        )}
                      >
                        <FormattedMessage id={translations.inputs.buttons.delete} />
                      </Button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        onClick={() => handleActionSelect(openModal, ModalType.STATUS)}
                        appearance="transparent"
                        className={classNames(
                          active && 'bg-white bg-opacity-[32%] rounded-sm',
                          'block px-4 py-2 text-sm text-white w-full'
                        )}
                      >
                        {CharterEnquireStatus.Open === status ? (
                          <FormattedMessage id={translations.inputs.buttons.solved} />
                        ) : (
                          <FormattedMessage id={translations.inputs.buttons.reopen} />
                        )}
                      </Button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </Modal>
  );
};

export default CharterEnquireTableItemMenu;
