import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {}

const PromoCodesTableHeader: React.FC<Props> = () => {
  const text = translations.pages.promoCodes.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] justify-between font-bold">
      <Table.Data className="pr-1 w-[15%]" header>
        <FormattedMessage id={text.code} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.discount} />
      </Table.Data>

      <Table.Data className="pr-1 w-[15%]" header>
        <FormattedMessage id={text.createdAt} />
      </Table.Data>

      <Table.Data className="pr-1 w-[15%]" header>
        <FormattedMessage id={text.expiresAt} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]" header>
        <FormattedMessage id={text.useCount} />
      </Table.Data>

      <Table.Data className="w-4" header />
    </Typography>
  );
};

export default PromoCodesTableHeader;
