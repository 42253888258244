import React from 'react';

import ValidationMessage, { ValidationError } from '@flyblack/common/components/ValidationMessage';

export interface Props {}

interface ExtraProps {
  invalid?: boolean;
  error?: ValidationError | null;
  messageClassName?: string;
}

export const withValidation = <P extends Props>(Component: React.ComponentType<any>, staticProps?: Partial<Props>) =>
  class WithValidation extends React.PureComponent<P & ExtraProps> {
    render() {
      const { invalid, error, messageClassName, ...rest } = this.props;

      const normalizedError = error || ({} as any);

      return (
        <div>
          <Component invalid={!!error} {...staticProps} {...rest} />

          <ValidationMessage
            visible={!!invalid}
            id={normalizedError.id}
            values={normalizedError.values}
            className={messageClassName}
          />
        </div>
      );
    }
  };
