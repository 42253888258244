import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { CharterStatus } from '@/domains/charter-status';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import CharterStatusSelect from '@/components/CharterStatusSelect';

const CharterStatusSelectWithValidation = withValidation(CharterStatusSelect);

const charterStatusToBgColorMap: Record<CharterStatus, string> = {
  [CharterStatus.Archived]: 'bg-[#525252]',
  [CharterStatus.Booked]: 'bg-[#0F5630]',
  [CharterStatus.Canceled]: 'bg-[#751C1C]',
  [CharterStatus.Confirmed]: 'bg-[#0F5630]',
  [CharterStatus.Booked]: 'bg-[#0F5630]',
  [CharterStatus.WaitingApproval]: 'bg-[#6D521D]',
  [CharterStatus.WaitingPayment]: 'bg-[#6D521D]'
};

interface Props {
  formId: string;
  submitting: boolean;
  disabledSubmit: boolean;
  showStatus?: boolean;
  allowStatusChange?: boolean;
  initialStatus?: CharterStatus;
  selectedStatus?: CharterStatus;
}

const CharterDetailButtons: React.FC<Props> = ({
  formId,
  submitting,
  disabledSubmit,
  showStatus,
  allowStatusChange,
  initialStatus,
  selectedStatus
}) => {
  const history = useHistory();

  return (
    <div className="flex justify-end gap-6">
      {showStatus &&
        ([
          CharterStatus.WaitingApproval,
          CharterStatus.WaitingPayment,
          CharterStatus.Canceled,
          CharterStatus.Booked
        ].includes(initialStatus) ? (
          <div className={classnames('px-3', charterStatusToBgColorMap[selectedStatus])}>
            <Form.Field
              is={CharterStatusSelectWithValidation}
              type="text"
              id={`${formId}-status`}
              name="status"
              readOnly={submitting}
              disabled={allowStatusChange}
              className={charterStatusToBgColorMap[selectedStatus]}
              inputClassName="h-12 border-b-0"
              includeBookedStatus={initialStatus === CharterStatus.Booked}
            />
          </div>
        ) : (
          <div
            className={classnames(
              charterStatusToBgColorMap[initialStatus],
              'px-4 py-1 flex w-[227px] justify-center items-center rounded-sm'
            )}
          >
            <Typography is="span" type="hummingbird">
              <FormattedMessage id={translations.domains.charterStatus[initialStatus]} />
            </Typography>
          </div>
        ))}

      <Button
        onClick={() => history.push(urls.charters.base)}
        appearance="ghost"
        className="w-[128px]"
        form={formId}
        type="button"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons.discardCharter} />
        </Typography>
      </Button>

      <Button
        appearance="white"
        className="w-[200px]"
        form={formId}
        loading={submitting}
        disabled={disabledSubmit}
        type="submit"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons.saveCharter} />
        </Typography>
      </Button>
    </div>
  );
};

export default CharterDetailButtons;
