import React from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import { translations } from '@/locale';

import TextInput from '@flyblack/common/components/Form/Input/TextInput';

interface Props {
  id: string;
  onChange?: (value: string) => void;
}

const SearchByIdInput = ({ id, onChange }: Props) => {
  const intl = useIntl();

  const debouncedOnChange = onChange && debounce(onChange, 300);

  const handleChange = (event) => onChange && debouncedOnChange(event.target.value);

  return (
    <TextInput
      id={id}
      type="number"
      min={1}
      leadingIcon="search"
      onChange={handleChange}
      label={intl.formatMessage({
        id: translations.inputs.searchById.label
      })}
      placeholder={intl.formatMessage({
        id: translations.inputs.searchById.placeholder
      })}
    />
  );
};

export default SearchByIdInput;
