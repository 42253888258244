import React from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { deleteCity } from '@/services/api/city';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Button from '@flyblack/common/components/Button';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';

interface Props {
  cityId: number;
  onAction?: () => any;
}

const CityTableItemMenu = ({ cityId, onAction }: Props) => {
  const intl = useIntl();

  const onDelete = () => deleteCity(cityId).then(() => onAction && onAction());

  return (
    <Modal
      modal={ConfirmationModal}
      title={intl.formatMessage({ id: translations.modals.confirmationModals.deleteCityTitle })}
      message={intl.formatMessage({ id: translations.modals.confirmationModals.deleteCityMessage })}
      confirmMessage={intl.formatMessage({ id: translations.modals.confirmationModals.yes })}
      cancelMessage={intl.formatMessage({ id: translations.modals.confirmationModals.no })}
      onConfirm={onDelete}
      className="w-[500px]"
    >
      {({ open: openModal }) => (
        <Menu as="div" className="ml-3 relative">
          {({ open }) => (
            <>
              <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
                <Icon type="more" appearance="white" className="cursor-pointer" />
              </Menu.Button>
              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 rounded-sm shadow-lg bg-flyblack-gray focus:outline-none"
                >
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        onClick={openModal}
                        appearance="transparent"
                        className={classNames(
                          active && 'bg-white bg-opacity-[32%] rounded-sm',
                          'block px-4 py-2 text-sm text-white w-full'
                        )}
                      >
                        <FormattedMessage id={translations.inputs.buttons.delete} />
                      </Button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </Modal>
  );
};

export default CityTableItemMenu;
