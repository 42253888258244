import React from 'react';
import MaskedInput from 'react-text-mask';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { parse as parseFns, format as formatFns, isValid } from 'date-fns';

import TextInput, { Props as TextInputProps } from '@flyblack/common/components/Form/Input/TextInput';

export const dateFormat = 'yyyy-MM-dd';

const generateMaskFromFormat = (format: string) => {
  const letterPattern = /[a-zA-Z]/;
  return format.split('').map((key) => (letterPattern.test(key) ? /\d/ : key));
};

const parse = (date: any, format: string) => {
  if (!date.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) return null;

  date = parseFns(date, format, new Date());
  return isValid(date) ? formatFns(date, dateFormat) : null;
};

export interface Props extends Omit<TextInputProps, 'value' | 'onChange'> {
  format?: string;
  value?: string;
  onChange?: (event: string) => void;
}

const DateInput: React.SFC<Props> = (props) => {
  const format = 'MM/dd/yyyy';

  const date = parseFns(props.value, dateFormat, new Date());

  const value = props.value && isValid(date) ? formatFns(date, format) : props.value;

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe(format.toLowerCase());

  return (
    <TextInput
      is={MaskedInput}
      // @ts-ignore
      mask={generateMaskFromFormat(format)}
      guide={false}
      keepCharPositions
      pipe={autoCorrectedDatePipe}
      placeholder={format}
      {...props}
      value={value}
      onChange={(event) => {
        const date = parse(event.target.value, format);

        if (props.onChange) return props.onChange(date || event.target.value);
      }}
      onBlur={(event) => {
        const date = parse(event.target.value, format);

        if (props.onBlur) props.onBlur(event);
        if (props.onChange) return props.onChange(date);
      }}
    />
  );
};

export default DateInput;
