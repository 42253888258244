import React from 'react';
import { urls } from '@/constants';
import { useHistory } from 'react-router';
import { FormatDateOptions, useIntl } from 'react-intl';

import { JetShuttleDetail, JetShuttleStatus } from '@flyblack/common/domains';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

import ShuttleTableItemMenu from './ShuttleTableItemMenu';

export interface Props {
  data: JetShuttleDetail;
  onAction: () => any;
}

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true
};

const ShuttleTableItem: React.FC<Props> = ({ data, onAction }) => {
  const history = useHistory();

  const intl = useIntl();

  return (
    <Typography
      is={Table.Row}
      onClick={() => history.push(urls.shuttles.detail.get(data.id))}
      className="justify-between cursor-pointer font-light h-20 px-6 odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
    >
      <Table.Data className="pr-1 w-28">{`${data.aircraftCategory.jetShuttleSeats - data.availableSeats}/${
        data.aircraftCategory.jetShuttleSeats
      }`}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${data.sourceAirport.displayCode} ${data.sourceAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[15%]">{intl.formatDate(data.schedule.departsAt, formatDateOptions)}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${data.destinationAirport.displayCode} ${data.destinationAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[15%]">{intl.formatDate(data.schedule.arrivesAt, formatDateOptions)}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{data.aircraftCategory.name}</Table.Data>

      <Table.Data className="pr-1 w-[10%]"> {data.aircraftType ? data.aircraftType.name : 'TBA'}</Table.Data>

      <Table.Data className="pr-1 w-24 justify-end">{`${data.price.symbol}${Number(
        data.price.value
      ).toLocaleString()}`}</Table.Data>

      <Table.Data className="w-4 justify-end" stopPropagation>
        <ShuttleTableItemMenu
          jetDealId={data.id}
          isBooked={[
            JetShuttleStatus.Booked,
            JetShuttleStatus.PartiallyBooked,
            JetShuttleStatus.PendingBooking
          ].includes(data.status)}
          onAction={onAction}
        />
      </Table.Data>
    </Typography>
  );
};

export default ShuttleTableItem;
