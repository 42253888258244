import React from 'react';
import classnames from 'classnames';

import Icon from '@flyblack/common/components/Icon';

export interface Props {
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => any;
}

const SelectItem: React.SFC<Props> = ({ active, selected, disabled, className, onClick, children, ...rest }) => (
  <li
    {...rest}
    className={classnames(
      'relative block h-12 w-full py-3 pl-3 pr-9 text-white truncate cursor-pointer',
      active || selected ? 'bg-white bg-opacity-[32%]' : 'bg-flyblack-gray',
      { 'cursor-default': disabled },
      className
    )}
    onClick={disabled ? null : onClick}
  >
    {children}

    {selected && <Icon type="check" className="absolute right-3" />}
  </li>
);

export default SelectItem;
