import { FlightCodeType, MembershipCodeType } from '.';

export enum PaymentMethodType {
  ACH = 'ACH',
  Card = 'CARD'
}

export enum CarbonOffsetState {
  Included = 'INCLUDED',
  NotIncluded = 'NOT_INCLUDED'
}

export enum TypeOfProduct {
  EmptyLeg = 'EMPTY_LEG',
  JetShuttle = 'JET_SHUTTLE',
  JetCharter = 'JET_CHARTER',
  Membership = 'MEMBERSHIP'
}

export enum PaymentResponseStatus {
  FreeFlight = 'FREE_FLIGHT',
  PaidFlight = 'PAID_FLIGHT',
  PaidMembership = 'PAID_MEMBERSHIP'
}

export interface PaymentMethod {
  id: string;
  last4: string;
  type: PaymentMethodType;
  default: boolean;
}

export interface ACH extends PaymentMethod {
  bankName: string;
  holderName: string;
  holderType: string;
}

export type CreditCardBrand =
  | 'amex'
  | 'cartes_bancaires'
  | 'diners'
  | 'discover'
  | 'jcb'
  | 'mastercard'
  | 'unionpay'
  | 'visa';

export interface Card extends PaymentMethod {
  address: {
    city: string;
    country: string;
    state: string;
    zipCode: string;
  };
  brand: CreditCardBrand;
  expMonth: number;
  expYear: number;
}

export interface PaymentMethodResponse {
  achs: ACH[];
  cards: Card[];
}

export interface PaymentIntentResponse {
  clientSecret: string;
  flightStatus: PaymentResponseStatus;
  emptyLegId: number;
  userId: number;
}

export interface PaymentChargeRequest {
  productId: number;
  typeOfProduct: TypeOfProduct;
  payCarbonOffset?: boolean;
  seats?: number;
  bankAccountId?: string;
  code?: string;
  discountType?: FlightCodeType | MembershipCodeType;
}
