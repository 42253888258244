import React from 'react';
import classnames from 'classnames';

import Card from '@flyblack/common/components/Card';
import Month from '@flyblack/common/components/Calendar/Month';
import MonthNavigation from '@flyblack/common/components/Calendar/MonthNavigation';
import { useMonthNavigation } from '@flyblack/common/components/Calendar/useMonthNavigation';

export interface Props {
  date?: Date;
  selectDate: (date: Date) => any;
  className?: string;
}

const Datepicker = ({ date, selectDate, className }: Props) => {
  const { currentDate, goToPreviousMonth, goToNextMonth } = useMonthNavigation(date);

  return (
    <Card className={classnames('flex justify-center rounded-[2px]', className)} bg="white">
      <div className="px-6 py-3 flex flex-col justify-between">
        <div className="w-[308px] flex justify-between items-center pb-3">
          <MonthNavigation
            currentDate={currentDate}
            onGoToPreviousMonth={goToPreviousMonth}
            onGoToNextMonth={goToNextMonth}
          />
        </div>

        <div className="w-[308px]">
          <Month
            year={currentDate.getFullYear()}
            month={currentDate.getMonth()}
            selectedDate={date}
            onSelectDate={selectDate}
          />
        </div>
      </div>
    </Card>
  );
};

export default Datepicker;
