import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useIntl } from 'react-intl';

import { getVendors } from '@/services/api/vendors';

import useLoad from '@flyblack/common/hooks/useLoad';
import Spacer from '@flyblack/common/components/Spacer';
import Container from '@flyblack/common/components/Container';
import BackButton from '@flyblack/common/components/BackButton';
import TextWithLabel from '@flyblack/common/components/TextWithLabel';

import { translations } from '@/locale';

export interface Props {}

interface Params {
  id: string;
}

const VendorDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const intl = useIntl();

  const vendorId = parseInt(match.params.id);

  const { value: vendorData, loading } = useLoad({
    load: () => getVendors().then((response) => response.filter((item) => item.id === vendorId)[0])
  });

  return (
    !loading && (
      <div className="min-h-full py-10">
        <Container max className="flex flex-col">
          <div>
            <BackButton />
          </div>

          <Spacer xs={5} />

          <div className="flex">
            <div className="flex flex-col w-96">
              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.companyName })}
                text={vendorData.name}
              />
            </div>

            <div className="flex flex-col ml-5">
              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.address })}
                text={vendorData.address || intl.formatMessage({ id: translations.pages.vendors.detail.valueNotSet })}
              />

              <Spacer xs={8} />

              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.name })}
                text={
                  (vendorData.users[0] &&
                    vendorData.users[0].firstName &&
                    vendorData.users[0].lastName &&
                    `${vendorData.users[0].firstName} ${vendorData.users[0].lastName}`) ||
                  intl.formatMessage({ id: translations.pages.vendors.detail.valueNotSet })
                }
              />

              <Spacer xs={4} />

              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.email })}
                text={vendorData.users[0] && vendorData.users[0].email}
              />

              <Spacer xs={4} />

              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.phoneNumber })}
                text={
                  (vendorData.users[0] && vendorData.users[0].phoneNumber) ||
                  intl.formatMessage({ id: translations.pages.vendors.detail.valueNotSet })
                }
              />

              <Spacer xs={4} />

              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.bankName })}
                text={
                  (vendorData.paymentInfo && vendorData.paymentInfo.bankName) ||
                  intl.formatMessage({ id: translations.pages.vendors.detail.valueNotSet })
                }
              />

              <Spacer xs={4} />

              <TextWithLabel
                label={intl.formatMessage({ id: translations.pages.vendors.detail.bankAccount })}
                text={
                  (vendorData.paymentInfo && vendorData.paymentInfo.account) ||
                  intl.formatMessage({ id: translations.pages.vendors.detail.valueNotSet })
                }
              />
            </div>
          </div>
        </Container>

        <Spacer xs={10} />
      </div>
    )
  );
};

export default VendorDetail;
