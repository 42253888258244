export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 6,
        boxHeight: 6,
        padding: 32,
        color: '#FFFFFF',
        font: {
          size: 16
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        color: 'rgba(0,0,0,0)',
        borderColor: '#3D3D3D'
      },
      ticks: {
        color: '#DDDDDD',
        padding: 8,
        font: {
          size: 16
        }
      }
    },
    y: {
      grid: {
        color: '#1A1A1A',
        borderColor: 'rgba(0,0,0,0)'
      },
      ticks: {
        color: '#DDDDDD',
        font: {
          size: 16
        },
        callback: function(value: any) {
          return `${value / 1000}K`;
        }
      }
    }
  }
};
