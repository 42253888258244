import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { CityStatus } from '@flyblack/common/domains';
import { updateCityStatus } from '@/services/api/city';

import LiveSubmit from '@flyblack/common/components/LiveSubmit';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';
import Loading from '@flyblack/common/components/Loading';
import Modal from '@flyblack/common/components/Modal';
import ConfirmationModal from '@flyblack/common/components/ConfirmationModal';

export interface Props {
  cityId: number;
  status?: CityStatus;
  onStatusChange: () => any;
}

const CityTableItemStatus = ({ cityId, status, onStatusChange }: Props) => {
  const intl = useIntl();

  const onCityStatusChange = () =>
    updateCityStatus(cityId, status === CityStatus.Unavailable ? CityStatus.Available : CityStatus.Unavailable).then(
      onStatusChange
    );

  return status ? (
    <Modal
      modal={ConfirmationModal}
      title={intl.formatMessage({
        id:
          translations.modals.confirmationModals.cityStatusChangeTitle[
            status === CityStatus.Available ? CityStatus.Unavailable : CityStatus.Available
          ]
      })}
      confirmMessage={intl.formatMessage({ id: translations.modals.confirmationModals.yes })}
      cancelMessage={intl.formatMessage({ id: translations.modals.confirmationModals.no })}
      onConfirm={onCityStatusChange}
    >
      {({ open }) => (
        <LiveSubmit
          value={{ fieldState: status === CityStatus.Available }}
          onChange={() => {
            open();
            return Promise.reject();
          }}
        >
          {({ loading, value: { fieldState }, set }) => (
            <div className="flex">
              <ToggleSwitch disabled={loading} checked={fieldState} onClick={() => set('fieldState', !fieldState)} />
              {loading && (
                <Loading visible={true} className="ml-2">
                  <Loading.Indicator size={20} borderWidth={2} />
                </Loading>
              )}
            </div>
          )}
        </LiveSubmit>
      )}
    </Modal>
  ) : null;
};

export default CityTableItemStatus;
