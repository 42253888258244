import React from 'react';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getShuttles } from '@/services/api/shuttle';
import { By, Order, ShuttlesFilter, Sort } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Link from '@flyblack/common/components/Link';
import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import usePagination from '@flyblack/common/hooks/usePagination';
import PageSelector from '@flyblack/common/components/PageSelector';
import SearchByIdInput from '@flyblack/common/components/SearchByIdInput';

import ShuttleTableItem from './ShuttleTableItem';
import ShuttleTableHeader from './ShuttleTableHeader';

export interface Props {}

const orderingOptionsByFilter: Record<ShuttlesFilter, any> = {
  [ShuttlesFilter.Upcoming]: { [Sort.Order]: Order.Ascendent },
  [ShuttlesFilter.Past]: { [Sort.Order]: Order.Descendent }
};

const Shuttles: React.FC<RouteComponentProps> = ({ history, location }) => {
  const urlFilter = queryString.parse(location.search).type || ShuttlesFilter.Upcoming;

  const [selectedFilter, setSelectedFilter] = React.useState((urlFilter as string) || ShuttlesFilter.Upcoming);
  const [searchedId, setSearchedId] = React.useState<number>();

  const { loading, data: shuttles, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size) => {
      return getShuttles(page, size, {
        ...(selectedFilter === ShuttlesFilter.Upcoming ? { after: new Date().toISOString() } : null),
        ...(selectedFilter === ShuttlesFilter.Past ? { before: new Date().toISOString() } : null),
        [Sort.By]: By.DepartureDate,
        ...orderingOptionsByFilter[selectedFilter],
        ...(searchedId ? { ids: [searchedId] } : null)
      });
    }
  });

  React.useEffect(() => {
    !loading && reload();
    selectedFilter !== urlFilter &&
      history.push(queryString.stringifyUrl({ url: urls.shuttles.base, query: { type: selectedFilter } }));
  }, [selectedFilter]);

  React.useEffect(() => {
    setSelectedFilter(urlFilter as string);
  }, [urlFilter]);

  React.useEffect(() => {
    reload();
  }, [searchedId]);

  const onSearchedIdChange = (value: string) => setSearchedId(parseInt(value));

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex h-14 items-center justify-between">
          <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-md space-x-1">
            {Object.values(ShuttlesFilter).map((item) => (
              <Button
                key={item}
                type="button"
                appearance={selectedFilter === item ? 'white' : 'transparent'}
                className="w-[160px]"
                onClick={() => setSelectedFilter(item)}
                disabled={loading}
              >
                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.domains.shuttlesFilter[item]} />
                </Typography>
              </Button>
            ))}
          </div>

          <div className="flex items-center gap-10">
            <SearchByIdInput id="shuttleSearch" onChange={onSearchedIdChange} />

            <Button onClick={() => history.push(urls.shuttles.create)} appearance="white">
              <Icon type="add" className="pr-2" />

              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.pages.shuttles.addShuttle} />
              </Typography>
            </Button>
          </div>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible={true} center className="w-full h-[612px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <ShuttleTableHeader />

            <Table.Body>
              {!loading && shuttles.map((item) => <ShuttleTableItem key={item.id} data={item} onAction={reload} />)}
            </Table.Body>
          </Table>
        )}

        <Spacer xs={3} />

        <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default Shuttles;
