import React from 'react';

import Select, { Props as SelectProps } from '@flyblack/common/components/Select';

export interface Props<T>
  extends Omit<SelectProps<T>, 'onSelect' | 'onInputValueChange' | 'getValue' | 'getItemValue' | 'renderItems'> {
  label?: string;
  onInputValueChange?: (...params) => any;
}

const SimpleSelect: React.FC<Props<any>> = ({
  onInputValueChange = () => null,
  value,
  inputClassName,
  onChange,
  ...rest
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      onSelect={() => null}
      inputClassName={inputClassName}
      {...rest}
    />
  );
};

export default SimpleSelect;
