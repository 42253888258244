import React from 'react';
import classnames from 'classnames';

import { getStylesWithSize } from '@flyblack/common/util';
import { WithSize, Magnitude } from '@flyblack/common/constants';

// @ts-ignore
import style from './Column.sass';

export interface Props extends WithSize<Magnitude> {
  padded?: boolean;
  right?: boolean;
  className?: string;
}

const Column: React.FC<Props> = ({ padded = true, right, className, children, ...props }) => {
  return (
    <div
      className={classnames(
        getStylesWithSize(
          (size) => (style as any)[`${size}${props[size] || 0}`],
          (size) => props[size] != null
        ),
        {
          'p-5': padded,
          'order-[100]': right
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Column;
