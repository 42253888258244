import { City, CityStatus, Page } from '@flyblack/common/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const getCities = (pageNumber: number, pageSize: number, options?: { [x: string]: any }) =>
  authorizedApi.get<Page<City>>(`/v1/cities`, { pageNumber, pageSize, ...options });

export const updateCityStatus = (cityId: number, status: CityStatus) =>
  authorizedApi.put(`/v1/cities/${cityId}`, { status });

export const createCity = (placeId: string) => authorizedApi.post(`/v1/cities`, { placeId });

export const deleteCity = (cityId: number) => authorizedApi.delete(`/v1/cities/${cityId}`);
