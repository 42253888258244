import React from 'react';
import { FormatDateOptions, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { UserDetail } from '@/domains/user-detail';
import { approveMembership } from '@/services/api/membership';

import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import RejectMembershipApplicationModal from './RejectMembershipApplicationModal';

import TruncatedTableDataContent from './TruncatedTableDataContent';

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

export interface Props {
  data: UserDetail;
  onClick: (event: MouseEvent) => any;
  onAction?: () => any;
  isApprovable?: boolean;
}

const PendingTableItem: React.FC<Props> = ({ data, onClick, onAction, isApprovable }) => {
  const intl = useIntl();

  return (
    <Typography
      is={Table.Row}
      className="justify-between font-light h-20 px-6 cursor-pointer odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
      onClick={onClick}
    >
      <Table.Data className="pr-1 w-[30%]">
        <TruncatedTableDataContent>{`${data.firstName} ${data.lastName}`}</TruncatedTableDataContent>
      </Table.Data>

      <Table.Data className="pr-1 w-[40%]">
        <TruncatedTableDataContent>{data.email}</TruncatedTableDataContent>
      </Table.Data>

      <Table.Data className="pr-1 w-[15%]">
        {data.membership && intl.formatDate(data.membership.createdAt, formatDateOptions)}
      </Table.Data>

      <Table.Data className="pr-1 w-[15%] flex justify-between" stopPropagation>
        {data.membership && isApprovable && (
          <React.Fragment>
            <Modal modal={RejectMembershipApplicationModal} id={data.membership.id} onSuccess={onAction}>
              {({ open }) => (
                <Button appearance="ghost" onClick={open}>
                  <FormattedMessage id={translations.pages.membership.table.button.decline} />
                </Button>
              )}
            </Modal>

            <Button
              appearance="white"
              onClick={() => approveMembership(data.membership.id).then(() => onAction && onAction())}
            >
              <FormattedMessage id={translations.pages.membership.table.button.accept} />
            </Button>
          </React.Fragment>
        )}
      </Table.Data>
    </Typography>
  );
};

export default PendingTableItem;
