import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';

export interface Props extends RouteComponentProps {
  is?: React.ElementType;
  className?: string;
  hoverable?: boolean;
  url?: string;
  isExternalUrl?: boolean;
}

const handleClick = (url, isExternalUrl, history) => {
  url ? (isExternalUrl ? window.open(url, '_blank') : history.push(url)) : null;
};

const LinkRow: React.FC<Props> = ({
  is: Component = 'tr',
  className,
  hoverable,
  url,
  isExternalUrl,
  staticContext,
  history,
  children,
  ...rest
}) => (
  <Component
    className={classnames(
      'rounded-sm flex flex-row p-6 text-white  bg-black  even:bg-flyblack-gray ',
      hoverable && 'hover:bg-flyblack-dark-gray',
      url && 'cursor-pointer',
      className
    )}
    onClick={() => handleClick(url, isExternalUrl, history)}
    {...rest}
  >
    {children}
  </Component>
);

export default withRouter(LinkRow);
