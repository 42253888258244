import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { urls } from '@/constants';

import LogoLink from '@flyblack/common/components/LogoLink';
import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import SignInPage from './SignInPage';
import ResetPassword from './ResetPassword';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpired';

const Unauthorized: React.FC = () => (
  <div className="flex flex-col h-full w-full">
    <LogoLink className="mt-8 ml-8 h-5 w-auto" />

    <div className="flex flex-col items-center h-full w-full pt-[160px]">
      <Switch>
        <Route exact path={urls.signIn} component={SignInPage} />

        <Route exact path={urls.resetPassword.main} component={ResetPassword} />

        <Route exact path={urls.resetPassword.expired} component={ResetPasswordLinkExpired} />

        <ConsumeRedirectRoute render={() => <ConsumeRedirectRoute render={() => <Redirect to={urls.signIn} />} />} />
      </Switch>
    </div>
  </div>
);

export default Unauthorized;
