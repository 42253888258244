import React from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';

import { UserDetail } from '@/domains/user-detail';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

export interface Props {
  data: UserDetail;
  onClick: (event: MouseEvent) => any;
}

const MemberTableItem: React.FC<Props> = ({ data, onClick }) => {
  const intl = useIntl();

  return (
    <Typography
      is={Table.Row}
      className="justify-between font-light h-20 px-6 cursor-pointer odd:bg-flyblack-gray"
      type="halcyon"
      hoverable
      onClick={onClick}
    >
      <Table.Data className="pr-1 w-[30%]">{`${data.firstName} ${data.lastName}`}</Table.Data>

      <Table.Data className="pr-1 w-[60%]">{data.email}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">
        {data.membership && intl.formatDate(data.membership.approvedAt, formatDateOptions)}
      </Table.Data>
    </Typography>
  );
};

export default MemberTableItem;
