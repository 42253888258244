import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { By, Order, Sort } from '@flyblack/common/domains';
import { getNotifications } from '@/services/api/notifications';

import Icon from '@flyblack/common/components/Icon';
import Modal from '@flyblack/common/components/Modal';
import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Loading from '@flyblack/common/components/Loading';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import usePagination from '@flyblack/common/hooks/usePagination';
import PageSelector from '@flyblack/common/components/PageSelector';

import ShuttleTableHeader from './PushNotificationTableHeader';
import PushNotificationTableItem from './PushNotificationTableItem';
import PushNotificationDetailModal from './PushNotificationDetailModal';

import SettingsNavigation from '../SettingsNavigation';

export interface Props {}

const PushNotifications: React.FC<Props> = () => {
  const { loading, data: notifications, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size) => {
      return getNotifications(page, size, {
        [Sort.By]: By.CreatedAt,
        [Sort.Order]: Order.Descendent
      });
    }
  });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <div className="flex h-14 items-center justify-between">
          <SettingsNavigation />

          <Modal modal={PushNotificationDetailModal} onSuccess={reload}>
            {({ open }) => (
              <Button appearance="white" onClick={open}>
                <Icon type="add" className="pr-2" />

                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.pages.notifications.sendNotification} />
                </Typography>
              </Button>
            )}
          </Modal>
        </div>

        <Spacer xs={4} />

        {loading ? (
          <Loading visible={true} center className="w-full h-[612px]">
            <Loading.Indicator size={100} borderWidth={2} />
          </Loading>
        ) : (
          <Table className="min-h-[612px]">
            <ShuttleTableHeader />

            <Table.Body>
              {!loading && notifications.map((item) => <PushNotificationTableItem key={item.id} data={item} />)}
            </Table.Body>
          </Table>
        )}

        <Spacer xs={3} />

        <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />

        <Spacer xs={10} />
      </Container>
    </div>
  );
};

export default PushNotifications;
