import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getDateFormat } from '@flyblack/common/util';
import { STRING_SHORT_MAX_LENGTH } from '@/constants';
import { PromoCodeListItem } from '@/domains/promo-code';
import { createPromoCode, deletePromoCode, updatePromoCode } from '@/services/api/promo-code';

import Icon from '@flyblack/common/components/Icon';
import Card from '@flyblack/common/components/Card';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SubmitError } from '@flyblack/common/components/Error';
import Modal, { InstanceProps } from '@flyblack/common/components/Modal';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';

const DEFAULT_CURRENCY = 'USD';

interface Props extends InstanceProps {
  data?: PromoCodeListItem;
  onSuccess?: () => any;
}

const schema = yup.object().shape({
  code: yup
    .string()
    .max(STRING_SHORT_MAX_LENGTH)
    .label(translations.inputs.promoCodeName.label)
    .required(),
  endsAt: yup
    .string()
    .label(translations.inputs.promoCodeExpiration.label)
    .required(),
  value: yup
    .number()
    .label(translations.inputs.promoCodeAmount.label)
    .required()
});

const TextInputWithValidation = withValidation(TextInput);

const PromoCodeDetailModal = ({ data, onSuccess, close }: Props) => {
  const intl = useIntl();

  const [deleteError, setDeleteError] = React.useState(false);

  return (
    <Modal.Card className="w-[384px]">
      <Modal.Header close={close}>
        <FormattedMessage id={translations.modals.promoCodeModal.title[data ? 'update' : 'new']} />
      </Modal.Header>

      <Card.Row padded>
        <Form
          schema={schema}
          initialValues={
            data && {
              code: data.code,
              value: data.amount.value,
              endsAt: getDateFormat(data.endsAt, dateFormat)
            }
          }
          subscription={{ submitError: true, submitting: true, valid: true, dirty: true }}
          onSubmit={({ value, code, endsAt }) => {
            setDeleteError(false);
            return (data
              ? updatePromoCode(data.id, { currency: DEFAULT_CURRENCY, value: value }, code, `${endsAt}T00:00:00.000Z`)
              : createPromoCode({ currency: DEFAULT_CURRENCY, value: value }, code, `${endsAt}T00:00:00.000Z`)
            ).then(() => {
              onSuccess && onSuccess();
              close();
            });
          }}
          className="flex flex-col w-full"
        >
          {({ submitError, submitting, valid, dirty, form }) => (
            <React.Fragment>
              <div className="flex justify-between">
                <Form.Field
                  is={TextInputWithValidation}
                  id="code"
                  name="code"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.promoCodeName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.promoCodeName.placeholder })}
                  className="w-[180px]"
                  appearance="light"
                />

                <Form.Field
                  is={TextInputWithValidation}
                  id="value"
                  name="value"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.promoCodeAmount.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.promoCodeAmount.placeholder })}
                  leadingIcon="dollar"
                  className="w-[100px]"
                  appearance="light"
                />
              </div>

              <Spacer xs={4} />

              <Form.Field
                is={DatePickerInput}
                id={`ends-at`}
                name="endsAt"
                type="text"
                readOnly={submitting}
                label={intl.formatMessage({ id: translations.inputs.promoCodeExpiration.label })}
                change={form.change}
                className="w-[180px]"
                appearance="light"
              />

              <Spacer xs={10} />

              {!!submitError || !!deleteError ? (
                <SubmitError error={submitError || deleteError} className="h-10" />
              ) : (
                <Spacer xs={5} />
              )}

              <Spacer xs={2} />

              <div className="flex justify-between items-center">
                {data && (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      deletePromoCode(data.id)
                        .then(() => {
                          onSuccess();
                          close();
                        })
                        .catch((error) => setDeleteError(error))
                    }
                  >
                    <Icon type="bin" />
                  </div>
                )}
                <Button
                  type="submit"
                  className="font-medium ml-auto w-[150px]"
                  appearance="black"
                  disabled={!valid || !dirty || submitting}
                >
                  <FormattedMessage id={translations.inputs.buttons[data ? 'save' : 'addPromoCode']} />
                </Button>
              </div>
            </React.Fragment>
          )}
        </Form>
      </Card.Row>
    </Modal.Card>
  );
};

export default PromoCodeDetailModal;
