/* eslint-disable @typescript-eslint/camelcase */

import memoize from 'lodash/memoize';

import { GeoLocation } from '@flyblack/common/domains';
import { HttpClient } from '@/services/network/http';

interface FreeGeoIPData {
  ip: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip_code: string;
  time_zone: string;
  latitude: number;
  longitude: number;
}

interface IPResult extends GeoLocation {
  country: string;
  timeZone: string;
}

class IPService {
  private client = new HttpClient({ baseURL: `https://freegeoip.live` });

  get = memoize(
    (): Promise<IPResult> => {
      return this.client.get<FreeGeoIPData>('/json/').then(({ latitude, longitude, country_code, time_zone }) => ({
        latitude,
        longitude,
        country: country_code,
        timeZone: time_zone
      }));
    }
  );
}

export const ip = new IPService();
