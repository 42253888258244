import React from 'react';
import classnames from 'classnames';

interface Props {
  image?: string;
  className?: string;
}

// TODO replace with the correct placeholder image
const placeholderImg =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/PlaceholderLC.png/240px-PlaceholderLC.png';

const Avatar = ({ image, className }: Props) => {
  return (
    <div
      className={classnames('rounded-full bg-center bg-no-repeat bg-cover bg-flyblack-light-gray', className)}
      style={{
        backgroundImage: `url(${image || placeholderImg})`
      }}
    />
  );
};

export default Avatar;
