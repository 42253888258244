import React from 'react';

import { FormattedMessage } from 'react-intl';

import { PassengerListItem, Pet } from '@flyblack/common/domains';

import Modal from '@flyblack/common/components/Modal';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import DetailCard from '@flyblack/common/components/DetailCard';
import PassengersModal from '@flyblack/common/components/Modals/PassengersModal';
import Icon from '@flyblack/common/components/Icon';

interface Props {
  passengers: PassengerListItem[];
  pets: Pet[];
  translations: {
    title: string;
    label: string;
    passengerCount: string;
    petCount: string;
    viewPassengers: string;
  };
  className?: string;
}

const PassengersSection: React.FC<Props> = ({ passengers, pets, translations, className }) => {
  return (
    <DetailCard className={className}>
      <div>
        <Typography is="span" type="halcyon" weight="bold">
          <FormattedMessage id={translations.title} />
        </Typography>

        <Spacer xs={4} />

        <Typography is="span" type="hummingbird" className="font-normal text-white text-opacity-[32%]">
          <FormattedMessage id={translations.label} />
        </Typography>
        <div className="flex justify-between border-b border-solid border-flyblack-gray pt-2 pb-4">
          <div className="flex">
            <Icon type="crew" />
            <Typography is="div" type="halcyon" className="pl-4 leading-6">
              <FormattedMessage id={translations.passengerCount} values={{ passengerCount: passengers.length }} />
              {pets.length > 0 && <FormattedMessage id={translations.petCount} values={{ petCount: pets.length }} />}
            </Typography>
          </div>

          <Modal modal={PassengersModal} passengers={passengers} pets={pets}>
            {({ open }) => (
              <Typography is="div" type="halcyon" className="leading-6 cursor-pointer" weight="bold" onClick={open}>
                <FormattedMessage id={translations.viewPassengers} />
              </Typography>
            )}
          </Modal>
        </div>
      </div>
    </DetailCard>
  );
};

export default PassengersSection;
