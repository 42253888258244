import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';
import { CharterStatus } from '@/domains/charter-status';

import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import { SubmitError } from '@flyblack/common/components/Error';

import CharterDetailButtons from './CharterDetailButtons';

export interface Props {
  initialStatus: CharterStatus;
  selectedStatus: CharterStatus;
  formId: string;
  submitting: boolean;
  dirty: boolean;
  hasValidationErrors: boolean;
  submitError;
}

const TitleSection: React.FC<Props> = ({
  initialStatus,
  selectedStatus,
  formId,
  submitting,
  dirty,
  hasValidationErrors,
  submitError
}) => (
  <React.Fragment>
    <div className="flex flex-row w-full justify-between items-center">
      <Typography is="span" type="flamingo">
        <FormattedMessage id={translations.pages.charters.detail.title} />
      </Typography>

      <CharterDetailButtons
        formId={formId}
        submitting={submitting}
        disabledSubmit={!dirty || hasValidationErrors}
        showStatus
        allowStatusChange={false}
        initialStatus={initialStatus}
        selectedStatus={selectedStatus}
      />
    </div>

    {submitError && (
      <div className="flex flex-col items-end">
        <Spacer xs={6} />
        <SubmitError error={submitError} />
      </div>
    )}
  </React.Fragment>
);

export default TitleSection;
